import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import User from '../user/action'
import Video from '../Video/action'
import Learner from './action'
import {
  createLearner,
  getClinicLearnerCount,
  getLearnerDashboard,
  getLearnerDetails,
  getLearners,
  getLearnerVideosHistory,
  getLearnerVideosInHierarchy,
  getStudentsByClinic,
  getVideosByLearner,
  getWatchTimeGraphData,
  updateLearnerDetails,
} from './services'

export function* GET_VIDEOS_BY_LEARNER() {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerVideosLoading: true,
    },
  })
  const response = yield call(getVideosByLearner)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learnerVideos: response.getStudentsTutorialVideos.edges,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerVideosLoading: false,
    },
  })
}

export function* GET_LEARNER_VIDEOS_HIERARCHY({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerVideosLoading: true,
    },
  })
  const response = yield call(getLearnerVideosInHierarchy, payload)
  if (response) {
    const temp = response.getLearnerVideosInHierarchy
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learnerVideosHierarchy: temp,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerVideosLoading: false,
    },
  })
}
export function* GET_LEARNER_DETAILS({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerDetailsLoading: true,
    },
  })
  const response = yield call(getLearnerDetails, payload)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        selectedLearner: response.student,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerDetailsLoading: false,
    },
  })
}

export function* UPDATE_LEARNER({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerSaving: true,
    },
  })
  const response = yield call(updateLearnerDetails, payload)
  if (response && response.updateStudent) {
    notification.success({
      message: 'Learner Details successfully updated!',
    })
    const { students, role } = yield select(state => state.user)
    if (role === 'parents') {
      yield put({
        type: User.SET_STATE,
        payload: {
          students: { ...students, ...response.updateStudent.student },
        },
      })
    }
    if (role === 'school_admin' || role === 'superuser') {
      yield put({
        type: Learner.REPLACE_LEARNER,
        payload: {
          data: response.updateStudent.student,
        },
      })
    }
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerSaving: false,
    },
  })
}

export function* GET_LEARNER_DASHBOARD() {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isDashboardLoading: true,
    },
  })
  const response = yield call(getLearnerDashboard)
  if (response && response.getLearnerVideoDashboard) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        dashboard: response.getLearnerVideoDashboard,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isDashboardLoading: false,
    },
  })
}

export function* GET_WATCH_TIME_GRAPH_DATA({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isGraphLoading: true,
    },
  })
  const response = yield call(getWatchTimeGraphData, payload)
  if (response && response.getVideoWatchTimeOfLearner) {
    const data = response.getVideoWatchTimeOfLearner
    const temp = JSON.parse(data)
    const gData = Object.keys(temp).map(item => ({ x: item, y: temp[item] }))
    yield put({
      type: Learner.SET_STATE,
      payload: {
        graphData: [
          {
            id: 'Watch Time',
            color: 'hsl(286, 70%, 50%)',
            data: gData,
          },
        ],
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isGraphLoading: false,
    },
  })
}
export function* GET_LEARNERS({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const response = yield call(getLearners, payload)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learners: response.students.edges,
        pageInfo: response.students.pageInfo,
        learnerCount: response.students.totalCount,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { pageInfo, type } = yield select(state => state.learner)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  }
  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }

  const response = yield call(getLearners, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learners: response.students.edges,
        pageInfo: response.students.pageInfo,
        learnerCount: response.students.totalCount,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export function* CREATE_LEARNER({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerSaving: true,
    },
  })
  const response = yield call(createLearner, payload)
  if (response) {
    notification.success({
      message: 'Learner Created successfully!',
    })
    yield put({
      type: Learner.APPEND_LEARNER,
      payload: {
        data: response.createStudent.student,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerSaving: false,
    },
  })
}
export function* GET_STUDENTS_BY_CLINIC({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const response = yield call(getStudentsByClinic, payload)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learners: response.students.edges,
        superAdminLearnerCount: response.students.totalCount,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}
export function* GET_LEARNER_VIDEOS_HISTORY({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isHistoryLoading: true,
    },
  })
  const response = yield call(getLearnerVideosHistory, payload)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learnerHistoryCount: response.getLearnerVideosHistory.totalCount,
        learnerVideosHistory: response.getLearnerVideosHistory.edges,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isHistoryLoading: false,
    },
  })
}
export function* GET_LEARNER_CLINIC_COUNT({ payload }) {
  const response = yield call(getClinicLearnerCount, payload)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        learnerCount: response.getClinicLearnerCount.totalLearner,
        clinicCount: response.getClinicLearnerCount.totalClinic,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(Learner.GET_VIDEOS_BY_LEARNER, GET_VIDEOS_BY_LEARNER),
    takeLatest(Learner.GET_LEARNER_VIDEOS_HIERARCHY, GET_LEARNER_VIDEOS_HIERARCHY),

    takeLatest(Learner.GET_LEARNER_DETAILS, GET_LEARNER_DETAILS),
    takeLatest(Learner.UPDATE_LEARNER, UPDATE_LEARNER),

    takeLatest(Learner.GET_LEARNER_DASHBOARD, GET_LEARNER_DASHBOARD),
    takeLatest(Learner.GET_WATCH_TIME_GRAPH_DATA, GET_WATCH_TIME_GRAPH_DATA),

    takeLatest(Learner.GET_LEARNERS, GET_LEARNERS),
    takeLatest(Learner.CREATE_LEARNER, CREATE_LEARNER),
    takeLatest(Learner.GET_STUDENTS_BY_CLINIC, GET_STUDENTS_BY_CLINIC),

    takeLatest(Learner.GET_LEARNER_VIDEOS_HISTORY, GET_LEARNER_VIDEOS_HISTORY),
    takeLatest(Learner.GET_LEARNER_CLINIC_COUNT, GET_LEARNER_CLINIC_COUNT),

    takeLatest(Learner.PAGE_CHANGED, PAGE_CHANGED),
  ])
}
