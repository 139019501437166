import Video from './action'

const initialState = {
  selectedVideo: null,
  selectedModuleIndex: 0,
  selectedSubModuleIndex: 0,
  selectedVideoIndex: 0,
  isCommentsLoading: false,
  comments: [],
  graphData: null,
  isGraphLoading: false,
  convertedVideosHierarchy: [],
}

export default function videoReducer(state = initialState, action) {
  switch (action.type) {
    case Video.SET_STATE:
      return { ...state, ...action.payload }
    case Video.APPEND_VIDEO_COMMENT: {
      return {
        ...state,
        comments: [
          ...state.comments,
          {
            node: { ...action.payload.data, reply: { edges: [] } },
          },
        ],
      }
    }
    case Video.REPLACE_VIDEO_COMMENT: {
      return {
        ...state,
        comments: [
          ...state.comments.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    case Video.APPEND_VIDEO_COMMENT_REPLY: {
      const { commentId, data } = action.payload
      let updatedComments = state.comments
      // Find the selected comment in the state
      const selectedComment = state.comments.find(comment => comment.node.id === commentId)

      if (selectedComment) {
        // Append the reply to the selected comment
        selectedComment.node.reply.edges.push({ node: data })

        // Create a new array with the updated comment
        updatedComments = state.comments.map(comment => {
          if (comment.node.id === commentId) {
            return selectedComment
          }
          return comment
        })

        // Return the updated state
      }
      return {
        ...state,
        comments: updatedComments,
      }
    }

    case Video.REPLACE_VIDEO_COMMENT_REPLY: {
      const { replyCommentId, data } = action.payload

      // Find the selected reply within the comments array
      const updatedComments = state.comments.map(comment => {
        const updatedReplies = comment.node.reply.edges.map(reply => {
          if (reply.node.id === replyCommentId) {
            return { node: data }
          }
          return reply
        })

        return {
          ...comment,
          node: {
            ...comment.node,
            reply: {
              ...comment.node.reply,
              edges: updatedReplies,
            },
          },
        }
      })

      // Return the updated state
      return {
        ...state,
        comments: updatedComments,
      }
    }
    case Video.POP_COMMENT: {
      const { commentId } = action.payload

      const updatedComments = state.comments.filter(comment => comment.node.id !== commentId)

      // Return the updated state
      return {
        ...state,
        comments: updatedComments,
      }
    }
    case Video.POP_REPLY_COMMENT: {
      const { replyCommentId } = action.payload

      const updatedComments = state.comments.map(comment => {
        const updatedReplies = comment.node.reply.edges.filter(
          reply => reply.node.id !== replyCommentId,
        )

        return {
          ...comment,
          node: {
            ...comment.node,
            reply: {
              ...comment.node.reply,
              edges: updatedReplies,
            },
          },
        }
      })

      // Return the updated state
      return {
        ...state,
        comments: updatedComments,
      }
    }

    default:
      return state
  }
}
