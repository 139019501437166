import Assessment from './action'

const initialState = {
  isLearnerAssessmentSaving: false,
  selectedAssessment: null,
  questions: [],
  isQuestionLoading: false,
  isLearnerAssessmentLoading: false,
  selectedQuestionIndex: 0,
  Question: null,
  responseLoading: false,
  questionResponses: [],
  learnerAssessments: [],
  last5Assessments: [],
  isCreate: false,
  completedClick: false,
  initialModal: false,
  isAssessmentLoaded: false,
}

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case Assessment.SET_STATE:
      return { ...state, ...action.payload }
    case Assessment.APPEND_LEARNER_ASSESSMENT: {
      return {
        ...state,
        isCreate: false,
        selectedAssessment: action.payload.data,
        learnerAssessments: [...state.learnerAssessments, { ...action.payload.data }],
      }
    }
    case Assessment.APPEND_RESPONSE: {
      let responses = state.questionResponses

      const { selectedQuestionIndex, questions } = state

      const Question = questions[selectedQuestionIndex]?.node
      let filtered = questions
      responses = [...responses, { ...action.payload.data }]
      const idx =
        state.selectedQuestionIndex === questions.length - 1
          ? state.selectedQuestionIndex
          : state.selectedQuestionIndex + 1
      const isAnswered = responses.find(res => res.question?.id === Question?.id)

      if (
        isAnswered &&
        selectedQuestionIndex !== questions.length - 1 &&
        Question.questionType !== 'BEHAVIOR'
      ) {
        const find = questions.filter(
          ({ node }) =>
            node.forResponse &&
            isAnswered.option &&
            node.forResponse.id !== isAnswered.option.id &&
            node.parentQuestion.id === isAnswered.question.id,
        )

        if (find.length) {
          filtered = questions.filter(
            ({ node: item }) => !find.some(({ node: item2 }) => item.id === item2.id),
          )
        }
      }
      return {
        ...state,
        questions: filtered,
        questionResponses: responses,
        selectedQuestionIndex: idx,
        Question: questions[idx].node,
      }
    }
    case Assessment.REPLACE_RESPONSE: {
      let responses = state.questionResponses
      const questions = state.questions

      const idx = responses.findIndex(node => node.id === action.payload.data.id)

      if (idx !== -1) {
        const queIdx =
          state.selectedQuestionIndex === questions.length - 1
            ? state.selectedQuestionIndex
            : state.selectedQuestionIndex + 1
        const oldData = responses[idx]
        const newData = {
          ...oldData,
          ...action.payload.data,
        }
        responses[idx] = newData
        return {
          ...state,
          questionResponses: responses,
          selectedQuestionIndex: queIdx,
        }
      }
      return {
        ...state,
        questionResponses: responses,
      }
    }
    default:
      return state
  }
}
