/* eslint-disable no-unused-vars */

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function getVideosByLearner(payload) {
  return client
    .query({
      query: gql`
        query getStudentsTutorialVideos {
          getStudentsTutorialVideos {
            edges {
              node {
                id
                learner {
                  id
                  firstname
                  lastname
                  email
                }
                totalLikesInClinic
                totalDislikesInClinic
                averageRatingInClinic
                learnerLikeDislike {
                  id
                  like
                  dislike
                }
                learnerRating {
                  id
                  rating
                }
                studentvideotracking {
                  id
                  learnerVideo {
                    id
                  }
                  videoTracking
                }
                tutorialVideos {
                  id
                  video {
                    id
                    videoUrl
                    videoName
                  }
                  isActive
                  module {
                    id
                    name
                  }
                  subModule {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerDetails(payload) {
  return client
    .query({
      query: gql`
        query student($studentId: ID!, $weekDay: SchoolStaffWeekdaysWeekdayChoices) {
          student(studentId: $studentId) {
            id
            clinic {
              id
              schoolName
              email
              address
            }

            firstname
            lastname
            parentFirstname
            parentLastname
            parentNickname
            mobileno
            email
            dob
            gender
            zipCode
            currentAddress
            caseManager {
              id
              user {
                id
                email
              }
              image
              firstname
              lastname
              email
              designation
              city
              state
              meetLink
              country {
                id
                name
              }
              education
              experience
              staffweekdaysSet(weekday: $weekDay) {
                edges {
                  node {
                    id
                    timeSlots {
                      edges {
                        node {
                          id
                          startTime
                          endTime
                        }
                      }
                    }
                  }
                }
              }
              languages {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            authStaff {
              edges {
                node {
                  id
                  user {
                    id
                    email
                  }
                  image
                  firstname
                  lastname
                  email
                  designation
                  city
                  state
                  meetLink
                  country {
                    id
                    name
                  }
                  education
                  experience
                  staffweekdaysSet(weekday: $weekDay) {
                    edges {
                      node {
                        id
                        timeSlots {
                          edges {
                            node {
                              id
                              startTime
                              endTime
                            }
                          }
                        }
                      }
                    }
                  }
                  languages {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
        weekDay: payload.weekDay.toUpperCase(),
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerVideosInHierarchy(payload) {
  return client
    .query({
      query: gql`
        query getLearnerVideosInHierarchy($status: String) {
          getLearnerVideosInHierarchy(status: $status) {
            moduleId
            moduleName
            isActive
            moduleOrder
            subModules {
              subModuleId
              subModuleName
              isActive
              learnerTutorialVideos {
                id
                learner {
                  id
                  email
                }
                status
                videoOrder
                availableAllTime
                videoPlayedAt
                videoCompletedAt
                userWatchTime
                videoPlayCount
                studentvideotracking {
                  id
                  videoTracking
                }
                totalLikesInClinic
                totalDislikesInClinic
                averageRatingInClinic
                videoViewsInClinic
                learnerLikeDislike {
                  id
                  like
                  dislike
                }
                learnerRating {
                  id
                  rating
                }
                tutorialVideos {
                  id
                  video {
                    id
                    videoUrl
                    videoName
                    videoDocFile
                  }
                  isActive
                  module {
                    id
                    name
                    isActive
                  }
                  subModule {
                    id
                    name
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerDashboard() {
  return client
    .query({
      query: gql`
        query {
          getLearnerVideoDashboard {
            totalAssignVideos
            completedVideos
            inprogressVideos
            pendingVideos
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getWatchTimeGraphData(payload) {
  return client
    .query({
      query: gql`
        query getVideoWatchTimeOfLearner($startDate: Date!, $endDate: Date!) {
          getVideoWatchTimeOfLearner(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearners(payload) {
  return client
    .query({
      query: gql`
        query students(
          $isActive: Boolean
          $first: Int
          $last: Int
          $after: String
          $before: String
          $firstname: String
          $email: String
        ) {
          students(
            isActive: $isActive
            first: $first
            last: $last
            after: $after
            before: $before
            email_Icontains: $email
            firstname_Icontains: $firstname
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                email
                firstname
                lastname
                isActive
                dob
                gender
                pronounceChild
                currentAddress
                mobileno
                zipCode
                clientId
                parentFirstname
                parentLastname
                parentNickname
                caseManager {
                  id
                }
                authStaff {
                  edges {
                    node {
                      id
                      email
                      firstname
                      lastname
                    }
                  }
                }
                country {
                  id
                  name
                }
                studentstutorialvideosSet {
                  totalCount
                }
                clinic {
                  id
                  email
                  schoolName
                }
              }
            }
          }
        }
      `,
      variables: {
        isActive: payload.isActive,
        firstname: payload.firstname ? payload.firstname : '',
        email: payload.email ? payload.email : '',
        ...payload,
      },

      fetchPolicy: 'no-cache',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createLearner(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation CreateLearner(
          $email: String!
          $gender: String!
          $other: String
          $dob: Date!
          $firstname: String!
          $lastname: String
          $mobileNo: String
          $address: String
          $zipCode: String
          $parentFirstname: String
          $parentLastname: String
          $parentNickname: String
          $country: ID!
          $caseManager: ID
          $password: String
          $authStaff: [ID]
        ) {
          createStudent(
            input: {
              studentData: {
                email: $email
                gender: $gender
                pronounceChild: $other
                dob: $dob
                firstname: $firstname
                lastname: $lastname
                mobileno: $mobileNo
                address: $address
                zipCode: $zipCode
                parentFirstname: $parentFirstname
                parentLastname: $parentLastname
                parentNickname: $parentNickname
                country: $country
                caseManager: $caseManager
                password: $password
                authStaff: $authStaff
              }
            }
          ) {
            student {
              id
              email
              firstname
              lastname
              isActive
              dob
              gender
              pronounceChild
              currentAddress
              mobileno
              zipCode
              clientId
              caseManager {
                id
              }
              authStaff {
                edges {
                  node {
                    id
                    email
                    firstname
                    lastname
                  }
                }
              }
              parentFirstname
              parentLastname
              parentNickname
              country {
                id
                name
              }
              studentstutorialvideosSet {
                totalCount
              }
              clinic {
                id
                email
                schoolName
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function updateLearnerDetails(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateStudent(
          $id: ID!
          $email: String
          $gender: String
          $other: String
          $dob: Date
          $firstname: String
          $lastname: String
          $address: String
          $mobileNo: String
          $zipCode: String
          $isActive: Boolean
          $country: ID
          $parentFirstname: String
          $parentLastname: String
          $parentNickname: String
          $caseManager: ID
          $authStaff: [ID]
        ) {
          updateStudent(
            input: {
              studentData: {
                id: $id
                email: $email
                gender: $gender
                pronounceChild: $other
                dob: $dob
                firstname: $firstname
                lastname: $lastname
                address: $address
                mobileno: $mobileNo
                zipCode: $zipCode
                isActive: $isActive
                country: $country
                parentFirstname: $parentFirstname
                parentLastname: $parentLastname
                parentNickname: $parentNickname
                caseManager: $caseManager
                authStaff: $authStaff
              }
            }
          ) {
            message
            status
            student {
              id
              firstname
              lastname
              dob
              email
              mobileno
              gender
              pronounceChild
              isActive
              currentAddress
              zipCode
              clientId
              country {
                id
                name
              }
              caseManager {
                id
              }
              authStaff {
                edges {
                  node {
                    id
                    email
                    firstname
                    lastname
                  }
                }
              }
              parentFirstname
              parentLastname
              parentNickname
              studentstutorialvideosSet {
                totalCount
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getStudentsByClinic(payload) {
  return client
    .query({
      query: gql`
        query students($clinicId: ID!, $first: Int, $offset: Int) {
          students(clinic: $clinicId, first: $first, offset: $offset) {
            totalCount
            edges {
              node {
                id
                email
                firstname
                lastname
                isActive
                dob
                gender
                pronounceChild
                currentAddress
                mobileno
                zipCode
                clientId
                studentstutorialvideosSet {
                  totalCount
                }
                clinic {
                  id
                  email
                  schoolName
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerVideosHistory(payload) {
  return client
    .query({
      query: gql`
        query getLearnerVideosHistory(
          $startDate: Date!
          $endDate: Date
          $learnerId: ID
          $first: Int
          $offset: Int
        ) {
          getLearnerVideosHistory(
            startDate: $startDate
            endDate: $endDate
            learner: $learnerId
            first: $first
            offset: $offset
          ) {
            totalCount
            edges {
              node {
                id
                learner {
                  id
                  email
                }
                studentTutorialVideo {
                  id
                  videoWatchedTime
                  status
                  userWatchTime
                  videoPlayCount
                }
                tutorialVideo {
                  id
                  video {
                    id
                    videoName
                    videoUrl
                  }
                  isActive
                  module {
                    id
                    name
                    isActive
                  }
                  subModule {
                    id
                    name
                    isActive
                  }
                }
                isAssigned
                updatedBy {
                  id
                  email
                  username
                }
                createdAt
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getClinicLearnerCount(payload) {
  return client
    .query({
      query: gql`
        query getClinicLearnerCount(
          $email: String
          $firstName: String
          $schoolName: String
          $isActive: Boolean
        ) {
          getClinicLearnerCount(
            email_Icontains: $email
            isActive: $isActive
            firstname_Icontains: $firstName
            schoolName_Icontains: $schoolName
          ) {
            totalLearner
            totalClinic
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getStudentVideobyTutorialVideoId(payload) {
  return client
    .query({
      query: gql`
        query getStudentVideoByTutorialId($videoId: ID!) {
          getStudentVideoByTutorialId(tutorialVideoId: $videoId) {
            id
            learner {
              id
              email
            }
            status
            videoPlayedAt
            videoCompletedAt
            userWatchTime
            videoPlayCount
            studentvideotracking {
              id
              videoTracking
            }
            totalLikesInClinic
            totalDislikesInClinic
            averageRatingInClinic
            videoViewsInClinic
            learnerLikeDislike {
              id
              like
              dislike
            }
            learnerRating {
              id
              rating
            }
            tutorialVideos {
              id
              video {
                id
                videoUrl
                videoName
                videoDocFile
              }
              isActive
              module {
                id
                name
                isActive
              }
              subModule {
                id
                name
                isActive
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
