const Learner = {
  SET_STATE: 'learner/SET_STATE',
  GET_VIDEOS_BY_LEARNER: 'learner/GET_VIDEOS_BY_LEARNER',
  GET_LEARNER_DETAILS: 'learner/GET_LEARNER_DETAILS',
  UPDATE_LEARNER: 'learner/UPDATE_LEARNER',
  GET_LEARNER_VIDEOS_HIERARCHY: 'learner/GET_LEARNER_VIDEOS_HIERARCHY',
  GET_LEARNER_DASHBOARD: 'learner/GET_DASHBOARD',
  GET_WATCH_TIME_GRAPH_DATA: 'learner/GET_WATCH_TIME_GRAPH_DATA',
  GET_LEARNERS: 'learner/GET_LEARNERS',
  CREATE_LEARNER: 'learner/CREATE_LEARNER',
  APPEND_LEARNER: 'learner/APPEND_LEARNER',
  REPLACE_LEARNER: 'learner/REPLCAE_LEARNER',
  GET_STUDENTS_BY_CLINIC: 'learner/GET_STUDENTS_BY_CLINIC',
  GET_LEARNER_VIDEOS_HISTORY: 'learner/GET_LEARNER_VIDEOS_HISTORY',
  GET_LEARNER_CLINIC_COUNT: 'learner/GET_LEARNER_CLINIC_COUNT',
  PAGE_CHANGED: 'learner/PAGE_CHANGED',
}

export default Learner
