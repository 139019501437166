import Chat from './action'

const initialState = {
  loading: false,
  chatRooms: [],
  chatRoomResponse: {},
  loadingChatRooms: false,
  chatRoomsLoaded: false,
  isChatRoomCreating: false,
  isMessagesLoading: false,
  allMessages: [],
  isMessageSending: true,
  isAllMessagesLoaded: false,
  isUnreadCountLoading: false,
  messageUser: [],
  filteredMessageUser: [],
  selectedLearnerChat: null,
  loadingMoreMessages: false,
  loadedMessages: [],
  roomDetailsLoading: false,
}

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case Chat.SET_STATE:
      return { ...state, ...action.payload }
    case Chat.RESET:
      return { ...initialState }
    case Chat.APPEND_CHAT_ROOM:
      return {
        ...state,
        chatRooms: [
          ...state.chatRooms,
          { unreadMessageCount: 0, chatRoom: { ...action.payload.data } },
        ],
        selectedLearnerChat: {
          ...state.selectedLearnerChat,
          ...action.payload.data,
          unreadMessageCount: 0,
          roomId: action.payload.data.id,
        },
      }
    default:
      return state
  }
}
