const Chat = {
  SET_STATE: 'chat/SET_STATE',
  RESET: 'chat/RESET',
  GET_ALL_CHAT_ROOMS: 'chat/GET_ALL_CHAT_ROOMS',
  GET_CHAT_ROOM_BY_ID: 'chat/GET_CHAT_ROOM_BY_ID',
  CREATE_CHAT_ROOM: 'chat/CREATE_CHAT_ROOM',
  APPEND_CHAT_ROOM: 'chat/APPEND_CHAT_ROOM',
  GET_CHAT_ROOM_MESSAGES: 'chat/GET_CHAT_ROOM_MESSAGES',
  LOAD_MORE_MESSAGES: 'chat/LOAD_MORE_MESSAGES',
  SEND_CHAT_MESSAGE: 'chat/SEND_MESSAGE',
  ADD_MESSAGE_VIEWED_BY: 'chat/ADD_MESSAGE_VIEWED_BY',
  GET_ROOM_WITH_UNREAD_COUNT: 'chat/GET_ROOM_WITH_UNREAD_COUNT',
}

export default Chat
