import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ZoomMeetingAction from './action'
import {
  createInstantZoomMeeting,
  getInstantMeetCalls,
  getInstantZoomMeetingSignature,
  getManualInstantMeetCalls,
  getMeetingDetailsByMeetId,
  updateMeetingStatus,
  updateZoomMeetByBCBA,
} from './services'

// export function* CREATE_INSTANT_ZOOM_MEETING({ payload }) {
//   yield put({
//     type: ZoomMeetingAction.SET_STATE,
//     payload: {
//       isZoomMeetingSaving: true,
//     },
//   })
//   const response = yield call(createInstantZoomMeeting, payload)
//   if (response && response.createInstantMeet && response.createInstantMeet.status) {
//     const { role } = yield select(state => state.user)
//     yield put({
//       type: ZoomMeetingAction.SET_STATE,
//       payload: {
//         createdZoomMeeting: response.createInstantMeet.data,
//       },
//     })

//     yield put({
//       type: ZoomMeetingAction.GET_INSTANT_ZOOM_MEETING_SIGNATURE,
//       payload: {
//         zoomId: response.createInstantMeet.data.id,
//         role: role === 'parents' ? 1 : 0,
//       },
//     })
//   }
//   yield put({
//     type: ZoomMeetingAction.SET_STATE,
//     payload: {
//       isZoomMeetingSaving: false,
//     },
//   })
// }

export function* CREATE_INSTANT_ZOOM_MEETING({ payload }) {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isZoomMeetingSaving: true,
    },
  })
  const response = yield call(createInstantZoomMeeting, payload)
  if (response && response.createInstantMeetManually && response.createInstantMeetManually.status) {
    yield put({
      type: ZoomMeetingAction.SET_STATE,
      payload: {
        createdZoomMeeting: response.createInstantMeetManually.data,
      },
    })
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isZoomMeetingSaving: false,
    },
  })
}
export function* GET_INSTANT_ZOOM_MEETING_SIGNATURE({ payload }) {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isZoomSignatureLoading: true,
    },
  })
  const response = yield call(getInstantZoomMeetingSignature, payload)
  if (response && response.generateSignatureForMeet && response.generateSignatureForMeet.status) {
    yield put({
      type: ZoomMeetingAction.SET_STATE,
      payload: {
        signature: response.generateSignatureForMeet.signature,
      },
    })
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isZoomSignatureLoading: false,
    },
  })
}

export function* GET_MEETING_DETAILS_BY_MEETID({ payload }) {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isMeetDetailLoading: true,
    },
  })
  const response = yield call(getMeetingDetailsByMeetId, payload)
  if (response && response.getInstantZoomCallById) {
    yield put({
      type: ZoomMeetingAction.SET_STATE,
      payload: {
        zoomMeetingDetails: response.getInstantZoomCallById,
      },
    })
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isMeetDetailLoading: false,
    },
  })
}

export function* UPDATE_MEETING_STATUS({ payload }) {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isMeetingSaving: true,
    },
  })
  const response = yield call(updateMeetingStatus, payload)
  if (response && response.updateInstantZoomMeet) {
    console.log('updated meeting==>', response.updateInstantZoomMeet)
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isMeetingSaving: false,
    },
  })
}

export function* GET_INSTANT_MEET_CALLS() {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isInstantCallLoading: true,
    },
  })
  const response = yield call(getInstantMeetCalls)
  if (response && response.getInstantZoomCalls) {
    console.log('meetings==>', response.getInstantZoomCalls)
    yield put({
      type: ZoomMeetingAction.SET_STATE,
      payload: {
        meetCalls: response.getInstantZoomCalls.edges,
      },
    })
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isInstantCallLoading: false,
    },
  })
}

export function* GET_MANUAL_INSTANT_CALLS({ payload }) {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isInstantCallLoading: true,
    },
  })
  const response = yield call(getManualInstantMeetCalls, payload)
  if (response && response.getManualInstantMeet) {
    yield put({
      type: ZoomMeetingAction.SET_STATE,
      payload: {
        manualMeetCalls: response.getManualInstantMeet.edges,
        totalManualMeetingCount: response.getManualInstantMeet.totalCount,
      },
    })
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isInstantCallLoading: false,
    },
  })
}

export function* UPDATE_ZOOM_MEET_BY_BCBA({ payload }) {
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isZoomMeetingSaving: true,
    },
  })
  const response = yield call(updateZoomMeetByBCBA, payload)
  if (response && response.updateInstantMeetManually && response.updateInstantMeetManually.status) {
    yield put({
      type: ZoomMeetingAction.REPLACE_ZOOM_MEET,
      payload: {
        data: response.updateInstantMeetManually.data,
      },
    })
  }
  yield put({
    type: ZoomMeetingAction.SET_STATE,
    payload: {
      isZoomMeetingSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(ZoomMeetingAction.CREATE_INSTANT_ZOOM_MEETING, CREATE_INSTANT_ZOOM_MEETING),
    takeLatest(
      ZoomMeetingAction.GET_INSTANT_ZOOM_MEETING_SIGNATURE,
      GET_INSTANT_ZOOM_MEETING_SIGNATURE,
    ),
    takeLatest(ZoomMeetingAction.GET_MEETING_DETAILS_BY_MEETID, GET_MEETING_DETAILS_BY_MEETID),
    takeLatest(ZoomMeetingAction.UPDATE_MEETING_STATUS, UPDATE_MEETING_STATUS),
    takeLatest(ZoomMeetingAction.GET_INSTANT_MEET_CALLS, GET_INSTANT_MEET_CALLS),
    takeLatest(ZoomMeetingAction.GET_MANUAL_INSTANT_CALLS, GET_MANUAL_INSTANT_CALLS),
    takeLatest(ZoomMeetingAction.UPDATE_ZOOM_MEET_BY_BCBA, UPDATE_ZOOM_MEET_BY_BCBA),
  ])
}
