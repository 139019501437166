import {
  ArrowLeftOutlined,
  AuditOutlined,
  BankOutlined,
  BookOutlined,
  CalendarOutlined,
  HistoryOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  PlaySquareOutlined,
  TableOutlined,
  UserOutlined,
  WechatOutlined,
} from '@ant-design/icons'
import React from 'react'
import ProfileMenu from './MenuTop/ProfileMenu'
import styles from './style.module.scss'
import {
  MdAssessment,
  MdVideoLibrary,
  MdViewModule,
  MdVideoChat,
  MdOutlineOndemandVideo,
} from 'react-icons/md'
import { BsPersonVideo2 } from 'react-icons/bs'
import { FaHistory, FaClinicMedical, FaRegFileVideo } from 'react-icons/fa'
import { HiHome, HiUsers, HiDocumentReport } from 'react-icons/hi'
import { GoFileSubmodule } from 'react-icons/go'
import { CgCommunity } from 'react-icons/cg'
import { SiGooglemeet } from 'react-icons/si'
import { RiNurseFill } from 'react-icons/ri'
import { VscGraph } from 'react-icons/vsc'
import { BiCalendar, BiSolidUserBadge, BiSolidVideos } from 'react-icons/bi'
import { PiUniteDuotone } from 'react-icons/pi'

export const menuItems = [
  {
    label: (
      <img
        className={styles.logo}
        alt="Logo"
        src="../../../images/logo.png"
        style={{ marginLeft: '0px' }}
      />
    ),
    key: 'logo',
  },

  {
    label: 'Dashboard',
    key: 'dashboard',
    icon: <HiHome />,
  },

  {
    label: 'Learners',
    key: 'learners',
    icon: <HiUsers />,
  },
  {
    label: 'Tutorial',
    key: 'tutorial',
    icon: <GoFileSubmodule />,
    children: [
      {
        label: 'Curriculum',
        key: 'curriculum',
        icon: <MdViewModule />,
      },
      {
        label: 'Videos Curriculum',
        key: 'videosCurriculum',
        icon: <BiSolidVideos />,
      },
    ],
  },
  {
    label: 'Videos',
    key: 'videosLibrary',
    icon: <MdVideoLibrary />,
    children: [
      {
        label: 'Manage Videos',
        key: 'assign',
        icon: <BsPersonVideo2 />,
      },
      {
        label: 'Watch Videos',
        key: 'videos',
        icon: <MdOutlineOndemandVideo />,
      },
    ],
  },

  {
    label: 'Clinics',
    key: 'clinics',
    icon: <FaClinicMedical />,
  },
  {
    label: 'History',
    key: 'history',
    icon: <FaHistory />,
  },
  {
    label: 'Reports',
    key: 'reports',
    icon: <HiDocumentReport />,
    children: [
      {
        label: 'Learner Report',
        key: 'learnerReport',
        icon: <BiSolidUserBadge />,
      },
      {
        label: 'Assessment Report',
        key: 'assessment',
        icon: <MdAssessment />,
      },
      {
        label: 'History Report',
        key: 'history',
        icon: <FaHistory />,
      },
      {
        label: 'Meeting Report',
        key: 'meetingReport',
        icon: <FaRegFileVideo />,
      },
      {
        label: 'Watchtime Report',
        key: 'watchtimeReport',
        icon: <VscGraph />,
      },
      {
        label: 'BCBA Availability Report',
        key: 'BCBAAvailabilityReport',
        icon: <BiCalendar />,
      },
      {
        label: 'BCBA Unit Report',
        key: 'BCBAUnitReport',
        icon: <PiUniteDuotone />,
      },
    ],
  },
  {
    label: 'Calendar',
    key: 'calendar',
    icon: <CalendarOutlined />,
  },
  {
    label: 'Chat',
    key: 'chat',
    icon: <WechatOutlined />,
  },
  {
    label: 'Community',
    key: 'community',
    icon: <CgCommunity />,
    children: [
      {
        label: 'Webinar',
        key: 'webinar',
        icon: <SiGooglemeet />,
      },
      {
        label: 'BCBA',
        key: 'bcba',
        icon: <RiNurseFill />,
      },
    ],
  },
  {
    label: 'Community',
    key: 'communityParent',
    icon: <CgCommunity />,
  },
  {
    label: 'Back',
    icon: <ArrowLeftOutlined />,
    key: 'back',
  },
  {
    label: <ProfileMenu />,
    key: 'logout-profile',
  },
  {
    label: 'Meeting History',
    key: 'meetHistory',
    icon: <MdVideoChat />,
  },
]
