/* eslint-disable no-unused-vars */
import { notification } from 'antd'

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function getAllChatRooms() {
  return client
    .query({
      query: gql`
        query {
          getRoomWithUnreadMsgCount {
            unreadMessageCount
            chatRoom {
              id
              roomType
              groupName
              createdBy
              chatroomusersSet {
                edges {
                  node {
                    id
                    chatUser {
                      id
                      userName
                      user {
                        id
                        firstName
                        lastName
                      }
                    }
                    isAdmin
                  }
                }
              }
              messageSet(last: 1) {
                edges {
                  node {
                    id
                    content
                    document
                    timestamp
                    viewedBy {
                      edges {
                        node {
                          id
                          viewer {
                            id
                            userName
                          }
                        }
                      }
                    }
                    isAllViewed
                    sender {
                      id
                      userName
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getChatRoomById(payload) {
  return client
    .query({
      query: gql`
        query getRoomById($roomId: ID!) {
          getRoomById(id: $roomId) {
            id
            roomType
            groupName
            createdBy
            chatroomusersSet {
              edges {
                node {
                  id
                  chatUser {
                    id
                    userName
                    user {
                      id
                      firstName
                      lastName
                    }
                  }
                  isAdmin
                }
              }
            }
            messageSet {
              edges {
                node {
                  id
                  content
                  document
                  timestamp
                  viewedBy {
                    edges {
                      node {
                        id
                        viewer {
                          id
                          userName
                        }
                      }
                    }
                  }
                  isAllViewed
                  sender {
                    id
                    user {
                      id
                    }
                    userName
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function createChatRoom(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createChatRoom($users: [ID]!, $isGroup: Boolean!) {
          createChatRoom(input: { users: $users, isGroup: $isGroup }) {
            message
            status
            chatRoom {
              id
              roomType
              groupName
              createdBy
              chatroomusersSet {
                edges {
                  node {
                    id
                    chatUser {
                      id
                      userName
                      user {
                        id
                        firstName
                        lastName
                      }
                    }
                    isAdmin
                  }
                }
              }
              messageSet(last: 1) {
                edges {
                  node {
                    id
                    content
                    document
                    timestamp
                    viewedBy {
                      edges {
                        node {
                          id
                          viewer {
                            id
                            userName
                          }
                        }
                      }
                    }
                    isAllViewed
                    sender {
                      id
                      userName
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getChatRoomMessages(payload) {
  return client
    .query({
      query: gql`
        query getChatRoomMessages($roomId: ID!, $before: String) {
          getChatRoomMessages(roomId: $roomId, last: 40, before: $before) {
            edges {
              node {
                id
                content
                document
                timestamp
                viewedBy {
                  edges {
                    node {
                      id
                      viewer {
                        id
                        userName
                      }
                    }
                  }
                }
                isAllViewed
                sender {
                  id
                  userName
                }
                room {
                  id
                  roomType
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function sendChatMessage(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation sendChatMessage($roomId: ID!, $content: String!, $document: String) {
          sendChatMessage(input: { roomId: $roomId, content: $content, documentUrl: $document }) {
            status
            message
            data {
              id
              content
              document
              timestamp
              viewedBy {
                edges {
                  node {
                    id
                    viewer {
                      id
                      userName
                    }
                  }
                }
              }
              isAllViewed
              sender {
                id
                userName
              }
              room {
                id
                roomType
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function addMessageViewedBy(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation addMessageViewedBy($messageId: ID!, $roomId: ID!) {
          addMessageViewedBy(input: { messageId: $messageId, roomId: $roomId }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getRoomWithUnreadCount() {
  return client
    .query({
      query: gql`
        query {
          getRoomWithUnreadMsgCount {
            unreadMessageCount
            chatRoom {
              id
              roomType
              groupName
              createdBy
              chatroomusersSet {
                edges {
                  node {
                    chatUser {
                      userName
                      user {
                        id
                      }
                    }
                    isAdmin
                  }
                }
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
