import { initializeApp } from 'firebase/app'
import { getAuth, AuthCredential } from 'firebase/auth'
import { getMessaging } from 'firebase/messaging'
import { getFirestore, collection, onSnapshot } from 'firebase/firestore'
import { getDatabase, ref, onValue, off, onChildAdded, onChildChanged } from 'firebase/database'
import serviceAccountCredentials from './firebase_private_key.json'

const firebaseConfig = {
  apiKey: 'AIzaSyAbu1HhBEHxZrXaOwgKgdAUBvFs_5UODtk',
  authDomain: 'neurona-health-391903.firebaseapp.com',
  databaseURL: 'https://neurona-health-391903-default-rtdb.firebaseio.com',
  projectId: 'neurona-health-391903',
  storageBucket: 'neurona-health-391903.appspot.com',
  messagingSenderId: '333749903601',
  appId: '1:333749903601:web:451f864b82f5b29fafc950',
  measurementId: 'G-FKHY9Y1VCZ',
}

export const app = initializeApp({
  ...firebaseConfig,
  // credential: AuthCredential.cert(serviceAccountCredentials),
})
const messaging = getMessaging(app)
const db = getFirestore(app)
const database = getDatabase()
const auth = getAuth(app)

export {
  messaging,
  db,
  collection,
  onSnapshot,
  database,
  ref,
  onValue,
  off,
  onChildAdded,
  onChildChanged,
}
