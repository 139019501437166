const Staff = {
  SET_STATE: 'staff/SET_STATE',
  UPDATE_STAFF: 'staff/UPDATE_STAFF',
  REPLACE_STAFF: 'staff/REPLACE_STAFF',
  GET_REQUEST_MEETS: 'staff/GET_REQUEST_MEETS',
  UPDATE_MEET_REQUEST_BY_BCBA: 'staff/UPDATE_MEET_REQUEST_BY_BCBA',
  REPLACE_MEET_REQUEST_BY_BCBA: 'staff/REPLACE_MEET_REQUEST_BY_BCBA',
  GET_STAFF_LEARNERS: 'staff/GET_STAFF_LEARNERS',
  CREATE_UPDATE_SLOT: 'staff/CREATE_UPDATE_SLOT',
  REPLACE_SLOT: 'staff/REPLACE_SLOT',
  GET_SLOTS: 'staff/GET_SLOTS',
  GET_SLOTS_BY_DATE: 'staff/GET_SLOTS_BY_DATE',
  GET_MESSAGES_BY_PARENT: 'staff/GET_MESSAGES_BY_PARENT',
  SEND_MESSAGE_TO_PARENT: 'staff/SEND_MESSAGE_TO_PARENT',
  APPEND_PARENT_MESSAGE: 'staff/APPEND_PARENT_MESSAGE',
  GET_ALL_BCBA_IN_SUPERADMIN: 'staff/GET_ALL_BCBA_IN_SUPERADMIN',
  GET_BCBA_BY_CLINIC_ID: 'staff/GET_BCBA_BY_CLINIC_ID',
  GET_ALL_BCBA_FOR_WEBINAR:'staff/GET_ALL_BCBA_FOR_WEBINAR'
}

export default Staff
