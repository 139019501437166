import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import Assessment from './action'

import {
  getLearnerAssessmentDetails,
  getLearnerAssessmentResponses,
  getLearnerAssessments,
  getLearnerLast5Assessments,
  getPersonalizedQuestions,
  getStrugglingIssueQuestions,
  recordQuestion,
  startLearnerAssessment,
  updateLearnerAssessment,
  updateQuestion,
} from './services'

export function* START_LEARNER_ASSESSMENT({ payload, navigate }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentSaving: true,
    },
  })
  const response = yield call(startLearnerAssessment, payload)
  if (response && response.startLearnerAssessment.status) {
    // notification.success({
    //   message: response.startLearnerAssessment.message,
    // })
    yield put({
      type: Assessment.APPEND_LEARNER_ASSESSMENT,
      payload: {
        data: response.startLearnerAssessment.learnerAssessment,
      },
    })
    // navigate(`/parent/assessment/${response.startLearnerAssessment.learnerAssessment.id}`)
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentSaving: false,
    },
  })
}

export function* UPDATE_LEARNER_ASSESSMENT({ payload }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentSaving: true,
    },
  })
  const { selectedAssessment, learnerAssessments } = yield select(state => state.assessment)
  const response = yield call(updateLearnerAssessment, payload)
  if (response && response.updateLearnerAssessment.status) {
    const idx = learnerAssessments.findIndex(item => item.id === selectedAssessment.id)
    if (idx !== -1) {
      learnerAssessments[idx] = {
        ...learnerAssessments[idx],
        status: response.updateLearnerAssessment.learnerAssessment.status,
      }
    }
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        learnerAssessments,
        selectedAssessment: {
          ...selectedAssessment,
          status: response.updateLearnerAssessment.learnerAssessment.status,
        },
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentSaving: false,
    },
  })
}

export function* GET_LEARNER_ASSESSMENT_DETAILS({ payload }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: true,
    },
  })
  const response = yield call(getLearnerAssessmentDetails, payload)
  if (response && response.getLearnerAssessments) {
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        selectedAssessment: response.getLearnerAssessments[0],
        questionResponses:
          response.getLearnerAssessments[0].learnerstrugglingquestionresponsesSet.edges,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: false,
    },
  })
}

const getActiveQuestionId = (responses, allQuestions) => {
  let filtered = allQuestions

  let findIdx = filtered.length - 1

  if (filtered.length !== responses?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < filtered.length; i++) {
      // eslint-disable-next-line no-loop-func
      const idx = responses.findIndex(item => item.question.id === filtered[i].node.id)
      if (idx === -1) {
        findIdx = i
        break
      }
    }
  }

  let findId = responses.length && responses[responses.length - 1].question.id
  findId = filtered[findIdx]?.node?.id
  return findId
}

export function* GET_STRUGGLING_ISSUE_QUESTIONS() {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isQuestionLoading: true,
    },
  })
  const { selectedAssessment } = yield select(state => state.assessment)

  const res = yield call(getLearnerAssessmentResponses, {
    assessmentId: selectedAssessment.id,
  })
  const responses = res.getLearnerAssessmentResponses
  const response = yield call(getStrugglingIssueQuestions)
  if (response && response.getStrugglingIssueQuestions) {
    const questions = response.getStrugglingIssueQuestions.edges

    const id = getActiveQuestionId(responses, questions)
    let idx = 0
    if (id) {
      idx = questions.findIndex(({ node }) => node.id === id)
    }
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        questions,
        selectedQuestionIndex:
          idx === questions.length - 1 &&
          selectedAssessment &&
          selectedAssessment.status === 'COMPLETED'
            ? 0
            : idx,
        Question: idx !== -1 ? questions[idx]?.node : null,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isQuestionLoading: false,
    },
  })
}
export function* GET_PERSONALIZED_QUESTIONS({ payload }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isQuestionLoading: true,
    },
  })
  const { selectedAssessment } = yield select(state => state.assessment)

  const response = yield call(getPersonalizedQuestions, payload)
  if (response && response.getPersonalizedQuestions) {
    const questions = response.getPersonalizedQuestions.edges
    const newQuestionnaire = questions.flatMap(({ node: q }) => {
      if (q.strugglingissuesquestionswithoptionsSet.edges.length) {
        const subQuestions = q.strugglingissuesquestionswithoptionsSet.edges.map(
          ({ node: subQ }, index) => {
            return {
              node: {
                id: subQ.id,
                ...subQ,
              },
            }
          },
        )
        return [
          { node: { ...q, subQuestionSet: subQuestions.map(subQ => subQ.id) } },
          ...subQuestions,
        ]
      } else {
        return { node: q }
      }
    })

    let idx = 0
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        questions: newQuestionnaire,
        selectedQuestionIndex:
          idx === questions.length - 1 &&
          selectedAssessment &&
          selectedAssessment.status === 'COMPLETED'
            ? 0
            : idx,
        Question: idx !== -1 ? questions[idx]?.node : null,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isQuestionLoading: false,
    },
  })
}
export function* UPDATE_QUESTION({ payload }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      responseLoading: true,
    },
  })
  const response = yield call(updateQuestion, payload)
  if (response && response.updateQuestionAnswer) {
    yield put({
      type: Assessment.REPLACE_RESPONSE,
      payload: {
        data: response.updateQuestionAnswer.questionResponse,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      responseLoading: false,
    },
  })
}

export function* RECORD_QUESTION({ payload }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      responseLoading: true,
    },
  })
  const response = yield call(recordQuestion, payload)
  if (response && response.recordQuestionAnswer) {
    yield put({
      type: Assessment.APPEND_RESPONSE,
      payload: {
        data: response.recordQuestionAnswer.questionResponse,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      responseLoading: false,
    },
  })
}

export function* GET_LEARNER_ASSESSMENTS() {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: true,
    },
  })
  const response = yield call(getLearnerAssessments)
  if (response && response.getLearnerAssessments) {
    const length = response.getLearnerAssessments.length
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        learnerAssessments: response.getLearnerAssessments,
        selectedAssessment:
          length && response.getLearnerAssessments[0].status !== 'COMPLETED'
            ? response.getLearnerAssessments[0]
            : null,
        isAssessmentLoaded: true,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: false,
    },
  })
}
export function* GET_LAST5_ASSESSMENT() {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: true,
    },
  })
  const response = yield call(getLearnerLast5Assessments)
  if (response && response.getLearnerAssessments) {
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        last5Assessments: response.getLearnerAssessments,
        // selectedAssessment: response.getLearnerAssessments[0],
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: false,
    },
  })
}

export function* GET_LEARNER_ASSESSMENT_RESPONSES({ payload }) {
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      responseLoading: true,
    },
  })
  const response = yield call(getLearnerAssessmentResponses, payload)
  if (response && response.getLearnerAssessmentResponses) {
    yield put({
      type: Assessment.SET_STATE,
      payload: {
        questionResponses: response.getLearnerAssessmentResponses,
      },
    })
  }
  yield put({
    type: Assessment.SET_STATE,
    payload: {
      responseLoading: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(Assessment.START_LEARNER_ASSESSMENT, START_LEARNER_ASSESSMENT),
    takeLatest(Assessment.GET_LEARNER_ASSESSMENT_DETAILS, GET_LEARNER_ASSESSMENT_DETAILS),
    takeLatest(Assessment.UPDATE_LEARNER_ASSESSMENT, UPDATE_LEARNER_ASSESSMENT),
    takeLatest(Assessment.GET_STRUGGLING_ISSUE_QUESTIONS, GET_STRUGGLING_ISSUE_QUESTIONS),
    takeLatest(Assessment.GET_PERSONALIZED_QUESTIONS, GET_PERSONALIZED_QUESTIONS),
    takeLatest(Assessment.RECORD_QUESTION, RECORD_QUESTION),
    takeLatest(Assessment.UPDATE_QUESTION, UPDATE_QUESTION),
    takeLatest(Assessment.GET_LEARNER_ASSESSMENTS, GET_LEARNER_ASSESSMENTS),
    takeLatest(Assessment.GET_LEARNER_ASSESSMENT_RESPONSES, GET_LEARNER_ASSESSMENT_RESPONSES),
    takeLatest(Assessment.GET_LAST5_ASSESSMENT, GET_LAST5_ASSESSMENT),
  ])
}
