import 'rc-drawer/assets/index.css'
import React, { useState, useEffect } from 'react'
import Drawer from 'rc-drawer'
import { useSelector, useDispatch } from 'react-redux'
import MenuLeft from './MenuLeft/MenuLeft'
import MenuTop from './MenuTop'
import styles from './style.module.scss'
import { menuItems } from './menuItems'
import { useLocation, useNavigate } from 'react-router-dom'
import User from '../../redux/user/action'
import NotificationList from 'components/Notification/NotificationList'

const AppMenu = props => {
  const { isMenuTop, isMobileMenuOpen, isMobileView, isTabletView } = useSelector(
    state => state.settings,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector(state => state.user)
  const { pathname } = useLocation()

  const { role, current } = user

  const [items, setItems] = useState([])

  useEffect(() => {
    dispatch({
      type: User.GET_ALL_NOTIFICATIONS,
    })
  }, [])

  useEffect(() => {
    if (pathname) {
      if (pathname.includes('dashboard')) {
        setCurrent('dashboard')
      }
      if (pathname.includes('assign')) {
        setCurrent('assign')
      }
      if (pathname.includes('assessment')) {
        setCurrent('assessment')
      }
      if (pathname.includes('history')) {
        setCurrent('history')
      }
      if (pathname.includes('video')) {
        if (role === 'parents') {
          setCurrent('dashboard')
        } else {
          setCurrent('videos')
        }
      }
      if (pathname.includes('learners')) setCurrent('learners')
      if (pathname.includes('tutorial')) setCurrent('curriculum')
      if (pathname.includes('videosCurriculum')) setCurrent('videosCurriculum')
      if (pathname.includes('reports')) setCurrent('learnerReport')
      if (pathname.includes('meetingReports')) setCurrent('meetingReport')
      if (pathname.includes('bcbaMeetingUnits')) setCurrent('BCBAUnitReport')

      if (pathname.includes('community')) {
        if (role === 'parents') {
          setCurrent('communityParent')
        } else {
          setCurrent('community')
        }
      }
      if (pathname.includes('chat')) setCurrent('chat')
      if (pathname.includes('calendar')) setCurrent('calendar')
      if (pathname.includes('userProfile')) setCurrent('')
      if (pathname.includes('videoHistory')) setCurrent('meetHistory')
      if (pathname.includes('watchTimeReport')) setCurrent('watchtimeReport')
      if (pathname.includes('BCBAAvailabilityReport')) setCurrent('BCBAAvailabilityReport')
    }
  }, [pathname])

  const setCurrent = item => {
    dispatch({
      type: User.SET_STATE,
      payload: {
        current: item,
      },
    })
  }

  useEffect(() => {
    let temp = menuItems.filter(item => item.key !== 'back' && item.key !== 'logout-profile')
    if (user.role.toLowerCase() === 'parents') {
      temp = temp.filter(
        item => item.key === 'logo' || item.key === 'dashboard' || item.key === 'communityParent',
      )
    }
    if (user.role.toLowerCase() === 'school_admin') {
      temp = temp.filter(
        item =>
          item.key !== 'clinics' &&
          item.key !== 'tutorial' &&
          item.key !== 'calendar' &&
          item.key !== 'chat' &&
          item.key !== 'communityParent' &&
          item.key !== 'meetHistory' &&
          item.key !== 'history',
      )
    }
    if (user.role.toLowerCase() === 'therapist') {
      temp = temp.filter(
        item =>
          item.key === 'calendar' ||
          item.key === 'chat' ||
          item.key === 'logo' ||
          item.key === 'community' ||
          item.key === 'meetHistory',
      )
      temp = temp.map(item => {
        if (item.key === 'community') {
          const filteredChildren = item.children.filter(child => child.key !== 'bcba')
          return { ...item, children: filteredChildren }
        }
        return item
      })
    }
    if (user.role.toLowerCase() === 'superuser') {
      temp = temp.filter(
        item =>
          item.key !== 'assessment' &&
          item.key !== 'learners' &&
          item.key !== 'reports' &&
          item.key !== 'calendar' &&
          item.key !== 'chat' &&
          item.key !== 'communityParent' &&
          item.key !== 'meetHistory' &&
          item.key !== 'community',
      )
      temp = temp.map(item => {
        if (item.key === 'community') {
          const filteredChildren = item.children.filter(child => child.key !== 'bcba')
          return { ...item, children: filteredChildren }
        }
        return item
      })
      // temp=temp.filter(item=>item.key==='community' && item.children && )
    }

    setItems(temp)
  }, [user.role])

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
      navigate,
    })
  }

  const onClick = e => {
    setCurrent(e.key)
    if (e.key === 'setting') {
      navigate('/userprofile')
    }
    if (e.key === 'dashboard') {
      if (role.toLowerCase() === 'superuser') {
        navigate('/dashboard/superUser')
      }
      if (role.toLowerCase() === 'parents') {
        navigate('/dashboard/parent')
      }
      if (role.toLowerCase() === 'school_admin') {
        navigate('/dashboard/clinic')
        // navigate('/clinic/assign')
      }
    }
    if (e.key === 'curriculum') {
      navigate('/superuser/tutorial')
    }
    if (e.key === 'videosCurriculum') {
      navigate('/superuser/videosCurriculum')
    }
    if (e.key === 'history') {
      navigate('/history')
    }
    if (e.key === 'learnerReport') {
      navigate('/reports')
    }
    if (e.key === 'meetingReport') {
      navigate('/meetingReports')
    }
    if (e.key === 'assign') {
      if (role.toLowerCase() === 'superuser') {
        navigate('/superuser/assign')
      }
      if (role.toLowerCase() === 'parents') {
        navigate('/dashboard/parent')
      }
      if (role.toLowerCase() === 'school_admin') {
        navigate('/clinic/assign')
      }
    }
    if (e.key === 'videos') {
      navigate('/video')
    }
    if (e.key === 'clinics') {
      navigate('/superuser/clinics')
    }
    if (e.key === 'learners') {
      navigate('/clinic/learners')
    }
    if (e.key === 'assessment') {
      if (role.toLowerCase() === 'school_admin') {
        navigate('/clinic/learnerAssessment')
      } else {
        navigate('/parent/assessment')
      }
    }
    if (e.key === 'communityParent') {
      navigate('/community')
    }
    if (e.key === 'webinar') {
      if (role.toLowerCase() === 'school_admin') {
        navigate('/community/webinar')
      } else {
        navigate('/webinar')
      }
    }
    if (e.key === 'bcba') {
      if (role.toLowerCase() === 'superuser') {
        navigate('/community/bcba')
      } else {
        navigate('/community/clinic/bcba')
      }
    }
    if (e.key === 'chat') {
      navigate('/bcba/chat')
    }
    if (e.key === 'calendar') {
      navigate('/bcba/calendar')
    }
    if (e.key === 'meetHistory') {
      navigate('/bcba/videoHistory')
    }
    if (e.key === 'watchtimeReport') {
      navigate('/reports/watchTimeReport')
    }
    if (e.key === 'BCBAAvailabilityReport') {
      navigate('/reports/BCBAAvailabilityReport')
    }
    if (e.key === 'BCBAUnitReport') {
      navigate('/reports/bcbaMeetingUnits')
    }

    if (e.key === 'back') {
      navigate(-1)
    }
    if (e.key === 'logout') {
      logout()
    }
  }

  const toggleOpen = () => {
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isMobileMenuOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const BootstrappedMenu = () => {
    if (isMobileView) {
      return (
        <>
          <Drawer
            getContainer={null}
            level={null}
            open={isMobileMenuOpen}
            onMaskClick={toggleOpen}
            onHandleClick={toggleOpen}
            className={styles.drawerDark}
          >
            <MenuLeft menuItems={items} onClick={onClick} current={current} />
          </Drawer>
          {role !== 'superuser' && <NotificationList />}
        </>
      )
    }
    if (isMenuTop) {
      return <MenuTop menuItems={items} onClick={onClick} current={current} />
    }
    return <MenuLeft menuItems={items} onClick={onClick} current={current} />
  }

  return BootstrappedMenu()
}

export default AppMenu
