import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { COLORS } from '../../assests/globalStyle'

const LoadingComponent = () => (
  <Spin
    indicator={<LoadingOutlined />}
    style={{
      margin: '4rem auto',
      textAlign: 'center',
      width: '100%',
      fontSize: '1rem',
      color: COLORS.primary,
    }}
  />
)

export default LoadingComponent
