import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect, useSelector } from 'react-redux'
import { withRouter } from '../withRouter'
// import classNames from 'classnames'

import AppMenu from '../../components/Menu'

const MainLayout = ({ children }) => {
  const { isMenuVisible } = useSelector(state => state.settings)
  return (
    <>
      {isMenuVisible && <AppMenu />}

      <Layout>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div style={{ padding: 0, margin: 0, maxWidth: '100%', height: '100%' }}>{children}</div>
        </Layout.Content>
      </Layout>
    </>
  )
}

export default withRouter(MainLayout)
