import React, { Fragment, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { withRouter } from './withRouter'
import { useDispatch, useSelector } from 'react-redux'
// import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import LoadingComponent from '../components/LoadingComponent'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

const IndexLayout = props => {
  let previousPath = ''
  const { children } = props
  const { location, prevLocation } = props.router
  const { pathname, search } = location
  const user = useSelector(state => state.user)

  useEffect(() => {
    sessionStorage.setItem('previousURL', location.pathname)
  }, [location])

  useEffect(() => {
    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== undefined && deviceId !== 'null' && deviceId) {
      sessionStorage.setItem('deviceId', deviceId)
    }
  }, [user])

  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }, [location, prevLocation])

  const currentPath = pathname + search
  // if (currentPath !== previousPath) {
  //   NProgress.start()
  // }

  setTimeout(() => {
    // NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'login'
    }
    if (pathname === '/dashboard/parent') {
      return 'public'
    }

    // if (pathname === '/user/otp') {
    //   return 'public'
    // }
    // if (pathname === '/community') {
    //   return 'public'
    // }
    // if (pathname === '/webinar') {
    //   return 'public'
    // }
    if (/^\/deactivate-accoun(?=\/|$)/i.test(pathname)) {
      return 'public'
    }

    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isLoginLayout = getLayout() === 'login'
  const isPublic = getLayout() === 'public'
  const userRole = user.role
  const isUserVerified = userRole === 'superuser' ? true : user.isVerified

  const BootstrappedLayout = () => {
    // Autism Screening
    if (isPublic) {
      return <Container>{children}</Container>
    }
    // if (isLoginLayout && isUserAuthorized && !isUserVerified) {
    //   console.log('condition true')

    //   return <Navigate to="/user/otp" />
    // }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <LoadingComponent />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isLoginLayout && !isUserAuthorized) {
      return <Navigate to="/" />
    }
    // redirect to main dashboard when user on login page and authorized
    // if parent
    if (isLoginLayout && isUserAuthorized && userRole === 'parents') {
      // return <Navigate to="/dashboard/alpha" />
      return <Navigate to="/dashboard/parent" />
    }

    // if clinic admin
    if (isLoginLayout && isUserAuthorized && userRole === 'school_admin') {
      // return <Navigate to="/dashboard/clinic_admin" />
      return <Navigate to="/dashboard/clinic" />
    }

    if (isLoginLayout && isUserAuthorized && userRole === 'superuser') {
      // return <Navigate to="/dashboard/clinic_admin" />
      return <Navigate to="/dashboard/superUser" />
    }
    if (isLoginLayout && isUserAuthorized && userRole === 'therapist') {
      // return <Navigate to="/dashboard/clinic_admin" />
      return <Navigate to="/bcba/calendar" />
    }

    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Neurona | %s" title="Neurona Application" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(IndexLayout)
