import Community from './action'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  activeInactiveZoomMeeting,
  assignParentsToBCBA,
  createBcba,
  createHost,
  createMeetRequestComment,
  createZoomMeeting,
  deleteMeetingRequestByParent,
  deleteMeetRequestComment,
  getallBCBA,
  getAllHost,
  getAllZoomMeetings,
  getLearnersByClinic,
  getParentsByBCBA,
  getRequestMeets,
  getRequestMeetsComments,
  getWebinarById,
  sendEmailToBCBA,
  sendMeetingRequestToBCBA,
  updateBcba,
  updateMeetingRequestByParent,
  updateMeetRequestComment,
  updateZoomMeeting,
} from './services'
import { notification } from 'antd'
import Staff from '../staff/action'

export function* GET_ALL_ZOOM_MEETINGS({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarsLoading: true,
    },
  })
  const response = yield call(getAllZoomMeetings, payload)
  if (response) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        webinars: response.getAllZoomMeetings.edges,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarsLoading: false,
    },
  })
}

export function* CREATE_ZOOM_MEETING({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarSaving: true,
    },
  })
  const response = yield call(createZoomMeeting, payload)
  if (response && response.addZoomMeetManually.status) {
    notification.success({
      message: response.addZoomMeetManually.message,
    })
    yield put({
      type: Community.APPEND_ZOOM_MEETING,
      payload: {
        data: response.addZoomMeetManually.data,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarSaving: false,
    },
  })
}

export function* UPDATE_ZOOM_MEETING({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarSaving: true,
    },
  })
  const response = yield call(updateZoomMeeting, payload)
  if (response && response.updateManualZoomMeet.status) {
    notification.success({
      message: response.updateManualZoomMeet.message,
    })
    yield put({
      type: Community.REPLACE_ZOOM_MEETING,
      payload: {
        data: response.updateManualZoomMeet.data,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarSaving: false,
    },
  })
}

export function* ACTIVE_INACTIVE_ZOOM_MEETING({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarSaving: true,
    },
  })
  const response = yield call(activeInactiveZoomMeeting, payload)
  if (response && response.activeInactiveZoomLink.status) {
    notification.success({
      message: response.activeInactiveZoomLink.message,
    })
    yield put({
      type: Community.REPLACE_ZOOM_MEETING,
      payload: {
        data: response.activeInactiveZoomLink.data,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarSaving: false,
    },
  })
}
export function* GET_ALL_HOSTS() {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isHostLoading: true,
    },
  })
  const response = yield call(getAllHost)
  if (response) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        hosts: response.getAllHost.edges,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isHostLoading: false,
    },
  })
}
export function* CREATE_HOST({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      hostSaving: true,
    },
  })
  const response = yield call(createHost, payload)
  if (response && response.createHost.status) {
    notification.success({
      message: response.createHost.message,
    })
    yield put({
      type: Community.APPEND_HOST,
      payload: {
        data: response.createHost.hostData,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      hostSaving: false,
    },
  })
}

export function* GET_WEBINAR_BY_ID({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarByIdLoading: true,
    },
  })
  const response = yield call(getWebinarById, payload)
  if (response && response.getZoomMeetingById) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        webinarById: response.getZoomMeetingById,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isWebinarByIdLoading: false,
    },
  })
}
export function* GET_ALL_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isallBCBALoading: true,
    },
  })
  const response = yield call(getallBCBA, payload)
  if (response && response.getAllStaff) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        allBCBA: response.getAllStaff.edges,
        pageInfo: response.getAllStaff.pageInfo,
        BCBACount: response.getAllStaff.totalCount,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isallBCBALoading: false,
    },
  })
}

export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isallBCBALoading: true,
    },
  })
  let { first, pageNo } = payload
  const { pageInfo, type } = yield select(state => state.community)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  }
  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }

  const response = yield call(getallBCBA, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        allBCBA: response.getAllStaff.edges,
        pageInfo: response.getAllStaff.pageInfo,
        BCBACount: response.getAllStaff.totalCount,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isallBCBALoading: false,
    },
  })
}

export function* CREATE_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isBCBASaving: true,
    },
  })
  const response = yield call(createBcba, payload)
  if (response && response.createStaff.status) {
    notification.success({
      message: response.createStaff.message,
    })
    yield put({
      type: Community.APPEND_BCBA,
      payload: {
        data: response.createStaff.staff,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isBCBASaving: false,
    },
  })
}
export function* UPDATE_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isBCBASaving: true,
    },
  })
  const response = yield call(updateBcba, payload)
  if (response && response.updateStaff.status) {
    notification.success({
      message: response.updateStaff.message,
    })
    const { role } = yield select(state => state.user)
    const { staffs } = yield select(state => state.staff)

    if (role === 'superuser') {
      yield put({
        type: Staff.SET_STATE,
        payload: {
          staffs: [
            ...staffs.map(({ node: item }) => {
              if (item.id === response.updateStaff.staff.id) {
                return { node: { ...item, ...response.updateStaff.staff } }
              }
              return { node: { ...item } }
            }),
          ],
        },
      })
    }
    yield put({
      type: Community.REPLACE_BCBA,
      payload: {
        data: response.updateStaff.staff,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isBCBASaving: false,
    },
  })
}
export function* GET_PARENTS_BY_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      parentsByBCBALoading: true,
    },
  })
  const response = yield call(getParentsByBCBA, payload)
  if (response && response.getStaffLearners) {
    const { parentsByBCBA } = yield select(state => state.community)

    yield put({
      type: Community.SET_STATE,
      payload: {
        parentsByBCBA: {
          ...parentsByBCBA,
          [payload.staffId]: response.getStaffLearners,
        },
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      parentsByBCBALoading: false,
    },
  })
}
export function* ASSIGN_PARENTS_TO_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isParentAssigning: true,
    },
  })
  const response = yield call(assignParentsToBCBA, payload)
  if (response && response.updateLearnersStaff.status) {
    notification.success({
      message: response.updateLearnersStaff.message,
    })
    yield put({
      type: Community.APPEND_PARENTS_TO_BCBA,
      payload: {
        staffId: payload.staffId,
        data: response.updateLearnersStaff.studentsDetails,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isParentAssigning: false,
    },
  })
}
// export function* PAGE_CHANGED({ payload }) {
//   yield put({
//     type: Community.SET_STATE,
//     payload: {
//       isallBCBALoading: true,
//     },
//   })
//   let { first, pageNo } = payload
//   const { pageInfo, type } = yield select(state => state.learner)

//   let after = null
//   let before = null
//   let last = null

//   if (pageNo === 1) {
//     after = null
//     before = null
//   }
//   if (type === 'next') {
//     after = pageInfo?.endCursor
//   }
//   if (type === 'prev') {
//     before = pageInfo?.startCursor
//     last = first
//     first = null
//   }

//   const response = yield call(getLearners, { ...payload, after, before, first, last })
//   if (response) {
//     yield put({
//       type: Community.SET_STATE,
//       payload: {
//         learners: response.students.edges,
//         pageInfo: response.students.pageInfo,
//         learnerCount: response.students.totalCount,
//       },
//     })
//   }
//   yield put({
//     type: Community.SET_STATE,
//     payload: {
//       isallBCBALoading: false,
//     },
//   })
// }
export function* GET_LEARNERS() {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const response = yield call(getLearnersByClinic)
  if (response) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        learners: response.students.edges,
        learnersFetched: true,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export function* SEND_EMAIL_TO_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isEmailSending: true,
    },
  })
  const response = yield call(sendEmailToBCBA, payload)
  if (response && response.sendEmailToBcba.status) {
    notification.success({
      message: response.sendEmailToBcba.message,
    })
    yield put({
      type: Community.SET_STATE,
      payload: {
        isSendEmailDrawer: false,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isEmailSending: false,
    },
  })
}

export function* SEND_MEETING_REQUEST_TO_BCBA({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestSending: true,
    },
  })
  const response = yield call(sendMeetingRequestToBCBA, payload)
  if (response && response.createRequestMeet.status) {
    notification.success({
      message: response.createRequestMeet.message,
    })
    yield put({
      type: Community.APPEND_MEETING_REQUEST,
      payload: {
        data: response.createRequestMeet.data,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestSending: false,
    },
  })
}

export function* UPDATE_MEETING_REQUEST_BY_PARENT({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestSending: true,
    },
  })
  const response = yield call(updateMeetingRequestByParent, payload)
  if (response && response.updateRequestMeet.status) {
    notification.success({
      message: response.updateRequestMeet.message,
    })
    yield put({
      type: Community.REPLACE_MEETING_REQUEST,
      payload: {
        data: response.updateRequestMeet.data,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestSending: false,
    },
  })
}

function* DELETE_MEETING_REQUEST_BY_PARENT({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestSending: true,
    },
  })
  const response = yield call(deleteMeetingRequestByParent, payload)
  if (response && response.deleteMeetRequest.status) {
    notification.success({
      message: response.deleteMeetRequest.message,
    })

    yield put({
      type: Community.POP_MEETING_REQUEST,
      payload: {
        meetingId: payload.meetingId,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestSending: false,
    },
  })
}

export function* GET_REQUEST_MEETS({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestMeetsLoading: true,
    },
  })
  const response = yield call(getRequestMeets, payload)
  if (response && response.getRequestMeets) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        requestMeets: response.getRequestMeets.edges,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isRequestMeetsLoading: false,
    },
  })
}
export function* GET_REQUEST_MEETS_COMMENTS({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentsLoading: true,
    },
  })
  const response = yield call(getRequestMeetsComments, payload)
  if (response && response.getRequestMeetComments) {
    yield put({
      type: Community.SET_STATE,
      payload: {
        comments: response.getRequestMeetComments.edges,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentsLoading: false,
    },
  })
}
export function* CREATE_MEET_REQUEST_COMMENT({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentSaving: true,
    },
  })
  const response = yield call(createMeetRequestComment, payload)
  if (response && response.createMeetRequestComment.status) {
    notification.success({
      message: response.createMeetRequestComment.message,
    })
    yield put({
      type: Community.APPEND_MEET_REQUEST_COMMENT,
      payload: {
        meetingId: payload.meetingId,
        data: response.createMeetRequestComment.commentData,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentSaving: false,
    },
  })
}

export function* UPDATE_MEET_REQUEST_COMMENT({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentSaving: true,
    },
  })
  const response = yield call(updateMeetRequestComment, payload)
  if (response && response.updateMeetRequestComment.status) {
    notification.success({
      message: response.updateMeetRequestComment.message,
    })
    yield put({
      type: Community.REPLACE_MEET_REQUEST_COMMENT,
      payload: {
        commentId: payload.meetCommentId,
        meetingId: payload.meetingId,
        data: response.updateMeetRequestComment.commentData,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentSaving: false,
    },
  })
}

export function* DELETE_MEET_REQUEST_COMMENT({ payload }) {
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentSaving: true,
    },
  })
  const response = yield call(deleteMeetRequestComment, payload)
  if (response && response.deleteMeetRequestComment.status) {
    notification.success({
      message: response.deleteMeetRequestComment.message,
    })
    yield put({
      type: Community.POP_MEET_REQUEST_COMMENT,
      payload: {
        commentId: payload.meetCommentId,
        meetingId: payload.meetingId,
      },
    })
  }
  yield put({
    type: Community.SET_STATE,
    payload: {
      isCommentSaving: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(Community.GET_ALL_ZOOM_MEETINGS, GET_ALL_ZOOM_MEETINGS),
    takeLatest(Community.CREATE_ZOOM_MEETING, CREATE_ZOOM_MEETING),
    takeLatest(Community.UPDATE_ZOOM_MEETING, UPDATE_ZOOM_MEETING),
    takeLatest(Community.ACTIVE_INACTIVE_ZOOM_MEETING, ACTIVE_INACTIVE_ZOOM_MEETING),
    takeLatest(Community.CREATE_HOST, CREATE_HOST),
    takeLatest(Community.GET_ALL_HOSTS, GET_ALL_HOSTS),
    takeLatest(Community.GET_WEBINAR_BY_ID, GET_WEBINAR_BY_ID),
    //BCBA
    takeLatest(Community.GET_ALL_BCBA, GET_ALL_BCBA),
    takeLatest(Community.PAGE_CHANGED, PAGE_CHANGED),

    takeLatest(Community.CREATE_BCBA, CREATE_BCBA),
    takeLatest(Community.UPDATE_BCBA, UPDATE_BCBA),
    takeLatest(Community.GET_PARENTS_BY_BCBA, GET_PARENTS_BY_BCBA),
    takeLatest(Community.ASSIGN_PARENTS_TO_BCBA, ASSIGN_PARENTS_TO_BCBA),
    takeLatest(Community.GET_LEARNERS, GET_LEARNERS),
    takeLatest(Community.SEND_EMAIL_TO_BCBA, SEND_EMAIL_TO_BCBA),
    takeLatest(Community.SEND_MEETING_REQUEST_TO_BCBA, SEND_MEETING_REQUEST_TO_BCBA),
    takeLatest(Community.UPDATE_MEETING_REQUEST_BY_PARENT, UPDATE_MEETING_REQUEST_BY_PARENT),
    takeLatest(Community.DELETE_MEETING_REQUEST_BY_PARENT, DELETE_MEETING_REQUEST_BY_PARENT),

    takeLatest(Community.GET_REQUEST_MEETS, GET_REQUEST_MEETS),
    takeLatest(Community.GET_REQUEST_MEETS_COMMENTS, GET_REQUEST_MEETS_COMMENTS),

    takeLatest(Community.CREATE_MEET_REQUEST_COMMENT, CREATE_MEET_REQUEST_COMMENT),
    takeLatest(Community.UPDATE_MEET_REQUEST_COMMENT, UPDATE_MEET_REQUEST_COMMENT),
    takeLatest(Community.DELETE_MEET_REQUEST_COMMENT, DELETE_MEET_REQUEST_COMMENT),
  ])
}
