import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Menu } from 'antd'
import styles from './style.module.scss'
import store from 'store'

import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import ProfileMenu from '../MenuTop/ProfileMenu'

const MenuLeft = ({ menuItems, current, onClick }) => {
  const user = useSelector(state => state.user)
  const { isMobileView } = useSelector(state => state.settings)

  const { role } = user

  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  const onOpenChange = openeKeys => {
    store.set('app.menu.openedKeys', openeKeys)
    setOpenedKeys(openeKeys)
  }

  return (
    <>
      <div className={styles.logo}>
        <div className={styles.logoContainer}>
          <img
            src="../../../../images/logo.png"
            alt="CogniAble Logo"
            style={{ marginLeft: '14px' }}
          />
        </div>
      </div>
      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...pro }) => (
          <div
            {...pro}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        <div
          style={{
            justifyContent: 'center',
            marginLeft: '10%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <strong style={{ fontSize: 12 }}>
            Hello,&nbsp;
            {user.role === 'parents'
              ? user.firstName
              : user.role === 'school_admin'
              ? user.firstName
              : 'Anonymous'}
          </strong>
          <div>
            <strong style={{ fontSize: 12 }}>Role: </strong>
            {user.role === 'parents' ? 'Parent' : ''}
            {user.role.toLowerCase() === 'superuser' ? 'Super Admin' : ''}
            {user.role === 'school_admin' ? 'Clinic' : ''}
          </div>
        </div>

        <Menu
          style={{ flex: 'auto', minWidth: 0 }}
          // theme="dark"
          onClick={onClick}
          selectedKeys={[current]}
          mode="inline"
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          items={[
            ...menuItems.filter(
              item => item.key !== 'logo' && item.key !== 'logout' && item.key !== 'back',
            ),

            role !== 'parents' && {
              label: 'Settings',
              key: 'setting',
              icon: <SettingOutlined />,
            },
            {
              label: 'Log Out',
              key: 'logout',
              icon: <LogoutOutlined />,
            },
          ]}
        />
      </Scrollbars>
      {/* </Sider> */}
    </>
  )
}
export default MenuLeft
