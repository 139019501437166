/* eslint-disable no-unused-vars */
import { notification } from 'antd'

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function getCountry(payload) {
  return client
    .query({
      query: gql`
        query country($name: String) {
          country(isActive: true, name_Icontains: $name) {
            edges {
              node {
                id
                name
                isActive
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getLanguage() {
  return client
    .query({
      query: gql`
        query {
          getLanguages {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getWatchTimeGraphData(payload) {
  return client
    .query({
      query: gql`
        query getVideoWatchTimeFetchBySuperadmin($startDate: Date!, $endDate: Date!) {
          getVideoWatchTimeFetchBySuperadmin(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getDashboard() {
  return client
    .query({
      query: gql`
        query {
          getSuperadminDashboardData {
            totalVideos
            videosAssignedToClinic
            videosAssignedToLearners
            totalLearners
            activeLearners
            inactiveLearners
            totalClinics
            activeClinics
            inactiveClinics
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getVideosInHierarchy() {
  return client
    .query({
      query: gql`
        query {
          getVideosModules {
            edges {
              node {
                id
                name
                moduleOrder
                isActive
                tutorialvideossubmoduleSet {
                  edges {
                    node {
                      id
                      name
                      isActive
                      tutorialvideosSet {
                        edges {
                          node {
                            id
                            totalLikes
                            totalDislikes
                            averageRating
                            videoViews
                            isActive
                            video {
                              id
                              videoName
                              videoUrl
                              videoDocFile
                            }
                            module {
                              id
                            }
                            subModule {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getVideos() {
  return client
    .query({
      query: gql`
        query {
          getTutorialVideos {
            edges {
              node {
                totalLikes
                totalDislikes
                averageRating
                id
                video {
                  id
                  videoName
                  videoUrl
                }
                isActive
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getClinics(payload) {
  return client
    .query({
      query: gql`
        query schools(
          $name: String
          $isActive: Boolean
          $email: String
          $first: Int
          $last: Int
          $after: String
          $before: String
        ) {
          schools(
            schoolName_Icontains: $name
            isActive: $isActive
            email_Icontains: $email
            first: $first
            last: $last
            after: $after
            before: $before
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                schoolName
                email
                contactNo
                noLearner
                activeStaffCount
                inactiveStaffCount
                isActive
                isDefault
                isVerified
                country {
                  id
                  name
                  isActive
                }
                studentsSet {
                  edges {
                    node {
                      id
                    }
                  }
                }
                clinictutorialvideosSet {
                  totalCount
                  edges {
                    node {
                      id
                      clinic {
                        id
                        schoolName
                      }
                      tutorialVideos {
                        id
                        video {
                          id
                          videoName
                          videoUrl
                        }
                        isActive
                        module {
                          id
                          name
                          isActive
                        }
                        subModule {
                          id
                          name
                          isActive
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createClinic(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation clinicSignup(
          $schoolName: String!
          $email: String!
          $country: ID!
          $noLearner: Int!
        ) {
          clinicSignup(
            input: {
              data: { name: $schoolName, email: $email, country: $country, noLearner: $noLearner }
            }
          ) {
            message
            status
            school {
              id
              schoolName
              email
              contactNo
              noLearner
              isActive
              isDefault
              isVerified
              country {
                id
                name
                isActive
              }
              studentsSet {
                edges {
                  node {
                    id
                  }
                }
              }
              clinictutorialvideosSet {
                edges {
                  node {
                    id
                    clinic {
                      id
                      schoolName
                    }
                    tutorialVideos {
                      id
                      video {
                        id
                        videoName
                        videoUrl
                      }

                      isActive
                      module {
                        id
                        name
                        isActive
                      }
                      subModule {
                        id
                        name
                        isActive
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function updateClinic(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateClinic(
          $clinicId: ID!
          $schoolName: String!
          $email: String
          $mobileNo: String
          $address: String
          $noLearner: Int
          $isDefault: Boolean
          $country: ID!
        ) {
          updateClinic(
            input: {
              id: $clinicId
              clinicName: $schoolName
              email: $email
              contactNo: $mobileNo
              address: $address
              noLearner: $noLearner
              isDefault: $isDefault
              country: $country
            }
          ) {
            message
            status
            school {
              id
              schoolName
              email
              contactNo
              noLearner
              isActive
              isDefault
              isVerified
              country {
                id
                name
                isActive
              }
              studentsSet {
                edges {
                  node {
                    id
                  }
                }
              }
              clinictutorialvideosSet {
                edges {
                  node {
                    id
                    clinic {
                      id
                      schoolName
                    }
                    tutorialVideos {
                      id
                      video {
                        id
                        videoName
                        videoUrl
                      }
                      isActive
                      module {
                        id
                        name
                        isActive
                      }
                      subModule {
                        id
                        name
                        isActive
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function activeInActiveClinic(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation activeInactiveClinic($clinicId: ID!, $isActive: Boolean!) {
          activeInactiveClinic(input: { pk: $clinicId, isActive: $isActive }) {
            message
            status
            details {
              id
              schoolName
              email
              contactNo
              noLearner
              isActive
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getVideosList(payload) {
  return client
    .query({
      query: gql`
        query student($studentId: ID!) {
          student(studentId: $studentId) {
            id
            parent {
              id
            }
            studentstutorialvideosSet {
              edges {
                node {
                  id
                  userWatchTime
                  videoPlayCount
                  videoCompletedCount
                  status
                  tutorialVideos {
                    id
                    video {
                      id
                      videoName
                      videoUrl
                    }
                    module {
                      id
                      name
                    }
                    subModule {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnetWatchData(payload) {
  return client
    .query({
      query: gql`
        query getLearnerWatchTime($watchBy: ID!, $videoId: ID!) {
          getLearnerWatchTime(watchBy: $watchBy, tutorialVideo: $videoId) {
            edges {
              node {
                id
                userStartWatching
                userEndWatching
                videoStartPoint
                videoEndPoint
                watchTime
                status
                tutorialVideo {
                  id
                }
                watchBy {
                  id
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getClinicVideosByAdmin(payload) {
  return client
    .query({
      query: gql`
        query getAllClinicsVideosByAdmin($clinicId: ID!) {
          getAllClinicsVideosByAdmin(clinic: $clinicId) {
            edges {
              node {
                id
                clinic {
                  id
                  schoolName
                }
                tutorialVideos {
                  id
                  video {
                    id
                    videoName
                    videoUrl
                  }
                  isActive
                  module {
                    id
                    name
                    isActive
                  }
                  subModule {
                    id
                    name
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
