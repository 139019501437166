import ZoomMeeting from './action'

const initialState = {
  isZoomMeetingSaving: false,
  isZoomSignatureLoading: false,
  signature: null,
  createdZoomMeeting: null,
  isMeetDetailLoading: false,
  zoomMeetingDetails: null,
  isMeetingSaving: false,
  isIncomingCallModalVisible: false,
  isConnectingModalVisible: false,
  meetStatus: 'incoming',
  isInstantCallLoading: false,
  meetCalls: [],
  manualMeetCalls: [],
  isZoomMeetEditDrawer: false,
  selectedZoomMeeting: null,
  totalManualMeetingCount: 0,
}

export default function zoomMeetingReducer(state = initialState, action) {
  switch (action.type) {
    case ZoomMeeting.SET_STATE:
      return { ...state, ...action.payload }
    case ZoomMeeting.REPLACE_ZOOM_MEET: {
      return {
        ...state,
        selectedZoomMeeting: null,
        isZoomMeetEditDrawer: false,
        manualMeetCalls: [
          ...state.manualMeetCalls.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    default:
      return state
  }
}
