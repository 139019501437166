import Staff from './action'

const initialState = {
  selectedStaff: null,
  isStaffSaving: false,
  isRequestMeetsLoading: false,
  requestMeets: [],
  isMeetEditDrawer: false,
  isMeetSaving: false,
  selectedMeeting: null,
  staffLearners: [],
  isLearnerLoading: false,

  //chat
  selectedLearnerChat: null,
  isLearnerChatLoading: false,
  learnerChat: [],
  isMessageSending: false,

  //availability
  staffSlots: [],
  isSlotLoading: false,
  isSlotSaving: false,

  //dateAvailability
  isDateSlotsLoading: false,
  dateSlots: null,

  //allBCBA
  isAllBCBALoading: false,
  allBCBAInSuperAdmin: [],
  allBCBAForWebinar:[],

  isStaffLoading: false,
  staffs: [],
}

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case Staff.SET_STATE:
      return { ...state, ...action.payload }

    case Staff.REPLACE_MEET_REQUEST_BY_BCBA: {
      const { data } = action.payload

      return {
        ...state,
        requestMeets: [
          ...state.requestMeets.map(({ node: item }) => {
            if (item.id === data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
        isMeetEditDrawer: false,
        selectedMeeting: null,
      }
    }
    case Staff.REPLACE_SLOT:
      return { ...state, staffSlots: action.payload.data }
    default:
      return state
  }
}
