import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function getAllZoomMeetings(payload) {
  return client
    .query({
      query: gql`
        query getAllZoomMeetings($startDate: Date, $endDate: Date, $isActive: Boolean) {
          getAllZoomMeetings(
            fromDate: $startDate
            toDate: $endDate
            isActive: $isActive
            last: 12
          ) {
            edges {
              node {
                id
                linkDetails
                topic
                startTime
                duration
                joinUrl
                password
                isActive
                status
                meetingId
                recordingUrl
                description
                bcbaHost {
                  id
                  email
                  firstname
                  lastname
                  image
                  designation
                }
                manualHost {
                  id
                  email
                  name
                  image
                  designation
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createZoomMeeting(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation addZoomMeetManually(
          $topic: String!
          $startTime: DateTime!
          $durationMin: Int!
          $password: String
          $joinUrl: String!
          $hostId: ID
          $staffId: ID
          $description: String
        ) {
          addZoomMeetManually(
            input: {
              topic: $topic
              startTime: $startTime
              durationMin: $durationMin
              password: $password
              joinUrl: $joinUrl
              hostId: $hostId
              staffId: $staffId
              description: $description
            }
          ) {
            message
            status
            data {
              id
              linkDetails
              topic
              startTime
              duration
              joinUrl
              password
              isActive
              description
              status
              recordingUrl
              bcbaHost {
                id
                email
                firstname
                lastname
                image
                designation
              }
              manualHost {
                id
                email
                name
                image
                designation
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateZoomMeeting(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateManualZoomMeet(
          $pk: ID!
          $topic: String!
          $startTime: DateTime!
          $durationMin: Int!
          $password: String
          $joinUrl: String!
          $hostId: ID
          $description: String
          $status: String
          $recordingUrl: String
          $staffId: ID
        ) {
          updateManualZoomMeet(
            input: {
              pk: $pk
              topic: $topic
              startTime: $startTime
              durationMin: $durationMin
              password: $password
              joinUrl: $joinUrl
              hostId: $hostId
              description: $description
              status: $status
              recordingUrl: $recordingUrl
              staffId: $staffId
            }
          ) {
            message
            status
            data {
              id
              linkDetails
              topic
              startTime
              duration
              joinUrl
              password
              isActive
              description
              status
              recordingUrl
              bcbaHost {
                id
                email
                firstname
                lastname
                image
                designation
              }
              manualHost {
                id
                email
                name
                image
                designation
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function activeInactiveZoomMeeting(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation activeInactiveZoomLink($pk: ID!, $isActive: Boolean!) {
          activeInactiveZoomLink(input: { pk: $pk, isActive: $isActive }) {
            message
            status
            data {
              id
              linkDetails
              topic
              startTime
              duration
              joinUrl
              password
              isActive
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getAllHost() {
  return client
    .query({
      query: gql`
        query {
          getAllHost {
            edges {
              node {
                id
                email
                name
                image
                designation
                description
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createHost(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createHost(
          $email: String!
          $name: String!
          $designation: String!
          $image: String
        ) {
          createHost(
            input: { email: $email, name: $name, designation: $designation, image: $image }
          ) {
            status
            message
            hostData {
              id
              name
              email
              description
              designation
              image
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getWebinarById(payload) {
  return client
    .query({
      query: gql`
        query getZoomMeetingById($webinarId: ID!) {
          getZoomMeetingById(id: $webinarId) {
            id
            linkDetails
            topic
            startTime
            duration
            joinUrl
            password
            isActive
            status
            recordingUrl
            description
            manualHost {
              id
              email
              name
              image
              designation
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getallBCBA(payload) {
  return client
    .query({
      query: gql`
        query getAllStaff(
          $isActive: Boolean
          $first: Int
          $last: Int
          $after: String
          $before: String
          $firstname: String
          $lastname: String
          $email: String
        ) {
          getAllStaff(
            isActive: $isActive
            first: $first
            last: $last
            after: $after
            before: $before
            email_Icontains: $email
            firstname_Icontains: $firstname
            lastname_Icontains: $lastname
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                email
                firstname
                lastname
                contactNo
                gender
                dob
                address
                isActive
                isDefaultBcba
                languages {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                designation
                education
                experience
                city
                state
                country {
                  id
                  name
                }
                user {
                  id
                  email
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createBcba(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createStaff(
          $email: String!
          $firstname: String!
          $lastname: String
          $mobileNo: String
          $gender: String
          $address: String
          $languages: [ID]
          $designation: String
          $education: String
          $experience: String
          $city: String
          $state: String
          $country: ID
          $meetLink: String
          $password: String
          $isDefaultBcba: Boolean
        ) {
          createStaff(
            input: {
              email: $email
              firstname: $firstname
              lastname: $lastname
              contactNo: $mobileNo
              gender: $gender
              address: $address
              languages: $languages
              designation: $designation
              education: $education
              experience: $experience
              city: $city
              state: $state
              country: $country
              meetLink: $meetLink
              password: $password
              isDefaultBcba: $isDefaultBcba
            }
          ) {
            status
            message
            staff {
              id
              email
              firstname
              lastname
              contactNo
              gender
              dob
              address
              isActive
              meetLink
              isDefaultBcba
              languages {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              designation
              education
              experience
              city
              state
              country {
                id
                name
              }
              user {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateBcba(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateStaff(
          $id: ID!
          $email: String!
          $firstname: String!
          $lastname: String
          $mobileNo: String
          $gender: String
          $address: String
          $isActive: Boolean
          $languages: [ID]
          $designation: String
          $education: String
          $experience: String
          $city: String
          $state: String
          $country: ID
          $meetLink: String
          $isDefaultBcba: Boolean
        ) {
          updateStaff(
            input: {
              id: $id
              email: $email
              firstname: $firstname
              lastname: $lastname
              contactNo: $mobileNo
              gender: $gender
              address: $address
              isActive: $isActive
              languages: $languages
              designation: $designation
              education: $education
              experience: $experience
              city: $city
              state: $state
              country: $country
              meetLink: $meetLink
              isDefaultBcba: $isDefaultBcba
            }
          ) {
            status
            message
            staff {
              id
              email
              firstname
              lastname
              contactNo
              gender
              dob
              address
              isActive
              meetLink
              isDefaultBcba
              languages {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              designation
              education
              experience
              city
              state
              country {
                id
                name
              }
              user {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getParentsByBCBA(payload) {
  return client
    .query({
      query: gql`
        query getStaffLearners($staffId: ID!) {
          getStaffLearners(staffId: $staffId) {
            id
            email
            firstname
            lastname
            image
            caseManager {
              id
              firstname
              lastname
              email
            }
            parent {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function assignParentsToBCBA(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateLearnersStaff($staffId: ID!, $learnerIds: [ID]!) {
          updateLearnersStaff(input: { therapistId: $staffId, learnerIds: $learnerIds }) {
            status
            message
            studentsDetails {
              id
              email
              firstname
              lastname
              image
              caseManager {
                id
                firstname
                lastname
                email
              }
              parent {
                id
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnersByClinic() {
  return client
    .query({
      query: gql`
        query {
          students {
            edges {
              node {
                id
                email
                firstname
                lastname
                image
                caseManager {
                  id
                  email
                  firstname
                  lastname
                }
                parent {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,

      fetchPolicy: 'no-cache',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function sendEmailToBCBA(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation sendEmailToBcba($staffId: ID!, $context: String!, $subject: String!) {
          sendEmailToBcba(input: { staffId: $staffId, emailContext: $context, subject: $subject }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function sendMeetingRequestToBCBA(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createRequestMeet(
          $staffId: ID!
          $title: String!
          $startTime: DateTime!
          $endTime: DateTime!
          $localStartTime: DateTime
          $localEndTime: DateTime
        ) {
          createRequestMeet(
            input: {
              topic: $title
              startTime: $startTime
              endTime: $endTime
              staffId: $staffId
              localStartTime: $localStartTime
              localEndTime: $localEndTime
            }
          ) {
            message
            status
            data {
              id
              startTime
              endTime
              localStartTime
              localEndTime
              status
              topic
              meetLink
              staff {
                id
                email
              }
              learner {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateMeetingRequestByParent(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateRequestMeet(
          $meetingId: ID!
          $title: String!
          $startTime: DateTime!
          $endTime: DateTime!
          $localStartTime: DateTime
          $localEndTime: DateTime
        ) {
          updateRequestMeet(
            input: {
              id: $meetingId
              topic: $title
              startTime: $startTime
              endTime: $endTime
              localStartTime: $localStartTime
              localEndTime: $localEndTime
            }
          ) {
            message
            status
            data {
              id
              startTime
              endTime
              localStartTime
              localEndTime
              status
              topic
              meetLink
              staff {
                id
                email
              }
              learner {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function deleteMeetingRequestByParent(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation deleteMeetRequest($meetingId: ID!) {
          deleteMeetRequest(input: { id: $meetingId }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getRequestMeets(payload) {
  return client
    .query({
      query: gql`
        query getRequestMeets($staffId: ID!) {
          getRequestMeets(staff: $staffId) {
            edges {
              node {
                id
                startTime
                endTime
                status
                topic
                meetLink
                staff {
                  id
                  email
                }
                learner {
                  id
                  email
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },

      fetchPolicy: 'no-cache',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createMeetRequestComment(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createMeetRequestComment($meetingId: ID!, $comment: String!) {
          createMeetRequestComment(input: { meetRequestId: $meetingId, comment: $comment }) {
            status
            message
            commentData {
              id
              comment
              addedBy {
                id
                firstName
                lastName
                isStaff
                students {
                  id
                  image
                }
                staff {
                  id
                  image
                }
              }
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateMeetRequestComment(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateMeetRequestComment($meetCommentId: ID!, $comment: String!) {
          updateMeetRequestComment(input: { meetCommentId: $meetCommentId, comment: $comment }) {
            status
            message
            commentData {
              id
              comment
              addedBy {
                id
                firstName
                lastName
                isStaff
                students {
                  id
                  image
                }
                staff {
                  id
                  image
                }
              }
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function deleteMeetRequestComment(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation deleteMeetRequestComment($meetCommentId: ID!) {
          deleteMeetRequestComment(input: { meetCommentId: $meetCommentId }) {
            status
            message
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getRequestMeetsComments(payload) {
  return client
    .query({
      query: gql`
        query getRequestMeetComments($meetRequestId: ID!) {
          getRequestMeetComments(meetRequestId: $meetRequestId) {
            edges {
              node {
                id
                comment
                addedBy {
                  id
                  firstName
                  lastName
                  isStaff
                  students {
                    id
                    image
                  }
                  staff {
                    id
                    image
                  }
                }
                createdAt
                updatedAt
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
