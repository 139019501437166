import { CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from '@ant-design/icons'
import {
  Avatar,
  Badge,
  Button,
  Col,
  List,
  Popover,
  Typography,
  notification,
  Space,
  Modal,
  FloatButton,
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COLORS } from '../../assests/globalStyle'
import { useNavigate } from 'react-router-dom'
import User from '../../redux/user/action'
import { BiSolidBellRing } from 'react-icons/bi'
import './style.scss'
import { generateUniqueId, getLearnerName, reverseArray } from '../../Utilities'
import { messaging } from '../../firebase'
import { onMessage } from 'firebase/messaging'
// import { onBackgroundMessage } from 'firebase/messaging/sw'
import _ from 'lodash'
import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'
import ZoomMeetingAction from '../../redux/ZoomMeeting/action'
import { SiGooglemeet } from 'react-icons/si'
import { useSearchParams } from 'react-router-dom'

const { Title, Text } = Typography

const NotificationList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { isNotificationLoading, notifications, role, notificationPopoverVisible, staff } =
    useSelector(state => state.user)

  const { isMobileView } = useSelector(state => state.settings)

  const { isIncomingCallModalVisible } = useSelector(state => state.zoomMeeting)
  const [allNotification, setAllNotification] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)
  const [currentNotification, setCurrentNotification] = useState(null)
  const [audio, setAudio] = useState(null)
  const audioRef = useRef(null)

  useEffect(() => {
    const unsubscribe = onMessage(messaging, payload => {
      console.log('payload==>', payload, notifications)
      const {
        data: {
          title,
          body,
          notification_type,
          actor_object_id,
          notification_id,
          firstname,
          lastname,
          image,
          is_admin,
        },
      } = payload

      const obj = {
        id: notification_id,
        title,
        description: body,
        timestamp: dayjs(),
        read: false,
        notifyType: _.toUpper(notification_type),
        actorObjectId: actor_object_id,
        name: `${firstname} ${lastname} `,
        image,
        isReminder: is_admin,
      }

      if (notification_type === 'meet_call' && title.includes('Incoming')) {
        setCurrentNotification(payload.data)
        dispatch({
          type: ZoomMeetingAction.SET_STATE,
          payload: {
            isIncomingCallModalVisible: true,
          },
        })
      } else {
        notification.info({
          key: notification_id,
          message: title,
          description: body,
        })
        if (notification_type !== 'meet_call') {
          dispatch({
            type: User.GET_ALL_NOTIFICATIONS,
            // payload: {
            //   notifications: [...notifications, { node: obj }],
            // },
          })
        }
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    // Play the audio when the modal opens
    if (isIncomingCallModalVisible && currentNotification) {
      const timer = setTimeout(() => {
        handleNoResponse()
      }, 30000) // 30 seconds
      // playAudio()

      return () => {
        clearTimeout(timer)
      }
      // Store the audio element
    } else {
      // stopAudio() // Pause the audio if the modal is closed
    }
  }, [isIncomingCallModalVisible, currentNotification])

  const playAudio = () => {
    if (audioRef.current && audioRef) {
      audioRef.current.play()
    }
  }

  const stopAudio = () => {
    if (audioRef.current && audioRef) {
      audioRef.current.pause()
    }
  }

  useEffect(() => {
    if (searchParams && searchParams.get('call') && (role === 'therapist' || role === 'parents')) {
      dispatch({
        type: ZoomMeetingAction.SET_STATE,
        payload: {
          isIncomingCallModalVisible: true,
        },
      })
      const notificationData = JSON.parse(decodeURIComponent(searchParams.get('uId')))
      setCurrentNotification({
        ...notificationData,
      })
    }
  }, [searchParams])

  useEffect(() => {
    if (notifications) {
      const reversedArray = reverseArray(
        notifications.map(({ node }) => {
          const image =
            node.sender && node.sender.staff
              ? node.sender.staff.image
              : node.sender && node.sender.students
              ? node.sender.students.image
              : '../../../images/smallLogo.jpeg'
          const name = node.sender ? `${node.sender.firstName} ${node.sender.lastName} ` : ''
          return {
            node: {
              ...node,
              name: !node.name ? name : node.name,
              image: !node.image ? image : node.image,
            },
          }
        }),
      )
      setUnreadCount(reversedArray.filter(({ node }) => !node.read).length)
      setAllNotification(reversedArray)
    }
  }, [notifications])

  // const updateMeetingStatus = payload => {
  //   client
  //     .mutate({
  //       mutation: gql`
  //         mutation updateInstantMeetByAttende(
  //           $zoomId: ID!
  //           $status: String!
  //           $userId: ID!
  //           $zoomMeetingId: String!
  //         ) {
  //           updateInstantMeetByAttende(
  //             input: {
  //               instantZoomId: $zoomId
  //               status: $status
  //               userId: $userId
  //               zoomMeetingId: $zoomMeetingId
  //             }
  //           ) {
  //             status
  //             message
  //           }
  //         }
  //       `,
  //       variables: {
  //         ...payload,
  //       },
  //     })
  //     .then(data => {
  //       console.log('done')
  //     })
  //     .catch(err => console.error(err, 'error'))
  // }

  const updateMeetingStatus = payload => {
    client
      .mutate({
        mutation: gql`
          mutation updateInstantMeetByAttende($zoomId: ID!, $status: String!, $userId: ID!) {
            updateInstantManualMeetByAttende(
              input: { userId: $userId, instantZoomId: $zoomId, status: $status }
            ) {
              message
              status
            }
          }
        `,
        variables: {
          ...payload,
        },
      })
      .then(data => {
        console.log('done')
      })
      .catch(err => console.error(err, 'error'))
  }

  const renderData = (
    <List
      size="default"
      dataSource={allNotification}
      style={{ maxHeight: 500, overflowY: 'auto', width: '100%' }}
      renderItem={({ node }) => {
        return (
          <List.Item
            onClick={() => {
              if (!node.read) {
                dispatch({
                  type: User.MARK_NOTIFICATION_READ,
                  payload: {
                    notificationId: node.id,
                  },
                })
              }
              dispatch({
                type: User.SET_STATE,
                payload: {
                  clickedNotificationId: node.actorObjectId,
                  notificationPopoverVisible: false,
                },
              })
              if (node.notifyType === 'REQUEST_MEET' && role === 'parents') {
                navigate('/connectBCBA')
              }
              if (node.notifyType === 'WEBINAR_MEET') {
                navigate('/webinar')
              }
              if (node.notifyType === 'REQUEST_MEET' && role === 'therapist') {
                navigate('/bcba/calendar')
              }
            }}
            style={{
              padding: 10,
              margin: 5,
              marginInline: 0,
              paddingInlineStart: 5,
              backgroundColor: !node.read ? COLORS.secondaryLighten : 'transparent',
            }}
          >
            <Col span={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  size={40}
                  src={node.image}
                  icon={<UserOutlined />}
                  alt="user"
                  shape="square"
                />
              </div>
            </Col>
            <Col span={20}>
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginLeft: 8,
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    padding: '0px',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    // color: `${
                    //   selectedUserProfile && selectedUserProfile.id === row.id ? '#0190fe' : 'black'
                    // }`,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Text strong>{node.title}</Text>
                </div>
                <Text>
                  {!node.isReminder && <Text strong>{node.name}</Text>}
                  {node.description}
                </Text>
                <Text style={{ fontSize: 10, color: COLORS.primary }}>
                  {dayjs(node.timestamp).fromNow()}
                </Text>
              </div>
            </Col>

            <Col span={1}>
              {!node.read && (
                <div
                  style={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: COLORS.secondary,
                  }}
                ></div>
              )}
            </Col>
          </List.Item>
        )
      }}
    />
  )
  const handleOk = () => {
    if (currentNotification) {
      updateMeetingStatus({
        zoomId: currentNotification.instant_meet_id,
        status: 'inprogress',
        zoomMeetingId: currentNotification.meeting_number,
        userId: currentNotification.response_for,
      })
      closeModal()
      window.open(currentNotification?.meet_link, '_blank')
      // navigate(`/bcba/videoHistory?mt=${currentNotification.instant_meet_id}`)
    }
  }
  const handleCancel = () => {
    if (currentNotification) {
      updateMeetingStatus({
        zoomId: currentNotification.instant_meet_id,
        status: 'rejected',
        zoomMeetingId: currentNotification.meeting_number,
        userId: currentNotification.response_for,
      })
      closeModal()
    }
  }

  const handleNoResponse = () => {
    if (currentNotification) {
      updateMeetingStatus({
        zoomId: currentNotification.instant_meet_id,
        status: 'no_response',
        zoomMeetingId: currentNotification.meeting_number,
        userId: currentNotification.response_for,
      })
      closeModal()
    }
  }
  const closeModal = () => {
    // stopAudio() // Stop the audio when the button is clicked

    dispatch({
      type: ZoomMeetingAction.SET_STATE,
      payload: {
        isIncomingCallModalVisible: false,
      },
    })
  }

  return (
    <>
      {/* <audio
        id="audioDiv"
        preload="auto"
        src="audio.mp3" // Replace with your MP3 file's URL
        style={{ display: 'block' }} // Hide the audio element
        autoPlay
      ></audio> */}

      {/* <audio ref={audioRef}>
        <source src="audio.mp3" type="audio/mpeg" />
      </audio> */}
      {isMobileView ? (
        <>
          <Popover
            content={renderData}
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Title level={5} style={{ margin: 0 }}>
                  Notifications
                </Title>
                {unreadCount > 0 && (
                  <Button
                    type="link"
                    onClick={() => {
                      dispatch({
                        type: User.MARK_ALL_READ,
                      })
                    }}
                  >
                    Mark all as read
                  </Button>
                )}
              </div>
            }
            trigger="click"
            overlayClassName="notification"
            visible={notificationPopoverVisible}
            onVisibleChange={val => {
              dispatch({
                type: User.SET_STATE,
                payload: {
                  notificationPopoverVisible: val,
                },
              })
            }}
            placement="bottom"
            overlayStyle={{ minWidth: '200px', marginRight: 10 }} // Adjust the width as needed
          >
            <FloatButton
              icon={
                <BiSolidBellRing size={20} style={{ marginRight: unreadCount >= 10 ? 10 : 5 }} />
              }
              badge={{
                count: unreadCount,
                color: '#f48c2c',
              }}
              type="primary"
              style={{ right: 24, backgroundColor: COLORS.secondary }}
              onClick={() => {
                dispatch({
                  type: User.SET_STATE,
                  payload: {
                    notificationPopoverVisible: true,
                  },
                })
              }}
            />
          </Popover>
        </>
      ) : (
        <Popover
          content={renderData}
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                Notifications
              </Title>
              {unreadCount > 0 && (
                <Button
                  type="link"
                  onClick={() => {
                    dispatch({
                      type: User.MARK_ALL_READ,
                    })
                  }}
                >
                  Mark all as read
                </Button>
              )}
            </div>
          }
          trigger="click"
          overlayClassName="notification"
          visible={notificationPopoverVisible}
          onVisibleChange={val => {
            dispatch({
              type: User.SET_STATE,
              payload: {
                notificationPopoverVisible: val,
              },
            })
          }}
          placement="bottom"
          overlayStyle={{ minWidth: '300px' }} // Adjust the width as needed
        >
          <Button
            type="link"
            style={{ display: 'flex', alignItems: 'center', color: COLORS.blackTitle }}
            className="ant-menu-title-content"
            onClick={() => {
              dispatch({
                type: User.SET_STATE,
                payload: {
                  notificationPopoverVisible: true,
                },
              })
            }}
          >
            <Badge
              status={isNotificationLoading ? 'processing' : null}
              size="default"
              count={unreadCount}
              overflowCount={10}
            >
              <BiSolidBellRing size={20} style={{ marginRight: unreadCount >= 10 ? 10 : 5 }} />
            </Badge>
          </Button>
        </Popover>
      )}

      <Modal
        title=""
        visible={isIncomingCallModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div className="callContainer">
          <Title level={3} style={{ color: COLORS.primary, marginTop: 8 }}>
            Incoming Call
          </Title>
          <div className="call-animation">
            <SiGooglemeet class="caller-img" />
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'space-between', width: '60%', margin: 8 }}
          >
            <Button
              key="accept"
              icon={<CheckCircleOutlined style={{ color: 'white' }} size={40} />}
              onClick={handleOk}
              style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold' }}
              size="large"
            >
              Accept
            </Button>
            <Button
              key="reject"
              icon={<CloseCircleOutlined style={{ color: 'white' }} size={40} />}
              onClick={handleCancel}
              style={{ backgroundColor: COLORS.danger, color: 'white', fontWeight: 'bold' }}
              size="large"
            >
              Reject
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default NotificationList
