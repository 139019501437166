const User = {
  SET_STATE: 'user/SET_STATE',
  GET_DATA: 'user/GET_DATA',
  LOGIN: 'user/LOGIN',
  LOGOUT: 'user/LOGOUT',
  RESET: 'user/RESET',
  SIGNUP: 'user/SIGNUP',
  SEND_OTP: 'user/SEND_OTP',
  VERIFY_OTP: 'user/VERIFY_OTP',
  FORGET_PASSWORD: 'user/FORGET_PASSWORD',
  VERIFY_CHANGE_PASSWORD: 'user/VERIFY_CHANGE_PASSWORD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  CHANGE_USER_PASSWORD: 'user/CHANGE_USER_PASSWORD',
  ACCOUNT_DELETION: 'user/ACCOUNT_DELETION',
  REQUEST_PERMISSION: 'user/REQUEST_PERMISSION',
  REGISTER_USER_ON_FIREBASE: 'user/REGISTER_USER_TOKEN',
  REMOVE_USER_TOKEN: 'user/REMOVE_USER_TOKEN',
  GET_ALL_NOTIFICATIONS: 'user/GET_ALL_NOTIFICATIONS',
  MARK_NOTIFICATION_READ: 'user/MARK_NOTIFICATION_READ',
  REPLACE_NOTIFICATION_READ: 'user/REPLACE_NOTIFICATION_READ',
  MARK_ALL_READ: 'user/MARK_ALL_READ',
  SEND_OTP_FOR_ACCOUNT_DELETE: 'user/SEND_OTP_FOR_ACCOUNT_DELETE',
  VERIFY_OTP_FOR_ACCOUNT_DELETE: 'user/VERIFY_OTP_FOR_ACCOUNT_DELETE',
}

export default User
