import { notification } from 'antd'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import Clinic from '../Clinic/action'
import SuperUser from '../superUser/action'
import Tutorial from './action'

import {
  assignVideosToClinic,
  createCurriculumVideo,
  createModule,
  createSubModule,
  createVideo,
  editModule,
  editSubModule,
  editVideo,
  getAllVideos,
  getClinics,
  getCurriculumVideos,
  getModules,
  getSubModules,
  getVideos,
  updateCurriculumVideo,
} from './services'

export function* GET_CLINICS() {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isClinicLoading: true,
    },
  })
  const response = yield call(getClinics)
  const { selectedClinic } = yield select(state => state.clinic)
  if (response) {
    yield put({
      type: Tutorial.SET_STATE,
      payload: {
        clinics: response.schools.edges.map(({ node }) => ({ ...node })),
      },
    })
    if (response.schools.edges.length) {
      const clinic = response.schools.edges.filter(({ node }) => node.isDefault)
      localStorage.setItem('clinicId', clinic[0].node.id)
      if (!selectedClinic) {
        yield put({
          type: Clinic.SET_STATE,
          payload: {
            selectedClinic: clinic.length ? clinic[0].node : response.schools[0].node,
          },
        })
      }
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isClinicLoading: false,
    },
  })
}
export function* GET_MODULES() {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isModulesLoading: true,
    },
  })
  const response = yield call(getModules)
  const { selectedModule } = yield select(state => state.tutorial)
  if (response) {
    yield put({
      type: Tutorial.SET_STATE,
      payload: {
        modules: response.getVideosModules.edges,
        selectedModule:
          !selectedModule && response.getVideosModules.edges.length
            ? response.getVideosModules.edges[0].node
            : selectedModule,
      },
    })
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isModulesLoading: false,
    },
  })
}

export function* CREATE_MODULE({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isModuleSaving: true,
    },
  })
  const response = yield call(createModule, payload)
  if (response && response.createVideoModule) {
    if (response.createVideoModule.status) {
      notification.success({
        message: response.createVideoModule.message,
      })
      yield put({
        type: Tutorial.APPEND_MODULE,
        payload: response.createVideoModule.videoModule,
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isModuleSaving: false,
    },
  })
}

export function* EDIT_MODULE({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isModuleSaving: true,
    },
  })
  const response = yield call(editModule, payload)
  if (response && response.updateVideoModule) {
    if (response.updateVideoModule.status) {
      notification.success({
        message: response.updateVideoModule.message,
      })
      if (payload.isActive !== undefined) {
        yield put({
          type: Tutorial.GET_SUB_MODULES,
          payload: {
            moduleId: payload.id,
          },
        })
      }
      yield put({
        type: Tutorial.REPLACE_MODULE,
        payload: {
          moduleID: payload.id,
          data: response.updateVideoModule.videoModule,
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isModuleSaving: false,
    },
  })
}

export function* GET_SUB_MODULES({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isSubModuleLoading: true,
    },
  })
  const { selectedModule } = yield select(state => state.tutorial)

  const response = yield call(getSubModules, payload)
  if (response) {
    const subModule = response.getVideosSubModules.edges.length
      ? response.getVideosSubModules.edges[0].node
      : null
    yield put({
      type: Tutorial.SET_STATE,
      payload: {
        subModules: response.getVideosSubModules.edges,
        selectedSubModule: subModule,
      },
    })
    if (subModule && selectedModule) {
      yield put({
        type: Tutorial.GET_VIDEOS,
        payload: {
          moduleId: selectedModule.id,
          submoduleId: subModule.id,
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isSubModuleLoading: false,
    },
  })
}

export function* CREATE_SUB_MODULE({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isSubModuleSaving: true,
    },
  })
  const response = yield call(createSubModule, payload)
  if (response && response.createVideoSubModule) {
    if (response.createVideoSubModule.status) {
      notification.success({
        message: response.createVideoSubModule.message,
      })
      yield put({
        type: Tutorial.APPEND_SUB_MODULE,
        payload: response.createVideoSubModule.videoSubModule,
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isSubModuleSaving: false,
    },
  })
}

export function* EDIT_SUB_MODULE({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isSubModuleSaving: true,
    },
  })
  const { selectedModule } = yield select(state => state.tutorial)

  const response = yield call(editSubModule, payload)
  if (response && response.updateVideoSubModule) {
    if (response.updateVideoSubModule.status) {
      notification.success({
        message: response.updateVideoSubModule.message,
      })
      if (payload.isActive !== undefined) {
        yield put({
          type: Tutorial.GET_VIDEOS,
          payload: {
            moduleId: selectedModule.id,
            submoduleId: payload.submoduleId,
          },
        })
      }
      yield put({
        type: Tutorial.REPLACE_SUB_MODULE,
        payload: {
          subModuleID: payload.submoduleId,
          data: response.updateVideoSubModule.videoSubModule,
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isSubModuleSaving: false,
    },
  })
}

export function* GET_VIDEOS({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isVideoLoading: true,
    },
  })
  const response = yield call(getVideos, payload)
  if (response) {
    yield put({
      type: Tutorial.SET_STATE,
      payload: {
        videos: response.getTutorialVideos.edges,
        selectedVideo: response.getTutorialVideos.edges.length
          ? response.getTutorialVideos.edges[0].node
          : null,
      },
    })
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isVideoLoading: false,
    },
  })
}

export function* CREATE_VIDEO({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isVideoSaving: true,
    },
  })
  const response = yield call(createVideo, payload)
  if (response && response.createTutorialVideo) {
    if (response.createTutorialVideo.status) {
      notification.success({
        message: response.createTutorialVideo.message,
      })
      yield put({
        type: Tutorial.APPEND_VIDEO,
        payload: response.createTutorialVideo.tutorialVideo,
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isVideoSaving: false,
    },
  })
}

export function* EDIT_VIDEO({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isVideoSaving: true,
    },
  })
  const response = yield call(editVideo, payload)
  if (response && response.updateTutorialVideo) {
    if (response.updateTutorialVideo.status) {
      notification.success({
        message: response.updateTutorialVideo.message,
      })
      yield put({
        type: Tutorial.REPLACE_VIDEO,
        payload: {
          videoId: payload.id,
          data: response.updateTutorialVideo.tutorialVideo,
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isVideoSaving: false,
    },
  })
}

export function* GET_ALL_VIDEOS() {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isAllVideoLoading: true,
    },
  })
  const response = yield call(getAllVideos)
  if (response) {
    yield put({
      type: Tutorial.SET_STATE,
      payload: {
        allVideos: response.getTutorialVideos.edges,
      },
    })
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isAllVideoLoading: false,
    },
  })
}

export function* ASSIGN_VIDEOS_TO_CLINIC({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isAssigning: true,
    },
  })
  const selectedClinic = yield select(state => state.clinic.selectedClinic)
  const response = yield call(assignVideosToClinic, payload)
  if (response && response.assignVideosToClinic) {
    if (response.assignVideosToClinic.status) {
      notification.success({
        message: response.assignVideosToClinic.message,
      })
      yield put({
        type: Tutorial.SET_ASSIGN_VIDEO,
        payload: {
          selectedClinic,
          data: response.assignVideosToClinic.clinicTutorialVideos.map(item => ({
            node: item,
          })),
        },
      })
      yield put({
        type: SuperUser.SET_STATE,
        payload: {
          clinicTutorialVideos: response.assignVideosToClinic.clinicTutorialVideos.map(item => ({
            node: item,
          })),
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isAssigning: false,
    },
  })
}

export function* GET_CURRICULUM_VIDEOS() {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isCurriculumVideosLoading: true,
    },
  })
  const response = yield call(getCurriculumVideos)
  if (response) {
    yield put({
      type: Tutorial.SET_STATE,
      payload: {
        curriculumVideos: response.getVideos.edges,
      },
    })
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isCurriculumVideosLoading: false,
    },
  })
}

export function* CREATE_CURRICULUM_VIDEO({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isCurriculumVideoSaving: true,
    },
  })
  const response = yield call(createCurriculumVideo, payload)
  if (response && response.createVideo) {
    if (response.createVideo.status) {
      notification.success({
        message: response.createVideo.message,
      })
      yield put({
        type: Tutorial.APPEND_CURRICULUM_VIDEO,
        payload: {
          data: response.createVideo.video,
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isCurriculumVideoSaving: false,
    },
  })
}

export function* UPDATE_CURRICULUM_VIDEO({ payload }) {
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isCurriculumVideoSaving: true,
    },
  })
  const response = yield call(updateCurriculumVideo, payload)
  if (response && response.updateVideo) {
    if (response.updateVideo.status) {
      notification.success({
        message: response.updateVideo.message,
      })
      yield put({
        type: Tutorial.REPLACE_CURRICULUM_VIDEO,
        payload: {
          data: response.updateVideo.video,
        },
      })
    }
  }
  yield put({
    type: Tutorial.SET_STATE,
    payload: {
      isCurriculumVideoSaving: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(Tutorial.GET_CLINICS, GET_CLINICS),

    takeLatest(Tutorial.GET_MODULES, GET_MODULES),
    takeLatest(Tutorial.CREATE_MODULE, CREATE_MODULE),
    takeLatest(Tutorial.EDIT_MODULE, EDIT_MODULE),

    takeLatest(Tutorial.GET_SUB_MODULES, GET_SUB_MODULES),
    takeLatest(Tutorial.CREATE_SUB_MODULE, CREATE_SUB_MODULE),
    takeLatest(Tutorial.EDIT_SUB_MODULE, EDIT_SUB_MODULE),

    takeLatest(Tutorial.GET_VIDEOS, GET_VIDEOS),
    takeLatest(Tutorial.CREATE_VIDEO, CREATE_VIDEO),
    takeLatest(Tutorial.EDIT_VIDEO, EDIT_VIDEO),

    takeLatest(Tutorial.GET_ALL_VIDEOS, GET_ALL_VIDEOS),
    takeLatest(Tutorial.ASSIGN_VIDEOS_TO_CLINIC, ASSIGN_VIDEOS_TO_CLINIC),

    takeLatest(Tutorial.GET_CURRICULUM_VIDEOS, GET_CURRICULUM_VIDEOS),
    takeLatest(Tutorial.CREATE_CURRICULUM_VIDEO, CREATE_CURRICULUM_VIDEO),
    takeLatest(Tutorial.UPDATE_CURRICULUM_VIDEO, UPDATE_CURRICULUM_VIDEO),
  ])
}
