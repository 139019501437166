/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Layout, Button } from 'antd'
import { withRouter } from '../withRouter'
import styles from './style.module.scss'

const LoginLayout = ({ children }) => {
  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <Layout.Content>
        <div className={styles.content}>{children}</div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(LoginLayout)
