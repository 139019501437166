import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import Clinic from '../Clinic/action'
import Learner from '../Learner/action'
import Video from './action'
import {
  addCommentOnVideo,
  addCommentReply,
  addVideoRating,
  addVideoView,
  fetchVideoComments,
  getGeoGraphData,
  likeDislikeVideo,
  onPauseVideo,
  removeComment,
  updateCommentOnVideo,
  updateCommentReply,
} from './services'

export function* LIKE_DISLIKE_VIDEO({ payload }) {
  const { role, students } = yield select(state => state.user)

  const { selectedVideo, convertedVideosHierarchy } = yield select(state => state.video)
  const response = yield call(likeDislikeVideo, payload)

  if (response && response.likeDislikeVideo) {
    if (response.likeDislikeVideo.status) {
      const { totalLikesInClinic, totalDislikesInClinic } = response.likeDislikeVideo
      const { id, like, dislike } = response.likeDislikeVideo.videoLikeDislike
      if (role === 'parents') {
        const { learnerVideosHierarchy } = yield select(state => state.learner)

        // select module data
        const {
          tutorialVideos: { module, subModule },
        } = selectedVideo
        const modIdx = learnerVideosHierarchy.findIndex(item => item.moduleId === module.id)

        if (modIdx !== -1) {
          const subIdx = learnerVideosHierarchy[modIdx].subModules.findIndex(
            item => item.subModuleId === subModule.id,
          )

          if (subIdx !== -1) {
            const vidIdx = learnerVideosHierarchy[modIdx].subModules[
              subIdx
            ].learnerTutorialVideos.findIndex(item => item.id === selectedVideo.id)
            if (vidIdx !== -1) {
              const old =
                learnerVideosHierarchy[modIdx].subModules[subIdx].learnerTutorialVideos[vidIdx]
              const newData = {
                ...old,
                totalLikesInClinic,
                totalDislikesInClinic,
                learnerLikeDislike: {
                  id,
                  like,
                  dislike,
                },
              }

              //replace tutorial video with new changes
              learnerVideosHierarchy[modIdx].subModules[subIdx].learnerTutorialVideos[vidIdx] =
                newData
              if (selectedVideo) {
                yield put({
                  type: Video.SET_STATE,
                  payload: {
                    selectedVideo: { ...selectedVideo, ...newData },
                    convertedVideosHierarchy: convertedVideosHierarchy.map(item => {
                      if (item.moduleId === module.id) {
                        return {
                          ...item,
                          videos: item.videos.map(video => {
                            if (video.id === selectedVideo.id) {
                              return {
                                ...newData,
                              }
                            }
                            return video
                          }),
                        }
                      }
                      return item
                    }),
                  },
                })
              }
            }
          }
        }
        yield put({
          type: Learner.SET_STATE,
          payload: {
            learnerVideosHierarchy,
          },
        })
      }
    }
  }
}

export function* ADD_VIDEO_RATING({ payload }) {
  const { role, students } = yield select(state => state.user)
  const { selectedVideo, convertedVideosHierarchy } = yield select(state => state.video)
  const response = yield call(addVideoRating, payload)

  if (response && response.addUpdateVideoRating) {
    if (response.addUpdateVideoRating.status) {
      notification.success({
        message: response.addUpdateVideoRating.message,
      })
      const { averageRatingInClinic, videoRating } = response.addUpdateVideoRating
      const { id, rating } = videoRating

      if (role === 'parents') {
        const { learnerVideosHierarchy } = yield select(state => state.learner)

        const {
          tutorialVideos: { module, subModule },
        } = selectedVideo
        const modIdx = learnerVideosHierarchy.findIndex(item => item.moduleId === module.id)

        if (modIdx !== -1) {
          const subIdx = learnerVideosHierarchy[modIdx].subModules.findIndex(
            item => item.subModuleId === subModule.id,
          )

          if (subIdx !== -1) {
            const vidIdx = learnerVideosHierarchy[modIdx].subModules[
              subIdx
            ].learnerTutorialVideos.findIndex(item => item.id === selectedVideo.id)
            if (vidIdx !== -1) {
              const old =
                learnerVideosHierarchy[modIdx].subModules[subIdx].learnerTutorialVideos[vidIdx]
              const newData = {
                ...old,
                averageRatingInClinic,
                learnerRating: {
                  id,
                  rating,
                },
              }
              //replace tutorial video with new changes
              learnerVideosHierarchy[modIdx].subModules[subIdx].learnerTutorialVideos[vidIdx] =
                newData
              if (selectedVideo) {
                yield put({
                  type: Video.SET_STATE,
                  payload: {
                    selectedVideo: { ...selectedVideo, ...newData },
                    convertedVideosHierarchy: convertedVideosHierarchy.map(item => {
                      if (item.moduleId === module.id) {
                        return {
                          ...item,
                          videos: item.videos.map(video => {
                            if (video.id === selectedVideo.id) {
                              return {
                                ...newData,
                              }
                            }
                            return video
                          }),
                        }
                      }
                      return item
                    }),
                  },
                })
              }
            }
          }
        }

        yield put({
          type: Learner.SET_STATE,
          payload: {
            learnerVideosHierarchy,
          },
        })
      }
    }
  }
}

export function* ADD_COMMENT_ON_VIDEO({ payload }) {
  const res = yield call(addCommentOnVideo, payload)
  if (res) {
    yield put({
      type: Video.APPEND_VIDEO_COMMENT,
      payload: { data: res.addCommentOnVideo.videoComment },
    })
  }
}

export function* UPDATE_COMMENT_ON_VIDEO({ payload }) {
  const res = yield call(updateCommentOnVideo, payload)
  if (res) {
    yield put({
      type: Video.REPLACE_VIDEO_COMMENT,
      payload: { data: res.updateCommentOnVideo.videoComment },
    })
  }
}
export function* ADD_COMMENT_REPLY({ payload }) {
  const res = yield call(addCommentReply, payload)
  if (res) {
    yield put({
      type: Video.APPEND_VIDEO_COMMENT_REPLY,
      payload: { data: res.addReplyOnComment.commentReply, commentId: payload.commentId },
    })
  }
}
export function* UPDATE_COMMENT_REPLY({ payload }) {
  const res = yield call(updateCommentReply, payload)
  if (res) {
    yield put({
      type: Video.REPLACE_VIDEO_COMMENT_REPLY,
      payload: {
        replyCommentId: payload.replyCommentId,
        data: res.updateReplyOnComment.commentReply,
      },
    })
  }
}
export function* REMOVE_COMMENT({ payload }) {
  const res = yield call(removeComment, payload)
  if (res) {
    if (payload.commentId) {
      yield put({
        type: Video.POP_COMMENT,
        payload: {
          commentId: payload.commentId,
        },
      })
    }
    if (payload.replyCommentId) {
      yield put({
        type: Video.POP_REPLY_COMMENT,
        payload: {
          replyCommentId: payload.replyCommentId,
        },
      })
    }
  }
}
export function* FETCH_VIDEO_COMMENTS({ payload }) {
  yield put({
    type: Video.SET_STATE,
    payload: {
      isCommentsLoading: true,
    },
  })
  const response = yield call(fetchVideoComments, payload)
  if (response) {
    yield put({
      type: Video.SET_STATE,
      payload: {
        comments: response.getVideoComments.edges,
      },
    })
  }
  yield put({
    type: Video.SET_STATE,
    payload: {
      isCommentsLoading: false,
    },
  })
}
export function* ON_PAUSE_VIDEO({ payload, setSearchParams }) {
  const response = yield call(onPauseVideo, payload)
  const {
    selectedVideo,
    selectedModuleIndex,
    selectedSubModuleIndex,
    selectedVideoIndex,
    convertedVideosHierarchy: convertedVideos,
  } = yield select(state => state.video)
  const { role } = yield select(state => state.user)

  if (response && response.trackLearnerVideo) {
    if (response.trackLearnerVideo.status) {
      const {
        studentvideotracking,
        status,
        videoPlayedAt,
        videoCompletedAt,
        videoPlayCount,
        userWatchTime,
        videoWatchedTime,
      } = response.trackLearnerVideo.learnerVideo
      if (role === 'parents') {
        const { learnerVideosHierarchy } = yield select(state => state.learner)

        const old =
          learnerVideosHierarchy[selectedModuleIndex].subModules[selectedSubModuleIndex]
            .learnerTutorialVideos[selectedVideoIndex]
        const newData = {
          ...old,
          status,
          videoPlayedAt,
          videoCompletedAt,
          videoPlayCount,
          userWatchTime,
          videoWatchedTime,
          studentvideotracking,
        }

        //replace tutorial video with new changes
        learnerVideosHierarchy[selectedModuleIndex].subModules[
          selectedSubModuleIndex
        ].learnerTutorialVideos[selectedVideoIndex] = newData

        yield put({
          type: Learner.SET_STATE,
          payload: {
            learnerVideosHierarchy,
          },
        })
        if (selectedVideo && status === 'COMPLETED') {
          const findModuleIdx = convertedVideos.findIndex(
            item => item.moduleId === selectedVideo.tutorialVideos.module.id,
          )
          console.log('findModuleIdx: ' + findModuleIdx)
          const availableModuleLength = convertedVideos.length
          if (findModuleIdx !== -1) {
            const availableVideoLength = convertedVideos[findModuleIdx].videos.length
            const findVideoIdx = convertedVideos[findModuleIdx].videos.findIndex(
              item => item.id === selectedVideo.id,
            )
            console.log('findVideoIdx: ' + findVideoIdx)

            if (findVideoIdx !== -1) {
              let temp = convertedVideos
              temp[findModuleIdx].videos[findVideoIdx].status = 'COMPLETED'
              yield put({
                type: Video.SET_STATE,
                payload: {
                  convertedVideosHierarchy: temp,
                },
              })
              // if (findVideoIdx === availableVideoLength - 1) {
              //   setSearchParams({
              //     v:
              //       findModuleIdx !== availableModuleLength - 1
              //         ? convertedVideos[findModuleIdx + 1].videos[0].tutorialVideos.id
              //         : selectedVideo.tutorialVideos.id,
              //   })
              // } else {
              //   setSearchParams({
              //     v: convertedVideos[findModuleIdx].videos[findVideoIdx + 1].tutorialVideos.id,
              //   })
              // }
            }
          }
        }
        // if (selectedVideo) {
        //   yield put({
        //     type: Video.SET_STATE,
        //     payload: {
        //       selectedVideo: {
        //         ...selectedVideo,
        //         status,
        //         studentvideotracking,
        //         videoPlayedAt,
        //         videoCompletedAt,
        //         videoPlayCount,
        //         userWatchTime,
        //         videoWatchedTime,
        //       },
        //     },
        //   })
        // }
      }
    }
  }
}

export function* ADD_VIDEO_VIEW({ payload }) {
  const response = yield call(addVideoView, payload)
  const { selectedVideo, selectedModuleIndex, selectedSubModuleIndex, selectedVideoIndex } =
    yield select(state => state.video)
  const { role } = yield select(state => state.user)

  if (response && response.addVideoView) {
    if (response.addVideoView) {
      const { tutorialvideosSet } = response.addVideoView.videoView.video
      if (tutorialvideosSet.edges.length > 0) {
        const { studentstutorialvideosSet, videoViews } = tutorialvideosSet.edges[0].node
        if (role === 'school_admin') {
          const { clinicVideosHierarchy } = yield select(state => state.clinic)

          const old =
            clinicVideosHierarchy[selectedModuleIndex].subModules[selectedSubModuleIndex]
              .clinicTutorialVideos[selectedVideoIndex]

          // replace videodata at found submodule

          const newData = {
            ...old,
            videoViews,
          }
          //replace tutorial video with new changes
          clinicVideosHierarchy[selectedModuleIndex].subModules[
            selectedSubModuleIndex
          ].clinicTutorialVideos[selectedVideoIndex] = newData
          if (selectedVideo) {
            yield put({
              type: Video.SET_STATE,
              payload: {
                selectedVideo: { ...selectedVideo, ...newData },
              },
            })
          }

          yield put({
            type: Clinic.SET_STATE,
            payload: {
              clinicVideosHierarchy,
            },
          })
        }
        if (role === 'parents') {
          const { learnerVideosHierarchy } = yield select(state => state.learner)

          if (studentstutorialvideosSet.edges.length) {
            const { videoViewsInClinic } =
              studentstutorialvideosSet.edges[studentstutorialvideosSet.edges.length - 1].node
            // select module data

            const {
              tutorialVideos: { module, subModule },
            } = selectedVideo
            const modIdx = learnerVideosHierarchy.findIndex(item => item.moduleId === module.id)
            if (modIdx !== -1) {
              const subIdx = learnerVideosHierarchy[modIdx].subModules.findIndex(
                item => item.subModuleId === subModule.id,
              )

              if (subIdx !== -1) {
                const vidIdx = learnerVideosHierarchy[modIdx].subModules[
                  subIdx
                ].learnerTutorialVideos.findIndex(item => item.id === selectedVideo.id)
                if (vidIdx !== -1) {
                  const old =
                    learnerVideosHierarchy[modIdx].subModules[subIdx].learnerTutorialVideos[vidIdx]
                  const newData = {
                    ...old,
                    videoViewsInClinic,
                  }
                  //replace tutorial video with new changes
                  learnerVideosHierarchy[modIdx].subModules[subIdx].learnerTutorialVideos[vidIdx] =
                    newData
                  if (selectedVideo) {
                    yield put({
                      type: Video.SET_STATE,
                      payload: {
                        selectedVideo: { ...selectedVideo, ...newData },
                      },
                    })
                  }
                }
              }
            }

            // replace videodata at found submodule

            yield put({
              type: Learner.SET_STATE,
              payload: {
                learnerVideosHierarchy,
              },
            })
          }
        }
      }
    }
  }
}
export function* GET_GEO_GRAPH_DATA({ payload }) {
  yield put({
    type: Video.SET_STATE,
    payload: {
      isGraphLoading: true,
    },
  })
  const response = yield call(getGeoGraphData, payload)
  if (response) {
    yield put({
      type: Video.SET_STATE,
      payload: {
        graphData: response.getVideoDataForGeoGraph,
      },
    })
  }
  yield put({
    type: Video.SET_STATE,
    payload: {
      isGraphLoading: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(Video.LIKE_DISLIKE_VIDEO, LIKE_DISLIKE_VIDEO),
    takeLatest(Video.ADD_VIDEO_RATING, ADD_VIDEO_RATING),
    takeLatest(Video.ADD_COMMENT_ON_VIDEO, ADD_COMMENT_ON_VIDEO),
    takeLatest(Video.UPDATE_COMMENT_ON_VIDEO, UPDATE_COMMENT_ON_VIDEO),

    takeLatest(Video.ADD_COMMENT_REPLY, ADD_COMMENT_REPLY),
    takeLatest(Video.UPDATE_COMMENT_REPLY, UPDATE_COMMENT_REPLY),
    takeLatest(Video.REMOVE_COMMENT, REMOVE_COMMENT),
    takeLatest(Video.FETCH_VIDEO_COMMENTS, FETCH_VIDEO_COMMENTS),
    takeLatest(Video.ON_PAUSE_VIDEO, ON_PAUSE_VIDEO),
    takeLatest(Video.ADD_VIDEO_VIEW, ADD_VIDEO_VIEW),
    takeLatest(Video.GET_GEO_GRAPH_DATA, GET_GEO_GRAPH_DATA),
  ])
}
