import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import _ from 'lodash'

dayjs.extend(utc)

export const dateTimeToUtc = dateTime => {
  return dayjs(dateTime).local().utc().format('YYYY-MM-DDTHH:mm:ssZ')
}

export const MinuteToHourMinute = minutes => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(remainingMinutes).padStart(2, '0')
  const str = `${formattedHours} : ${formattedMinutes}`
  return str
}

export const utcTimeStringToLocalTimeString = utcTimeString => {
  /// Create a Day.js object for the current time in UTC
  const utcTime = dayjs.utc(utcTimeString, 'hh:mm')

  // Convert UTC time to local time
  const localTime = utcTime.local()

  // Format the local time as desired
  const formattedLocalTime = dayjs(localTime)
  return formattedLocalTime
}

export const disabledPreviousDate = current => {
  // Disable dates before today (including today)
  return current && current < dayjs().startOf('day')
}

export const disabledFutureDate = current => {
  return current && current > dayjs().endOf('day')
}

export const reverseArray = arr => {
  const reversedArray = []

  for (let i = arr.length - 1; i >= 0; i--) {
    reversedArray.push(arr[i])
  }

  return reversedArray
}

export const getLearnerName = learner => {
  let name = ''
  if (learner.parent) {
    name = `${learner.parent.firstName} ${learner.parent.lastName}`
  } else {
    name = `${learner.firstName} ${learner.lastName}`
  }
  return name
}

export const generateUniqueId = () => {
  const timestamp = Date.now().toString()
  const random = Math.random().toString().substring(2)
  return `${timestamp}_${random}`
}

export const messageDateFormat = timestamp => {
  const sentTime = dayjs(timestamp)
  const now = dayjs()

  if (sentTime.isSame(now, 'day')) {
    return 'Today'
  } else if (sentTime.isSame(now.subtract(1, 'day'), 'day')) {
    return 'Yesterday'
  } else {
    return sentTime.format('dddd, DD MMMM YYYY')
  }
}

export const getYouTubeThumbnail = (url, size = 'default') => {
  const urlParts = url.split('/')
  let videoID = ''

  // Check if the URL is in the short format: https://youtu.be/kswsBwqRSFI
  if (urlParts.includes('youtu.be')) {
    videoID = urlParts.pop()
  } else {
    const results = url.match('[\\?&]v=([^&#]*)')
    videoID = results === null ? url : results[1]
  }

  return `https://img.youtube.com/vi/${videoID}/0.jpg`
}

export const moveElementToNewIndex = (array, oldIndex, newIndex) => {
  // console.log('oldIndex, newIndex', oldIndex, newIndex)
  const newArray = [...array]
  const element = newArray.splice(oldIndex, 1)[0]
  newArray.splice(newIndex, 0, element)
  return newArray
}

export const getDuration = (startTime, endTime) => {
  const startDateTime = dayjs(startTime)
  const endDateTime = dayjs(endTime)

  const durationInMilliseconds = endDateTime.diff(startDateTime)
  const duration = dayjs.duration(durationInMilliseconds)

  const formattedDuration = `${duration.hours().toString().padStart(2, '0')}:${duration
    .minutes()
    .toString()
    .padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`
  return formattedDuration
}

export const formatMiliseconds = milliseconds => {
  const hours = Math.floor(milliseconds / 3600000) // 1 hour = 3600000 ms
  milliseconds %= 3600000

  const minutes = Math.floor(milliseconds / 60000) // 1 minute = 60000 ms
  milliseconds %= 60000

  const seconds = Math.floor(milliseconds / 1000)

  return hours >= 0 && minutes >= 0 && seconds >= 0
    ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    : '00:00:00'
}

export const isValidZoomLink = link => {
  // Regular expression for matching Zoom meeting links
  const zoomLinkRegex = /^(https?:\/\/)?zoom\.us\/(j\/)?\d+(\?pwd=.+)?$/
  return zoomLinkRegex.test(link)
}

export const convertString = str => {
  const temp = _.split(str, '_')
  const join = _.join(
    temp.map(item => _.capitalize(item)),
    ' ',
  )
  return join
}

export const convertDuration = duration => {
  // Split the input duration into its components
  const [hours, minutes, secondsWithMillis] = duration.split(':')
  const [seconds, milliseconds] = secondsWithMillis.split('.')

  // Round the values to the nearest whole number
  const roundedHours = Math.round(parseFloat(hours))
  const roundedMinutes = Math.round(parseFloat(minutes))
  const roundedSeconds = Math.round(parseFloat(seconds))

  // Construct the rounded duration string
  const roundedDuration = `${String(roundedHours).padStart(2, '0')}:${String(
    roundedMinutes,
  ).padStart(2, '0')}:${String(roundedSeconds).padStart(2, '0')}`

  return roundedDuration
}
