/* eslint-disable no-unused-vars */

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

// export async function createInstantZoomMeeting(payload) {
//   return client
//     .mutate({
//       mutation: gql`
//         mutation createInstantMeet($userId: ID!) {
//           createInstantMeet(input: { attendeUser: $userId }) {
//             message
//             status
//             data {
//               id
//               meetingId
//               topic
//               password
//             }
//             response
//           }
//         }
//       `,
//       variables: {
//         ...payload,
//       },
//     })
//     .then(data => {
//       return data?.data
//     })
//     .catch(err => console.error(err, 'error'))
// }

export async function createInstantZoomMeeting(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createInstantMeetManually($userId: ID!) {
          createInstantMeetManually(input: { attendeUser: $userId }) {
            message
            status
            data {
              id
              zoomLink
              placeOfService {
                id
                name
              }
              cptCode {
                id
                code
              }
              status
              host {
                id
                firstName
              }
              attende {
                id
                firstName
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getInstantZoomMeetingSignature(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation generateSignatureForMeet($zoomId: ID!, $role: Int!) {
          generateSignatureForMeet(input: { instantZoomId: $zoomId, role: $role }) {
            status
            message
            signature
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getMeetingDetailsByMeetId(payload) {
  return client
    .query({
      query: gql`
        query getInstantZoomCallById($zoomId: ID!) {
          getInstantZoomCallById(id: $zoomId) {
            id
            meetingId
            topic
            password
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateMeetingStatus(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateInstantZoomMeet($zoomId: ID!, $status: String!) {
          updateInstantZoomMeet(input: { instantZoomId: $zoomId, status: $status }) {
            status
            message
            data {
              id
              meetingId
              password
              host {
                id
                firstName
                lastName
              }
              hostStatus
              attende {
                id
                firstName
                lastName
              }
              attendeStatus
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getInstantMeetCalls() {
  return client
    .query({
      query: gql`
        query {
          getInstantZoomCalls {
            edges {
              node {
                id
                topic
                meetingId
                # meetingDetails
                host {
                  id
                  firstName
                  lastName
                  email
                  students {
                    id
                    image
                    parent {
                      id
                      firstName
                      lastName
                    }
                  }
                }
                hostStatus
                hostLeave
                hostJoined
                attende {
                  id
                  firstName
                  lastName
                  email
                  staff {
                    id
                    image
                  }
                }
                attendeStatus
                attendeLeave
                attendeJoined
                createdAt
                updatedAt
              }
            }
          }
        }
      `,
      // variables: {
      //   ...payload,
      // },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getManualInstantMeetCalls(payload) {
  return client
    .query({
      query: gql`
        query getManualInstantMeet(
          $first: Int
          $offset: Int
          $hostEmail: String
          $startDate: Date
          $endDate: Date
        ) {
          getManualInstantMeet(
            first: $first
            offset: $offset
            host_Email_Icontains: $hostEmail
            startDate: $startDate
            endDate: $endDate
          ) {
            totalCount
            edges {
              node {
                id
                zoomLink
                status
                client {
                  id
                  firstName
                  lastName
                  lastLogin
                  email
                  students {
                    id
                    image
                    parent {
                      id
                      firstName
                      lastName
                    }
                  }
                  staff {
                    id
                    image
                  }
                }
                provider {
                  id
                  firstName
                  lastName
                  lastLogin
                  email
                  students {
                    id
                    image
                    parent {
                      id
                      firstName
                      lastName
                    }
                  }
                  staff {
                    id
                    image
                  }
                }
                meetTranscript
                meetAudioRecording
                meetVideoRecording
                duration
                units
                callAcceptAt
                callEndAt
                placeOfService {
                  id
                  name
                }
                cptCode {
                  id
                  code
                }
                createdAt
                updatedAt
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateZoomMeetByBCBA(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateInstantMeetManually(
          $zoomId: ID!
          $status: String!
          $videoRecordingUrl: String
          $audioRecordingUrl: String
        ) {
          updateInstantMeetManually(
            input: {
              instantZoomId: $zoomId
              status: $status
              videoRecordingUrl: $videoRecordingUrl
              audioRecordingUrl: $audioRecordingUrl
            }
          ) {
            message
            status
            data {
              id
              zoomLink
              status
              host {
                id
                firstName
                lastName
                email
                students {
                  id
                  image
                  parent {
                    id
                    firstName
                    lastName
                  }
                }
              }
              attende {
                id
                firstName
                lastName
                email
                staff {
                  id
                  image
                }
              }
              meetTranscript
              meetAudioRecording
              meetVideoRecording
              duration
              units
              placeOfService {
                id
                name
              }
              cptCode {
                id
                code
              }
              createdAt
              updatedAt
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
