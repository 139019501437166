import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import {
  addMessageViewedBy,
  createChatRoom,
  getAllChatRooms,
  getChatRoomById,
  getChatRoomMessages,
  getRoomWithUnreadCount,
  sendChatMessage,
} from './services'
import Chat from './action'
import Staff from '../staff/action'
import Community from '../Community/action'
import User from '../user/action'
import Learner from '../Learner/action'

const debug = true

export function* GET_ALL_CHAT_ROOMS() {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      loadingChatRooms: true,
      chatRoomResponse: {},
    },
  })

  let chatRoomResponse = {}

  const response = yield call(getAllChatRooms)
  const { userId } = yield select(state => state.user)

  if (response && response.getRoomWithUnreadMsgCount) {
    if (debug) console.log('chatRooms response : ', response)

    const allEdges = response.getRoomWithUnreadMsgCount
    for (var i = 0; i < allEdges.length; i += 1) {
      const user = allEdges[i].chatRoom.chatroomusersSet.edges.find(
        ({ node: item }) => item.chatUser.user.id !== userId,
      )
      const loggedInUser = allEdges[i].chatRoom.chatroomusersSet.edges.find(
        ({ node: item }) => item.chatUser.user.id === userId,
      )

      chatRoomResponse[allEdges[i].chatRoom.id] = {
        chatRoom: allEdges[i].chatRoom,
        loggedInChatUser: loggedInUser?.node.chatUser,
        withChatUser: user?.node.chatUser,
        unReadCount: allEdges[i].unreadMessageCount,
      }
    }

    yield put({
      type: Chat.SET_STATE,
      payload: {
        chatRooms: response.getRoomWithUnreadMsgCount,
        chatRoomResponse,
        chatRoomsLoaded: true,
      },
    })
  }

  yield put({
    type: Chat.SET_STATE,
    payload: {
      loadingChatRooms: false,
    },
  })
}
export function* CREATE_CHAT_ROOM({ payload }) {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isChatRoomCreating: true,
    },
  })
  const response = yield call(createChatRoom, payload)
  if (response && response.createChatRoom && response.createChatRoom.status) {
    const { role, id, chatUser } = yield select(state => state.user)
    const { selectedBCBA } = yield select(state => state.community)
    const { learnerAllBCBA } = yield select(state => state.learner)

    yield put({
      type: Chat.APPEND_CHAT_ROOM,
      payload: {
        data: response.createChatRoom.chatRoom,
      },
    })
    if (role === 'parents') {
      const findChatUser = response.createChatRoom.chatRoom.chatroomusersSet.edges.find(
        ({ node }) => node.chatUser.user.id === id,
      )
      yield put({
        type: Community.SET_STATE,
        payload: {
          selectedBCBA: {
            ...response.createChatRoom.chatRoom,
            unreadMessageCount: 0,
            ...selectedBCBA,
            roomId: response.createChatRoom.chatRoom.id,
          },
        },
      })

      yield put({
        type: Learner.SET_STATE,
        payload: {
          learnerAllBCBA: [
            ...learnerAllBCBA.map(item => {
              if (item.id === selectedBCBA.id) {
                return { ...item, roomId: response.createChatRoom.chatRoom.id }
              }
              return {
                ...item,
              }
            }),
          ],
        },
      })
      yield put({
        type: Chat.GET_ALL_CHAT_ROOMS,
      })
      yield put({
        type: User.SET_STATE,
        payload: {
          chatUser:
            findChatUser && findChatUser !== undefined
              ? {
                  ...findChatUser.node.chatUser,
                }
              : null,
        },
      })
    }
  }
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isChatRoomCreating: false,
    },
  })
}

export function* GET_CHAT_ROOM_MESSAGES({ payload }) {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isMessagesLoading: true,
    },
  })
  const response = yield call(getChatRoomMessages, payload)
  if (response && response.getChatRoomMessages) {
    yield put({
      type: Chat.SET_STATE,
      payload: {
        isAllMessagesLoaded: true,
        allMessages: response.getChatRoomMessages.edges,
      },
    })
  }
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isMessagesLoading: false,
    },
  })
}

export function* LOAD_MORE_MESSAGES({ payload }) {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      loadingMoreMessages: true,
    },
  })
  const response = yield call(getChatRoomMessages, payload)
  if (response && response.getChatRoomMessages) {
    const { allMessages } = yield select(state => state.chat)
    yield put({
      type: Chat.SET_STATE,
      payload: {
        loadedMessages: response.getChatRoomMessages.edges,
      },
    })
  }
  yield put({
    type: Chat.SET_STATE,
    payload: {
      loadingMoreMessages: false,
    },
  })
}
export function* SEND_CHAT_MESSAGE({ payload }) {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isMessageSending: true,
    },
  })
  const response = yield call(sendChatMessage, payload)
  //   if (response && response.sendChatMessage) {
  //     yield put({
  //       type: Chat.SET_STATE,
  //       payload: {
  //         allMessages: response.getChatRoomMessages.edges,
  //       },
  //     })
  //   }
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isMessageSending: false,
    },
  })
}

export function* GET_ROOM_WITH_UNREAD_COUNT() {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isUnreadCountLoading: true,
    },
  })
  const response = yield call(getRoomWithUnreadCount)
  if (response && response.getRoomWithUnreadMsgCount) {
    yield put({
      type: Chat.SET_STATE,
      payload: {
        unreadCount: response.getRoomWithUnreadMsgCount,
      },
    })
  }
  yield put({
    type: Chat.SET_STATE,
    payload: {
      isUnreadCountLoading: false,
    },
  })
}

export function* ADD_MESSAGE_VIEWED_BY({ payload }) {
  const response = yield call(addMessageViewedBy, payload)
  if (response) {
    const { allMessages } = yield select(state => state.chat)
    const temp = allMessages
    const foundIdx = allMessages.findIndex(({ node }) => node.id === payload.messageId)
    if (foundIdx !== -1) {
      temp[foundIdx] = {
        node: {
          ...temp[foundIdx].node,
          isAllViewed: true,
        },
      }
    }
    yield put({
      type: Chat.SET_STATE,
      payload: {
        allMessages: temp,
      },
    })
  }
}

export function* GET_CHAT_ROOM_BY_ID({ payload }) {
  yield put({
    type: Chat.SET_STATE,
    payload: {
      roomDetailsLoading: true,
    },
  })

  const response = yield call(getChatRoomById, payload)
  const { userId } = yield select(state => state.user)
  const { chatRoomResponse, chatRooms } = yield select(state => state.chat)

  if (response && response.getRoomById) {
    const temp = response.getRoomById

    const user = temp.chatroomusersSet.edges.find(
      ({ node: item }) => item.chatUser.user.id !== userId,
    )
    const loggedInUser = temp.chatroomusersSet.edges.find(
      ({ node: item }) => item.chatUser.user.id === userId,
    )
    const unreadCount = temp.messageSet.edges.filter(
      ({ node }) => !node.isAllViewed && node.sender.user.id !== userId,
    ).length
    console.log('unreadCount==>', unreadCount)
    chatRoomResponse[temp.id] = {
      chatRoom: temp,
      loggedInChatUser: loggedInUser?.node.chatUser,
      withChatUser: user?.node.chatUser,
      unReadCount: unreadCount,
    }

    yield put({
      type: Chat.SET_STATE,
      payload: {
        chatRooms: [
          ...chatRooms,
          {
            unreadMessageCount: unreadCount,
            chatRoom: temp,
          },
        ],
        chatRoomResponse,
      },
    })
  }

  yield put({
    type: Chat.SET_STATE,
    payload: {
      roomDetailsLoading: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(Chat.GET_ALL_CHAT_ROOMS, GET_ALL_CHAT_ROOMS),
    takeLatest(Chat.CREATE_CHAT_ROOM, CREATE_CHAT_ROOM),
    takeLatest(Chat.GET_CHAT_ROOM_MESSAGES, GET_CHAT_ROOM_MESSAGES),
    takeLatest(Chat.LOAD_MORE_MESSAGES, LOAD_MORE_MESSAGES),

    takeLatest(Chat.SEND_CHAT_MESSAGE, SEND_CHAT_MESSAGE),
    takeLatest(Chat.ADD_MESSAGE_VIEWED_BY, ADD_MESSAGE_VIEWED_BY),
    takeLatest(Chat.GET_ROOM_WITH_UNREAD_COUNT, GET_ROOM_WITH_UNREAD_COUNT),
    takeLatest(Chat.GET_CHAT_ROOM_BY_ID, GET_CHAT_ROOM_BY_ID),
  ])
}
