const Assessment = {
  SET_STATE: 'assessment/SET_STATE',
  START_LEARNER_ASSESSMENT: 'assessment/START_LEARNER_ASSESSMENT',
  APPEND_LEARNER_ASSESSMENT: 'assessment/APPEND_LEARNER_ASSESSMENT',
  UPDATE_LEARNER_ASSESSMENT: 'assessment/UPDATE_LEARNER_ASSESSMENT',
  GET_LEARNER_ASSESSMENT_DETAILS: 'assessment/GET_LEARNER_ASSESSMENT_DETAILS',
  GET_STRUGGLING_ISSUE_QUESTIONS: 'assessment/GET_STRUGGLING_ISSUE_QUESTIONS',
  GET_PERSONALIZED_QUESTIONS: 'assessment/GET_PERSONALIZED_QUESTIONS',

  RECORD_QUESTION: 'assessment/RECORD_QUESTION',
  UPDATE_QUESTION: 'assessment/UPDATE_QUESTION',
  APPEND_RESPONSE: 'assessment/APPEND_RESPONSE',
  REPLACE_RESPONSE: 'assessment/REPLACE_RESPONSE',
  GET_LEARNER_ASSESSMENTS: 'assessment/GET_LEARNER_ASSESSMENTS',
  GET_LEARNER_ASSESSMENT_RESPONSES: 'assessment/GET_LEARNER_ASSESSMENT_RESPONSES',
  GET_LAST5_ASSESSMENT: 'assessment/GET_LAST5_ASSESSMENT',

}

export default Assessment
