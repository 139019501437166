const ZoomMeetingAction = {
  SET_STATE: 'zoomMeeting/SET_STATE',
  CREATE_INSTANT_ZOOM_MEETING: 'zoomMeeting/CREATE_INSTANT_ZOOM_MEETING',
  GET_INSTANT_ZOOM_MEETING_SIGNATURE: 'zoomMeeting/GET_INSTANT_ZOOM_MEETING_SIGNATURE',
  GET_MEETING_DETAILS_BY_MEETID: 'zoomMeeting/GET_MEETING_DEATILS_BY_MEETID',
  UPDATE_MEETING_STATUS: 'zoomMeeting/UPDATE_MEETING_STATUS',
  GET_INSTANT_MEET_CALLS: 'zoomMeeting/GET_INSTANT_MEET_CALLS',
  UPDATE_ZOOM_MEET_BY_BCBA: 'zoomMeeting/UPDATE_ZOOM_MEET_BY_BCBA',
  REPLACE_ZOOM_MEET: 'zoomMeeting/REPLACE_ZOOM_MEET',
  GET_MANUAL_INSTANT_CALLS: 'zoomMeeting/GET_MANUAL_INSTANT_CALLS',
}

export default ZoomMeetingAction
