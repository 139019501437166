import SuperUser from './action'

const initialState = {
  graphData: [],
  isGraphLoading: false,
  isDashboardLoading: false,
  dashboard: null,
  videosHierarchy: [],
  isVideoLoading: false,
  videos: [],
  clinics: [],
  isClinicsLoading: false,
  isStudentLoading: false,
  students: [],
  selectedClinic: null,
  isClinicSaving: false,
  isCountryLoading: false,
  country: [],
  videosHistory: [],
  isHistoryLoading: false,
  selectedVideo: null,
  selectedModuleIndex: 0,
  selectedSubModuleIndex: 0,
  selectedVideoIndex: 0,
  clinicCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
  isVideoListLoading: false,
  videoList: [],
  selectedUser: null,
  watchDataByUser: {},
  watchDataLoading: false,
  isCreateClinicDrawer: false,
  isLanguageLoading: false,
  language: [],
  clinicTutorialVideos: [],
  isClinicTutorialVideosLoading: false,
}

export default function superUserReducer(state = initialState, action) {
  switch (action.type) {
    case SuperUser.SET_STATE:
      return { ...state, ...action.payload }
    case SuperUser.APPEND_CLINIC: {
      return {
        ...state,
        isCreateClinicDrawer: false,
        clinics: [{ node: action.payload.data }, ...state.clinics],
      }
    }
    case SuperUser.REPLACE_CLINIC: {
      return {
        ...state,
        selectedLearner: null,
        isCreateClinicDrawer: false,
        clinics: [
          ...state.clinics.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return {
              node: {
                ...item,
                isDefault: action.payload.data.isDefault && item.isDefault ? false : item.isDefault,
              },
            }
          }),
        ],
      }
    }

    default:
      return state
  }
}
