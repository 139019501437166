/* eslint-disable no-unused-vars */
import { notification } from 'antd'

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function startLearnerAssessment(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation startLearnerAssessment(
          $name: String!
          $dob: Date!
          $gender: String!
          $other: String
          $email: String!
          $zipCode: String
          $parentName: String
          $parentNickname: String
        ) {
          startLearnerAssessment(
            input: {
              name: $name
              age: $dob
              gender: $gender
              pronounceChild: $other
              email: $email
              zipCode: $zipCode
              parentName: $parentName
              parentNickname: $parentNickname
            }
          ) {
            message
            status
            learnerAssessment {
              id
              assessmentNo
              learner {
                id
                email
              }
              email
              zipCode
              age
              gender
              pronounceChild
              name
              parentName
              status
              createdAt
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })

    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function updateLearnerAssessment(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateLearnerAssessment(
          $status: String!
          $assessmentId: ID!
          $behaviourStatus: String
          $personalizedStatus: String
        ) {
          updateLearnerAssessment(
            input: {
              status: $status
              id: $assessmentId
              behaviourStatus: $behaviourStatus
              personalizedStatus: $personalizedStatus
            }
          ) {
            message
            status
            learnerAssessment {
              id
              status
              behaviourStatus
              personalizedStatus
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })

    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerAssessmentDetails(payload) {
  return client
    .query({
      query: gql`
        query getLearnerAssessments($assessmentId: ID!) {
          getLearnerAssessments(id: $assessmentId) {
            id
            learner {
              id
              email
              clinic {
                id
                schoolName
              }
            }
            email
            zipCode
            age
            gender
            pronounceChild
            name
            status
            behaviourStatus
            personalizedStatus
            learnerstrugglingquestionresponsesSet {
              edges {
                node {
                  id
                  question {
                    id
                  }
                  option {
                    id
                    option
                  }
                  otherIssue
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerAssessmentResponses(payload) {
  return client
    .query({
      query: gql`
        query getLearnerAssessmentResponses($assessmentId: ID!) {
          getLearnerAssessmentResponses(assessmentId: $assessmentId) {
            id
            question {
              id
              question
              questionType
            }
            option {
              id
              option
            }
            multipleOptions {
              edges {
                node {
                  id
                  option
                }
              }
            }
            otherIssue
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerAssessments() {
  return client
    .query({
      query: gql`
        query {
          getLearnerAssessments {
            id
            assessmentNo
            learner {
              id
              email
              clinic {
                id
                schoolName
              }
            }
            behaviourStatus
            personalizedStatus
            email
            zipCode
            age
            gender
            pronounceChild
            name
            status
            createdAt
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getLearnerLast5Assessments() {
  return client
    .query({
      query: gql`
        query {
          getLearnerAssessments(lastAssessments: 5) {
            id
            assessmentNo
            learner {
              id
              email
              clinic {
                id
                schoolName
              }
            }
            email
            zipCode
            age
            gender
            pronounceChild
            name
            status
            createdAt
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getStrugglingIssueQuestions() {
  return client
    .query({
      query: gql`
        query {
          getStrugglingIssueQuestions {
            edges {
              node {
                id
                question
                options {
                  edges {
                    node {
                      id
                      option
                    }
                  }
                }
                multiSelectOptions
                questionType
                allowFreeText
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })

    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getPersonalizedQuestions(payload) {
  return client
    .query({
      query: gql`
        query getPersonalizedQuestions($assessmentId: ID!) {
          getPersonalizedQuestions(assessmentId: $assessmentId) {
            edges {
              node {
                id
                question
                options {
                  edges {
                    node {
                      id
                      option
                    }
                  }
                }
                multiSelectOptions
                questionType
                allowFreeText
                strugglingissuesquestionswithoptionsSet {
                  edges {
                    node {
                      id
                      question
                      questionType
                      options {
                        edges {
                          node {
                            id
                            option
                          }
                        }
                      }
                      parentQuestion {
                        id
                        question
                      }
                      forResponse {
                        id
                        option
                      }
                      allowFreeText
                      multiSelectOptions
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },

      fetchPolicy: 'network-only',
    })

    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function recordQuestion(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation recordQuestionAnswer(
          $assessmentId: ID!
          $questionId: ID!
          $optionId: ID
          $optionText: String
          $selectedOptions: [ID]
        ) {
          recordQuestionAnswer(
            input: {
              learnerAssessment: $assessmentId
              questionId: $questionId
              optionId: $optionId
              otherOptionText: $optionText
              selectedOptions: $selectedOptions
            }
          ) {
            message
            status
            questionResponse {
              id
              question {
                id
                question
                questionType
              }
              multipleOptions {
                edges {
                  node {
                    id
                    option
                  }
                }
              }
              option {
                id
                option
              }
              otherIssue
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })

    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateQuestion(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateQuestionAnswer(
          $responseId: ID!
          $questionId: ID!
          $optionId: ID
          $optionText: String
          $selectedOptions: [ID]
        ) {
          updateQuestionAnswer(
            input: {
              id: $responseId
              questionId: $questionId
              optionId: $optionId
              otherOptionText: $optionText
              selectedOptions: $selectedOptions
            }
          ) {
            message
            status
            questionResponse {
              id
              learnerAssessment {
                id
              }
              question {
                id
                question
              }
              option {
                id
                option
              }
              multipleOptions {
                edges {
                  node {
                    id
                    option
                  }
                }
              }
              otherIssue
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })

    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
