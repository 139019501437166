const Tutorial = {
  SET_STATE: 'tutorial/SET_STATE',
  GET_CLINICS: 'tutorial/GET_CLINICS',
  GET_MODULES: 'tutorial/GET_MODULES',
  CREATE_MODULE: 'tutorial/CREATE_MODULE',
  EDIT_MODULE: 'tutorial/EDIT_MODULE',
  APPEND_MODULE: 'tutorial/APPEND_MODULE',
  REPLACE_MODULE: 'tutorial/REPLACE_MODULE',

  GET_SUB_MODULES: 'tutorial/GET_SUB_MODULES',
  CREATE_SUB_MODULE: 'tutorial/CREATE_SUB_MODULE',
  EDIT_SUB_MODULE: 'tutorial/EDIT_SUB_MODULE',
  APPEND_SUB_MODULE: 'tutorial/APPEND_SUB_MODULE',
  REPLACE_SUB_MODULE: 'tutorial/REPLACE_SUB_MODULE',

  GET_VIDEOS: 'tutorial/GET_VIDEOS',
  CREATE_VIDEO: 'tutorial/CREATE_VIDEO',
  EDIT_VIDEO: 'tutorial/EDIT_VIDEO',
  APPEND_VIDEO: 'tutorial/APPEND_VIDEO',
  REPLACE_VIDEO: 'tutorial/REPLACE_VIDEO',
  GET_ALL_VIDEOS: 'tutorial/GET_ALL_VIDEOS',
  ASSIGN_VIDEOS_TO_CLINIC: 'tutorial/ASSIGN_VIDEOS_TO_CLINIC',
  SET_ASSIGN_VIDEO: 'tutorial/SET_ASSIGN_VIDEO',

  GET_CURRICULUM_VIDEOS: 'tutorial/GET_CURRICULUM_VIDEOS',
  CREATE_CURRICULUM_VIDEO: 'tutorial/CREATE_CURRICULUM_VIDEO',
  APPEND_CURRICULUM_VIDEO: 'tutorial/APPEND_CURRICULUM_VIDEO',
  UPDATE_CURRICULUM_VIDEO: 'tutorial/UPDATE_CURRICULUM_VIDEO',
  REPLACE_CURRICULUM_VIDEO: 'tutorial/REPLACE_CURRICULUM_VIDEO',
}

export default Tutorial
