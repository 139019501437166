/* eslint-disable no-unused-vars */
import { notification } from 'antd'

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function updateStaff(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateStaff(
          $id: ID!
          $email: String!
          $firstname: String!
          $lastname: String
          $mobileNo: String
          $gender: String
          $address: String
          $isActive: Boolean
          $languages: [ID]
          $designation: String
          $education: String
          $experience: String
          $city: String
          $state: String
          $country: ID
          $meetLink: String
          $isDefaultBcba: Boolean
        ) {
          updateStaff(
            input: {
              id: $id
              email: $email
              firstname: $firstname
              lastname: $lastname
              contactNo: $mobileNo
              gender: $gender
              address: $address
              isActive: $isActive
              languages: $languages
              designation: $designation
              education: $education
              experience: $experience
              city: $city
              state: $state
              country: $country
              meetLink: $meetLink
              isDefaultBcba: $isDefaultBcba
            }
          ) {
            status
            message
            staff {
              id
              email
              firstname
              lastname
              contactNo
              gender
              dob
              address
              image
              isActive
              meetLink
              isDefaultBcba
              languages {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              designation
              education
              experience
              city
              state
              country {
                id
                name
              }
              user {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getRequestMeets() {
  return client
    .query({
      query: gql`
        query {
          getRequestMeets {
            edges {
              node {
                id
                startTime
                endTime
                status
                topic
                meetLink
                meetrequestscommentsSet {
                  edges {
                    node {
                      id
                      comment
                    }
                  }
                }
                staff {
                  id
                  email
                }
                learner {
                  id
                  email
                  firstname
                  lastname
                  image
                  parent {
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      `,

      fetchPolicy: 'no-cache',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function updateMeetRequestByBcba(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateMeetRequestByBcba(
          $meetingId: ID!
          $startTime: DateTime
          $endTime: DateTime
          $status: String!
          $link: String
          $localStartTime: DateTime
          $localEndTime: DateTime
        ) {
          updateMeetRequestByBcba(
            input: {
              id: $meetingId
              startTime: $startTime
              endTime: $endTime
              status: $status
              link: $link
              localStartTime: $localStartTime
              localEndTime: $localEndTime
            }
          ) {
            message
            status
            data {
              id
              startTime
              endTime
              status
              topic
              meetLink
              localStartTime
              localEndTime
              meetrequestscommentsSet {
                edges {
                  node {
                    id
                    comment
                  }
                }
              }
              staff {
                id
                email
              }
              learner {
                id
                email
                firstname
                lastname
                image
                parent {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getStaffLearners(payload) {
  return client
    .query({
      query: gql`
        query getStaffLearners($staffId: ID!) {
          getStaffLearners(staffId: $staffId) {
            id
            email
            image
            parent {
              id
              firstName
              lastName
              email
              chatuser {
                id
                userName
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getSlots(payload) {
  return client
    .query({
      query: gql`
        query getStaff($staffId: ID!) {
          getStaffById(id: $staffId) {
            id
            firstname
            lastname
            userRole
            staffweekdaysSet {
              edges {
                node {
                  id
                  weekday
                  isAvailable
                  timeSlots {
                    edges {
                      node {
                        id
                        startTime
                        endTime
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createUpdateSlot(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createUpdateStaffSlots($staffShift: [StaffShiftsInput]!) {
          createUpdateStaffSlots(input: { staffShifts: $staffShift }) {
            message
            status
            staff {
              id
              firstname
              email
              staffweekdaysSet {
                edges {
                  node {
                    id
                    isAvailable
                    weekday
                    timeSlots {
                      edges {
                        node {
                          id
                          startTime
                          endTime
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getSlotsByDate(payload) {
  return client
    .query({
      query: gql`
        query getAvailableStaffTimeSlots($staffId: ID!, $date: Date!, $learnerId: ID!) {
          getAvailableStaffTimeSlots(staffId: $staffId, slotDate: $date, learnerId: $learnerId) {
            message
            status
            staff {
              id
            }
            startDate
            data {
              isAvailable
              timeSlot
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getALlBCBAInSuperAdmin(payload) {
  return client
    .query({
      query: gql`
        query getAllStaffInSuperadmin($firstName: String, $lastName: String) {
          getAllStaffInSuperadmin(firstname_Icontains: $firstName, lastname_Icontains: $lastName) {
            edges {
              node {
                id
                email
                firstname
                lastname
                image
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getAllStaffByClincId(payload) {
  return client
    .query({
      query: gql`
        query getAllStaffInSuperadmin($clinicId: ID!) {
          getAllStaffInSuperadmin(clinic: $clinicId) {
            edges {
              node {
                id
                isActive
                email
                firstname
                lastname
                gender
                image
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getAllBCBAforWebinar(payload) {
  return client
    .query({
      query: gql`
        query getAllStaff {
          getAllStaff {
            edges {
              node {
                id
                isActive
                email
                firstname
                lastname
                gender
                image
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
