import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import User from './action'
import {
  accountDeleteRequest,
  changeUserPassword,
  forgetPassword,
  getAllNotifications,
  getUserDetails,
  login,
  logout,
  markAllRead,
  markNotificationRead,
  RefreshToken,
  registerUserOnFirebase,
  removeUserToken,
  sendOtp,
  sendOtpForAccountDelete,
  signUp,
  verifyChangePassword,
  verifyOtp,
  verifyOtpForAccountDelete,
} from './services'
import { getToken } from 'firebase/messaging'
import { messaging } from '../../firebase'
import { generateUniqueId } from '../../Utilities'

// Function to check if the service worker is active
function* isServiceWorkerActive() {
  if ('serviceWorker' in navigator) {
    try {
      const registration = yield call([navigator.serviceWorker, 'getRegistration'])
      return registration !== null
    } catch (error) {
      console.error('Error getting service worker registration isActive:', error)
      return false
    }
  }
  return false
}

// Function to subscribe and retrieve the token
function* subscribeForPushNotifications() {
  try {
    const isActive = yield call(isServiceWorkerActive)
    console.log('isActive', isActive)
    if (isActive) {
      const permission = yield call(Notification.requestPermission)
      if (permission === 'granted') {
        // Get the service worker registration
        const registration = yield call([navigator.serviceWorker, 'getRegistration'])
        console.log('registration**', registration)
        if (registration) {
          // Subscribe for push notifications
          const token = yield call(getToken, messaging, {
            vapidKey:
              'BNJpEwmJ_9w28wPXCQJkgurVkrjPsv9IgcgchmtPUgre0CmnKNPyI2RdykrzRlzG4f7salFJe-nFT6gTIxceApc',
            serviceWorkerRegistration: registration,
          })
          // const pushSubscription = yield call([registration.pushManager, 'subscribe'], {
          //   userVisibleOnly: true,
          //   applicationServerKey:
          //     'BNJpEwmJ_9w28wPXCQJkgurVkrjPsv9IgcgchmtPUgre0CmnKNPyI2RdykrzRlzG4f7salFJe-nFT6gTIxceApc',
          // })

          // const endpoint = pushSubscription.endpoint
          // const token = endpoint.split('/').pop()

          console.log('Token:', token)

          // let deviceId = sessionStorage.getItem('deviceId')

          // if (!deviceId) {
          let deviceId = generateUniqueId() // Implement your own function to generate a unique ID
          sessionStorage.setItem('deviceId', deviceId)
          localStorage.setItem('deviceId', deviceId)
          // }

          yield put({
            type: User.REGISTER_USER_ON_FIREBASE,
            payload: {
              fcmToken: token,
              deviceId,
            },
          })
        }
      } else if (permission === 'denied') {
        // Handle denied permission
        alert('You denied the notification')
      }
    } else {
      // Register the service worker
      yield call([navigator.serviceWorker, 'register'], '/firsebase-messaging-sw.js')

      // Now, wait for the service worker to be active and subscribe
      yield call(waitForServiceWorkerAndSubscribe)
    }
  } catch (error) {
    // Handle errors
    console.error('Error subscribing for push notifications:', error)
  }
}

// Function to wait for service worker and subscribe
function* waitForServiceWorkerAndSubscribe() {
  yield call(waitForServiceWorkerActivation)
  yield call(subscribeForPushNotifications)
}

// Function to wait for service worker activation
function* waitForServiceWorkerActivation() {
  while (true) {
    const isActive = yield call(isServiceWorkerActive)
    if (isActive) {
      return
    }
    yield call(delay, 1000) // Wait for 1 second before checking again
  }
}

// Utility function for delaying
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export function* REQUEST_PERMISSION() {
  let isReady = false
  const isActive = yield call(isServiceWorkerActive)
  console.log('SERVICE_WORKER_READY OUTSIDE:', isActive)
  const permission = yield call(Notification.requestPermission)
  console.log('permission: ' + permission)
  if (permission === 'granted') {
    const token = yield call(getToken, messaging, {
      vapidKey:
        'BNJpEwmJ_9w28wPXCQJkgurVkrjPsv9IgcgchmtPUgre0CmnKNPyI2RdykrzRlzG4f7salFJe-nFT6gTIxceApc',
    })
    console.log('Token Gen', token)
    // Send this token to the server (db)

    // let deviceId = sessionStorage.getItem('deviceId')

    // if (!deviceId) {
    let deviceId = generateUniqueId() // Implement your own function to generate a unique ID
    sessionStorage.setItem('deviceId', deviceId)
    localStorage.setItem('deviceId', deviceId)
    // }

    yield put({
      type: User.REGISTER_USER_ON_FIREBASE,
      payload: {
        fcmToken: token,
        deviceId,
      },
    })
  } else if (permission === 'denied') {
    alert('You denied the notification')
  }
}

export function* LOGIN({ payload, navigate, previousURL }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(login, payload)
  const deviceId = sessionStorage.getItem('deviceId')
  console.log('DeviceId', deviceId)
  if (deviceId !== undefined && deviceId !== 'null' && deviceId) {
    yield put({
      type: User.REMOVE_USER_TOKEN,
      payload: {
        deviceId,
      },
    })
  }
  if (response && response.tokenAuth) {
    yield put({ type: User.REQUEST_PERMISSION })

    let role = response.tokenAuth.user.groups.edges.length
      ? response.tokenAuth.user.groups.edges[0].node.name
      : ''
    if (!response.tokenAuth.isVerified && !response.tokenAuth.user.isSuperuser) {
      yield call(SEND_OTP, { payload: { userID: response.tokenAuth.user.id } })
    } else if (previousURL) {
      navigate(previousURL)
    } else if (response.tokenAuth.user.isSuperuser) {
      role = 'superuser'
      navigate('/dashboard/superUser')
    } else if (role === 'school_admin') {
      navigate('/dashboard/clinic')
      // navigate('/clinic/assign')

      yield put({
        type: 'clinic/SET_STATE',
        payload: {
          selectedClinic: response.tokenAuth.user.school,
        },
      })
    } else if (role === 'therapist') {
      navigate('/bcba/calendar')
    } else {
      // navigate('/parent/assessment')

      navigate('/dashboard/parent')
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...response.tokenAuth.user,
        authorized: true,
        loading: false,
        role: role.toLowerCase(),
        token: response.tokenAuth.token,
        isVerified: response.tokenAuth.isVerified,
        firstLogin: response.tokenAuth.firstLogin,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SIGNUP({ payload, navigate }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userSaving: true,
    },
  })
  const response = yield call(signUp, payload)
  if (response && response.studentSignup) {
    if (response.studentSignup.status) {
      notification.success({
        message: response.studentSignup.message,
        duration: 0,
      })
      const previousURL = sessionStorage.getItem('previousURL') || '/'

      yield put({
        type: User.LOGIN,
        payload: {
          username: response.studentSignup.student.parent.username,
          password: payload.password,
        },
        navigate,
        previousURL,
      })
      // navigate('/')
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userSaving: false,
    },
  })
}

export function* SEND_OTP({ payload, navigate }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isOtpSending: true,
    },
  })
  const response = yield call(sendOtp, payload)
  if (response && response.sendOtpForAccountVerfication) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isOtpSent: response.sendOtpForAccountVerfication.status,
      },
    })
    if (response.sendOtpForAccountVerfication.status) {
      notification.success({
        message: response.sendOtpForAccountVerfication.message,
        duration: 0,
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isOtpSending: false,
    },
  })
}

export function* VERIFY_OTP({ payload, navigate }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isOtpVerifying: true,
    },
  })
  const role = yield select(state => state.user.role)

  const response = yield call(verifyOtp, payload)
  if (response && response.accountVerificationWithOtp) {
    if (response.accountVerificationWithOtp.status) {
      notification.success({
        message: response.accountVerificationWithOtp.message,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          isVerified: true,
        },
      })
      if (role === 'parents') {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            firstLogin: true,
          },
        })
        navigate('/dashboard/parent')
        // navigate('/parent/assessment')
      }
      if (role === 'school_admin') {
        navigate('/dashboard/clinic')
        // navigate('/clinic/assign')
      } else if (role === 'therapist') {
        navigate('/bcba/calendar')
      }
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isOtpVerifying: false,
      isOtpSent: false,
    },
  })
}

export function* FORGET_PASSWORD({ payload, navigate }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isForgetPasswordRequestSent: true,
    },
  })
  const response = yield call(forgetPassword, payload)
  if (response && response.forgotPassword) {
    if (response.forgotPassword.status) {
      notification.success({
        message: response.forgotPassword.message,
      })
      navigate('/user/changePassword', { state: { ...payload } })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isForgetPasswordRequestSent: false,
    },
  })
}

export function* VERIFY_CHANGE_PASSWORD({ payload, navigate }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isChangePasswordVerified: true,
    },
  })
  const response = yield call(verifyChangePassword, payload)
  if (response && response.verifyChangePassword) {
    if (response.verifyChangePassword.status) {
      notification.success({
        message: response.verifyChangePassword.message,
      })
      navigate('/')
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isChangePasswordVerified: false,
    },
  })
}

export function* LOGOUT({ navigate }) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'logout',
    userId: JSON.parse(localStorage.getItem('userId')),
  })

  const deviceId = sessionStorage.getItem('deviceId')
  if (deviceId !== undefined && deviceId !== 'null' && deviceId) {
    yield put({
      type: User.REMOVE_USER_TOKEN,
      payload: {
        deviceId,
      },
    })
  }
  yield call(logout)

  localStorage.clear()
  sessionStorage.clear()
  navigate('/')
  yield put({
    type: 'user/RESET',
  })
  yield put({
    type: 'chat/RESET',
  })
  yield put({
    type: 'clinic/RESET',
  })
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      loading: false,
      role: '',
    },
  })
  window.location.reload()
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      // role:JSON.parse(localStorage.getItem('role')),
    },
  })
  // localStorage.removeItem('token')

  const response = yield call(RefreshToken)

  if (response && response.refreshToken) {
    const result = yield call(getUserDetails)

    if (result && result.userDetails) {
      let role = result.userDetails.groups.edges[0].node.name.toLowerCase()

      yield put({
        type: 'user/SET_STATE',
        payload: {
          ...result.userDetails,
          authorized: true,
          loading: false,
          role: result.userDetails.groups.edges[0].node.name.toLowerCase(),
          token: response.refreshToken.token,
          // role: result.data.getuser.edges[0].node.groups[0].node.id,
        },
      })
    }
  } else {
    const deviceId = localStorage.getItem('deviceId')
    if (deviceId !== undefined && deviceId !== 'null' && deviceId) {
      yield put({
        type: User.REMOVE_USER_TOKEN,
        payload: {
          deviceId,
        },
      })
      sessionStorage.clear()
    }

    localStorage.clear()
    // sessionStorage.clear()
    // LOGOUT()
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}
export function* CHANGE_USER_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isChangePasswordVerified: true,
    },
  })
  const response = yield call(changeUserPassword, payload)
  if (response && response.changeUserPassword) {
    if (response.changeUserPassword.status) {
      notification.success({
        message: response.changeUserPassword.message,
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isChangePasswordVerified: false,
    },
  })
}
export function* ACCOUNT_DELETION({ navigate }) {
  yield put({
    type: User.SET_STATE,
    payload: {
      accountDeleteRequest: true,
    },
  })
  const response = yield call(accountDeleteRequest)
  if (response && response.accountDeleteRequest) {
    if (response.accountDeleteRequest.status) {
      notification.success({
        message: response.accountDeleteRequest.message,
      })

      yield new Promise(resolve => setTimeout(resolve, 2000))

      yield put({
        type: User.LOGOUT,
        navigate,
      })
    }
  }
  yield put({
    type: User.SET_STATE,
    payload: {
      accountDeleteRequest: false,
    },
  })
}

export function* REGISTER_USER_ON_FIREBASE({ payload }) {
  yield put({
    type: User.SET_STATE,
    payload: {
      isRegistration: true,
    },
  })
  yield call(registerUserOnFirebase, payload)

  yield put({
    type: User.SET_STATE,
    payload: {
      isRegistration: false,
      fcmToken: payload.fcmToken,
      deviceId: payload.deviceId,
    },
  })
}

export function* REMOVE_USER_TOKEN({ payload }) {
  yield put({
    type: User.SET_STATE,
    payload: {
      isRemoving: true,
    },
  })
  yield call(removeUserToken, payload)

  yield put({
    type: User.SET_STATE,
    payload: {
      isRemoving: false,
    },
  })
}

export function* GET_ALL_NOTIFICATIONS() {
  yield put({
    type: User.SET_STATE,
    payload: {
      isNotificationLoading: true,
    },
  })
  const response = yield call(getAllNotifications)
  if (response && response.getNotifications) {
    yield put({
      type: User.SET_STATE,
      payload: {
        notifications: response.getNotifications.edges,
      },
    })
  }
  yield put({
    type: User.SET_STATE,
    payload: {
      isNotificationLoading: false,
    },
  })
}

export function* MARK_NOTIFICATION_READ({ payload }) {
  const response = yield call(markNotificationRead, payload)
  if (response && response.markNotifcationRead && response.markNotifcationRead.status) {
    yield put({
      type: User.REPLACE_NOTIFICATION_READ,
      payload: {
        data: response.markNotifcationRead.details,
      },
    })
  }
}
export function* MARK_ALL_READ() {
  const response = yield call(markAllRead)
  if (response && response.markAllNotifcationAsRead && response.markAllNotifcationAsRead.status) {
    const { notifications } = yield select(state => state.user)
    yield put({
      type: User.SET_STATE,
      payload: {
        notifications: notifications.map(({ node }) => ({
          node: { ...node, read: true },
        })),
      },
    })
  }
}

export function* SEND_OTP_FOR_ACCOUNT_DELETE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isDeleteOtpSending: true,
    },
  })
  const response = yield call(sendOtpForAccountDelete, payload)
  if (response && response.sendOtpForDeactivation) {
    if (response.sendOtpForDeactivation.status) {
      notification.success({
        message: response.sendOtpForDeactivation.message,
      })
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isDeleteOtpSending: false,
    },
  })
}

export function* VERIFY_OTP_FOR_ACCOUNT_DELETE({ payload, navigate }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isDeleteOtpVerifying: true,
    },
  })
  const response = yield call(verifyOtpForAccountDelete, payload)
  if (response && response.verifyOtpForDeactivation) {
    if (response.verifyOtpForDeactivation.status) {
      notification.success({
        message: response.verifyOtpForDeactivation.message,
      })
      navigate('/')
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      isDeleteOtpVerifying: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(User.LOGIN, LOGIN),
    takeLatest(User.LOGOUT, LOGOUT),
    takeLatest(User.SIGNUP, SIGNUP),
    takeLatest(User.SEND_OTP, SEND_OTP),
    takeLatest(User.VERIFY_OTP, VERIFY_OTP),
    takeLatest(User.FORGET_PASSWORD, FORGET_PASSWORD),
    takeLatest(User.VERIFY_CHANGE_PASSWORD, VERIFY_CHANGE_PASSWORD),
    takeLatest(User.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(User.CHANGE_USER_PASSWORD, CHANGE_USER_PASSWORD),
    takeLatest(User.ACCOUNT_DELETION, ACCOUNT_DELETION),
    takeLatest(User.REQUEST_PERMISSION, subscribeForPushNotifications),

    takeLatest(User.REGISTER_USER_ON_FIREBASE, REGISTER_USER_ON_FIREBASE),
    takeLatest(User.REMOVE_USER_TOKEN, REMOVE_USER_TOKEN),
    takeLatest(User.GET_ALL_NOTIFICATIONS, GET_ALL_NOTIFICATIONS),
    takeLatest(User.MARK_NOTIFICATION_READ, MARK_NOTIFICATION_READ),
    takeLatest(User.MARK_ALL_READ, MARK_ALL_READ),

    takeLatest(User.SEND_OTP_FOR_ACCOUNT_DELETE, SEND_OTP_FOR_ACCOUNT_DELETE),
    takeLatest(User.VERIFY_OTP_FOR_ACCOUNT_DELETE, VERIFY_OTP_FOR_ACCOUNT_DELETE),

    LOAD_CURRENT_ACCOUNT(),
  ])
}
