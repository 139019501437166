import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'

// import thunk from 'redux-thunk'
import logger from 'redux-logger'

import reducers from './reducers'
import sagas from './sagas'

// const history = createHashHistory()

const navigation = createHashHistory()
const routeMiddleware = routerMiddleware(navigation)

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
})
sagaMiddleware.run(sagas)

export { store, navigation }
