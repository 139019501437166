import user from './user/reducer'
import tutorial from './Tutorials/reducer'
import clinic from './Clinic/reducer'
import settings from './settings/reducers'
import learner from './Learner/reducer'
import video from './Video/reducer'
import assessment from './Assessment/reducer'
import superuser from './superUser/reducer'
import community from './Community/reducer'
import staff from './staff/reducer'
import chat from './Chat/reducer'
import zoomMeeting from './ZoomMeeting/reducer'

const reducer = {
  user,
  tutorial,
  clinic,
  settings,
  learner,
  video,
  assessment,
  superuser,
  community,
  staff,
  chat,
  zoomMeeting,
}

export default reducer
