const Clinic = {
  SET_STATE: 'clinic/SET_STATE',
  RESET: 'clinic/RESET',
  GET_VIDEOS_BY_CLINIC: 'clinic/GET_VIDEOS_BY_CLINIC',
  GET_CLINIC_DETAILS: 'clinic/GET_CLINIC_DETAILS',
  GET_LEARNERS_BY_CLINIC: 'clinic/GET_LEARNERS_BY_CLINIC',
  GET_LEARNERS_VIDEOS_BY_CLINIC: 'clinic/GET_LEARNERS_VIDEOS_BY_CLINIC',
  ASSIGN_VIDEOS_TO_LEARNER: 'clinic/ASSIGN_VIDEOS_TO_LEARNER',
  SET_ASSIGN_VIDEO: 'clinic/SET_ASSGN_VIDEO',
  GET_ALL_LEARNER_ASSESSMENT: 'clinic/GET_ALL_LEARNER_ASSESSMENT',
  UPDATE_CLINIC: 'clinic/UPDATE_CLINIC',
  GET_WATCH_TIME_GRAPH_DATA: 'clinic/GET_WATCH_TIME_GRAPH_DATA',
  GET_CLINIC_VIDEOS_HIERARCHY: 'clinic/GET_CLINIC_VIDEOS_HIERARCHY',
  GET_CLINIC_DASHBOARD: 'clinic/GET_CLINIC_DASHBOARD',
  GET_LAST5_ASSESSMENT: 'clinic/GET_LAST5_ASSESSMENT',
  GET_CLINIC_VIDEOS_HISTORY: 'clinic/GET_CLINIC_VIDEOS_HISTORY',
  GET_LEARNER_VIDEOS_REPORT: 'clinic/GET_LEARNER_VIDEOS_REPORT',
  PAGE_CHANGED: 'clinic/PAGE_CHANGED',
  GET_GEO_GRAPH_DATA: 'clinic/GET_GEO_GRAPH_DATA',
  GET_CLINIC_VIDEO_BY_TUTORIAL_VIDEO_ID: 'clinic/GET_CLINIC_VIDEO_BY_TUTORIAL_VIDEO_ID',
  GET_MEETING_REQUEST_REPORT: 'clinic/GET_MEETING_REQUEST_REPORT',
  GET_INSTANT_MEETING_REPORT: 'clinic/GET_INSTANT_MEETING_REPORT',
  GET_MANUAL_NSTANT_MEETING_REPORT: 'clinic/GET_MANUAL_NSTANT_MEETING_REPORT',
  GET_WATCH_TIME_BY_LEARNER: 'clinic/GET_WATCH_TIME_BY_LEARNER',
  GET_BCBA_AVAILABILITY_REPORT: 'clinic/GET_BCBA_AVAILABILITY_REPORT',
  SYNC_VIDEOS: 'clinic/SYNC_VIDEOS',
  GET_UNIT_REPORT: 'clinic/GET_UNIT_REPORT',
}

export default Clinic
