export const COLORS = {
  //   Palette Color
  palleteLight: '#edf1f7',
  palleteLightBlue: '#dbe2ef',
  palleteBlue: '#3f72af',
  palleteDarkBlue: '#112d4e',
  grayBackground: '#f1f1f1',
  submitBlue: '#06274e',
  bodyGrey: '#f1f1f1',
  lightGreen: '#34A770',
  orangeButtonColor: '#E58425',

  // Colors
  white: '#fff',
  module: '#08979c',
  submodule: 'orange',

  stimulus: '#F080B8',
  steps: '#F0B880',
  target: '#3f72af',
  class: '#ff7171',
  graph: 'rgb(229, 132, 37)',
  mandTitle: '#3f72af',

  text: '#74708d',
  textLighten: '#c0bdd0',
  textDarken: '#615d7c',
  textDarkenMore: '#514d6a',

  grayIcon: '#707070',
  gray: '#d2d9e5',
  grayLighten: '#eef0f4',
  grayLightenMore: '#fff',
  grayLightenMoreMore: '#f9fafc',
  grayDarken: '#b8beca',
  grayBorder: '#e4e9f0',
  grayFill: '#f9f9f9',

  black: '#0e0b20',
  blackLighten: '#000',
  blackLightenMore: '#000',

  blue: '#08f',
  blueDarken: '#0072d6',
  orange: '#f2a654',
  yellow: 'yellow',

  menuBg: '#001529',
  menuBgDarken: '#000c17',

  // Accent colors
  default: '#acb7bf',
  primary: '#3f5786',
  primaryLight: '#3f57864f',
  secondary: '#f48c2c',
  secondaryMoreLighten: '#fde5ce',
  secondaryLighten: '#fabd85',
  success: '#46be8a',
  info: '#0887c9',
  warning: '#f39834',
  danger: '#fb434a',

  // btn color
  btnPrimaryColor: '#0b35b3',
  btnWidth: '200px',
  subText: '#676767',
  primary_2: '#06264ebc',
  blackTitle: '#16181b',
  blueFont: '#3a525e',
}
export const DRAWER = {
  learnerL1: '30%',
  learnerL2: '55%',
  widthL1: '80%',
  widthL2: '60%',
  widthL3: '50%',
  widthL4: '40%',
}

export const FONT = {
  level1: '24px',
  level2: '20px',
  level3: '18px',
  level4: '14px',
  level5: '12px',
}
export const PRIMARY_BUTTON = {
  backgroundColor: COLORS.submitBlue,
  borderColor: 'transparent',
  color: '#fff',
  padding: '0 8px',
  borderRadius: '0.2rem',
  fontSize: 14,
  fontWeight: 'normal',
}

export const PRIMARY_BUTTON_DARK_THEME = {
  backgroundColor: 'white',
  borderColor: COLORS.submitBlue,
  color: COLORS.submitBlue,
  padding: '0 8px',
  borderRadius: '6px',
  fontSize: 14,
  fontWeight: 'normal',
}
export const WHITE_ICON_BUTTON = {
  color: COLORS.white,
  padding: '0 10px',
  fontSize: 18,
  fontWeight: 600,
}

export const SUBMIT_BUTTON = {
  backgroundColor: COLORS.submitBlue,
  minWidth: 180,
  maxWidht: 'fit-content',
  borderColor: 'transparent',
  height: 40,
  borderRadius: 2,
  boxShadow: '0px 2px 4px rgba(96, 97, 112, 0.16), 0px 0px 1px rgba(40, 41, 61, 0.04)',
  fontSize: 16,
  color: 'white',
  margin: '1rem',
}
export const CANCEL_BUTTON = {
  minWidth: 180,
  height: 40,
  boxShadow: '0px 2px 4px rgba(96, 97, 112, 0.16), 0px 0px 1px rgba(40, 41, 61, 0.04)',
  borderRadius: 2,
  fontSize: 16,
  color: 'white',
  background: '#f55e53',
  margin: '1rem',
  // fontWeight: 600,
}

export const AssessmentButton = {
  borderColor: 'transparent',
  color: '#fff',
  borderRadius: '0.2rem',
  marginTop: 30,
  fontSize: 20,
  textTransform: 'capitalize',
  backgroundColor: COLORS.submitBlue,
  width: 'auto',
  height: 40,
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 4px',
}

export const CHAT_BOX = {
  boxShadow: '0px 2px 4px rgb(244, 140, 44), 0px 0px 1px rgba(40, 41, 61, 0.04)',
  borderRadius: 8,
  borderColor: COLORS.secondary,
  fontSize: 16,
  color: 'white',
  background: 'white',
  margin: '1rem',
  // fontWeight: 600,
}
