/* eslint-disable no-unused-vars */
import { notification } from 'antd'

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function getClinics() {
  return client
    .query({
      query: gql`
        query {
          schools {
            id
            schoolName
            email
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getVideosByClinic(payload) {
  return client
    .query({
      query: gql`
        query getClinicTutorialVideos($videoId: ID) {
          getClinicTutorialVideos(tutorialVideos: $videoId) {
            edges {
              node {
                totalLikes
                totalDislikes
                averageRating
                id
                clinic {
                  id
                  schoolName
                }
                tutorialVideos {
                  id
                  isActive
                  video {
                    id
                    videoName
                    videoUrl
                  }

                  module {
                    id
                    name
                    isActive
                  }
                  subModule {
                    id
                    name
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnersByClinic(payload) {
  return client
    .query({
      query: gql`
        query students($after: String, $first: Int, $firstname: String) {
          students(after: $after, first: $first, firstname_Icontains: $firstname) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                email
                firstname
                lastname
                isActive
                dob
                currentAddress
                studentstutorialvideosSet {
                  edges {
                    node {
                      id
                      status
                      createdAt
                      userWatchTime
                      videoPlayCount
                      tutorialVideos {
                        id
                        video {
                          id
                          videoName
                        }
                        module {
                          id
                          name
                          isActive
                        }
                        subModule {
                          id
                          name
                          isActive
                        }
                      }
                    }
                  }
                }
                clinic {
                  id
                  email
                  schoolName
                }
                parent {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'no-cache',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnersVideosByClinic(payload) {
  return client
    .query({
      query: gql`
        query getAllStudentsVideosByClinic($learnerId: ID!) {
          getAllStudentsVideosByClinic(learnerId: $learnerId) {
            edges {
              node {
                id
                status
                createdAt
                userWatchTime
                videoPlayCount
                learner {
                  id
                  firstname
                }
                tutorialVideos {
                  id

                  video {
                    id
                    videoUrl
                    videoName
                  }
                  isActive
                  module {
                    id
                    name
                    isActive
                  }
                  subModule {
                    id
                    name
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function assignVideosToLearner(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation assignVideosToLearner($learnerId: ID!, $videos: [ID]!) {
          assignVideosToLearner(input: { learner: $learnerId, tutorialVideos: $videos }) {
            message
            status
            learnerTutorialVideos {
              id
              learner {
                id
                firstname
              }
              status
              createdAt
              userWatchTime
              videoPlayCount
              tutorialVideos {
                id
                video {
                  id
                  videoUrl
                  videoName
                }
                isActive
                module {
                  id
                  name
                }
                subModule {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getAllLearnerAssessment(payload) {
  return client
    .query({
      query: gql`
        query getLearnerAssessmentsByClinic(
          $first: Int
          $last: Int
          $after: String
          $before: String
          $startDate: Date
          $endDate: Date
          $email: String
          $name: String
        ) {
          getLearnerAssessmentsByClinic(
            first: $first
            last: $last
            after: $after
            before: $before
            startDate: $startDate
            endDate: $endDate
            email_Icontains: $email
            name_Icontains: $name
          ) {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                learner {
                  id
                  email
                }
                email
                zipCode
                age
                gender
                name
                status
                createdAt
                learnerstrugglingquestionresponsesSet {
                  edges {
                    node {
                      id
                      question {
                        id
                      }
                      option {
                        id
                        option
                      }
                      otherIssue
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getLast5Assessment() {
  return client
    .query({
      query: gql`
        query getLearnerAssessmentsByClinic {
          getLearnerAssessmentsByClinic(first: 5) {
            edges {
              node {
                id
                learner {
                  id
                  email
                }
                assessmentNo
                email
                zipCode
                age
                gender
                name
                status
                createdAt
              }
            }
          }
        }
      `,
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function updateClinic(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateClinic(
          $id: ID!
          $clinicName: String!
          $email: String
          $mobileNo: String
          $address: String
        ) {
          updateClinic(
            input: {
              id: $id
              clinicName: $clinicName
              email: $email
              contactNo: $mobileNo
              address: $address
            }
          ) {
            message
            status
            school {
              id
              schoolName
              email
              contactNo
              address
              noLearner
              isActive
              isDefault
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getClinicVideosInHierarchy(payload) {
  return client
    .query({
      query: gql`
        query getClinicVideosInHierarchy($userId: ID) {
          getClinicVideosInHierarchy {
            moduleId
            moduleName
            isActive
            moduleOrder
            subModules {
              subModuleId
              subModuleName
              isActive
              clinicTutorialVideos {
                id
                clinic {
                  id
                  email
                  schoolName
                }
                totalLikes
                totalDislikes
                averageRating
                videoViews
                tutorialVideos {
                  id
                  isActive
                  video {
                    id
                    videoName
                    videoUrl
                    videoDocFile
                    tutorialvideoviewsSet(reactBy: $userId) {
                      edges {
                        node {
                          id
                          reactBy {
                            id
                          }
                        }
                      }
                    }
                  }
                  module {
                    id
                    name
                    isActive
                  }
                  subModule {
                    id
                    name
                    isActive
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getWatchTimeGraphData(payload) {
  return client
    .query({
      query: gql`
        query getVideoWatchTimeInClinic($startDate: Date!, $endDate: Date!) {
          getVideoWatchTimeInClinic(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getWatchTimeByLearner(payload) {
  return client
    .query({
      query: gql`
        query getVideoWatchTimeOfLearner($startDate: Date!, $endDate: Date!, $learnerID: ID!) {
          getVideoWatchTimeOfLearner(
            startDate: $startDate
            endDate: $endDate
            learnerId: $learnerID
          )
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getClinicDashboard() {
  return client
    .query({
      query: gql`
        query {
          getClinicDashboardData {
            totalVideos
            videosAssigned
            totalLearners
            activeLearners
            inactiveLearners
            performedAssessments
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getClinicVideosHistory(payload) {
  return client
    .query({
      query: gql`
        query getClinicVideosHistory(
          $startDate: Date!
          $endDate: Date
          $clinicId: ID
          $first: Int
          $offset: Int
        ) {
          getClinicVideosHistory(
            startDate: $startDate
            endDate: $endDate
            clinic: $clinicId
            first: $first
            offset: $offset
          ) {
            totalCount
            edges {
              node {
                id
                clinic {
                  id
                  schoolName
                }
                tutorialVideo {
                  id
                  video {
                    id
                    videoName
                    videoUrl
                  }
                }
                isAssigned
                updatedBy {
                  id
                  email
                  username
                }
                createdAt
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLearnerVideosReport(payload) {
  return client
    .query({
      query: gql`
        query getLearnersVideosReport(
          $startDate: Date!
          $endDate: Date
          $isActive: Boolean
          $first: Int
          $offset: Int
        ) {
          getLearnersVideosReport(
            isActive: $isActive
            startDate: $startDate
            endDate: $endDate
            first: $first
            offset: $offset
          ) {
            totalCount
            reportData {
              learner {
                id
                email
                firstname
                lastname
                clinic {
                  id
                  schoolName
                }
                createdAt
              }
              reportData {
                totalAssignVideos
                pendingVideos
                inprogressVideos
                completedVideos
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getGeoGraphData(payload) {
  return client
    .query({
      query: gql`
        query getGeoGraphDataByClinic($videoId: ID!) {
          getGeoGraphDataByClinic(tutorialVideo: $videoId) {
            completedData {
              learner_Country_Name
              learner_Country_Iso3Code
              totalCount
            }
            inprogressData {
              learner_Country_Name
              learner_Country_Iso3Code
              totalCount
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getClinicVideobyTutorialVideoId(payload) {
  return client
    .query({
      query: gql`
        query getClinicVideobyTutorialVideoId($videoId: ID!, $userId: ID) {
          getClinicVideoByTutorialId(tutorialVideoId: $videoId) {
            id
            clinic {
              id
              email
              schoolName
            }

            totalLikes
            totalDislikes
            averageRating
            videoViews
            tutorialVideos {
              id
              video {
                id
                videoUrl
                videoName
                videoDocFile
              }
              isActive
              tutorialvideoviewsSet(reactBy: $userId) {
                edges {
                  node {
                    id
                    reactBy {
                      id
                    }
                  }
                }
              }
              module {
                id
                name
                isActive
              }
              subModule {
                id
                name
                isActive
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getMeetingRequestReport(payload) {
  return client
    .query({
      query: gql`
        query getRequestMeetsByClinic($first: Int, $offset: Int) {
          getRequestMeetsByClinic(status: "approved", first: $first, offset: $offset) {
            totalCount
            edges {
              node {
                id
                startTime
                endTime
                localEndTime
                localStartTime
                status
                meetLink
                learner {
                  id
                  email
                  firstname
                  lastname
                  image
                }
                staff {
                  id
                  email
                  firstname
                  lastname
                  image
                }
                placeOfService {
                  id
                  name
                }
                cptCode {
                  id
                  code
                }
                units
                meetingId
                password
                zoomResponse
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getInstantMeetingReport(payload) {
  return client
    .query({
      query: gql`
        query getInstantMeetByClinic($first: Int, $offset: Int) {
          getInstantMeetByClinic(meetingStatus: "completed", first: $first, offset: $offset) {
            totalCount
            edges {
              node {
                id
                attende {
                  id
                  firstName
                  lastName
                  email
                }
                host {
                  id
                  firstName
                  lastName
                  email
                }
                topic
                meetingStatus
                meetJoined
                meetEnded
                placeOfService {
                  id
                  name
                }
                cptCode {
                  id
                  code
                }
                units
                meetingId
                password
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getManualInstantMeetCalls(payload) {
  return client
    .query({
      query: gql`
        query getManualInstantMeetByClinic(
          $first: Int
          $offset: Int
          $hostEmail: String
          $attendeEmail: String
          $startDate: Date
          $endDate: Date
          $status: String
        ) {
          getManualInstantMeetByClinic(
            first: $first
            offset: $offset
            host_Email_Icontains: $hostEmail
            attende_Email_Icontains: $attendeEmail
            startDate: $startDate
            endDate: $endDate
            status: $status
          ) {
            totalCount
            edges {
              node {
                id
                zoomLink
                status
                client {
                  id
                  firstName
                  lastName
                  lastLogin
                  email
                  students {
                    id
                    image
                    parent {
                      id
                      firstName
                      lastName
                    }
                  }
                  staff {
                    id
                    image
                  }
                }
                provider {
                  id
                  firstName
                  lastName
                  lastLogin
                  email
                  students {
                    id
                    image
                    parent {
                      id
                      firstName
                      lastName
                    }
                  }
                  staff {
                    id
                    image
                  }
                }
                meetTranscript
                meetAudioRecording
                meetVideoRecording
                duration
                units
                callAcceptAt
                callEndAt
                placeOfService {
                  id
                  name
                }
                cptCode {
                  id
                  code
                }
                createdAt
                updatedAt
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getBCBAAvailabilityReport(payload) {
  return client
    .query({
      query: gql`
        query getAllStaffAvailabilityByDate($startDate: Date!) {
          getAllStaffAvailabilityByDate(startDate: $startDate) {
            staff {
              id
              email
            }
            staffWeekday {
              id
              weekday
              timeSlots {
                edges {
                  node {
                    startTime
                    endTime
                  }
                }
              }
            }
            meetRequests {
              id
              topic
              status
              learner {
                id
                firstname
                lastname
                parent {
                  id
                  firstName
                  lastName
                }
              }
              meetLink
              startTime
              endTime
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function syncVideos() {
  return client
    .mutate({
      mutation: gql`
        mutation {
          syncVideoCurriculumByClinic(input: {}) {
            message
            status
          }
        }
      `,
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getUnitReport(payload) {
  return client
    .query({
      query: gql`
        query TherapistGraphOfUnits($startDate: Date!, $endDate: Date!) {
          therapistGraphOfUnits(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
