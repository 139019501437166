import { all } from 'redux-saga/effects'

import user from './user/saga'
import tutorial from './Tutorials/saga'
import clinic from './Clinic/saga'
import setting from './settings/sagas'
import learner from './Learner/saga'
import video from './Video/saga'
import assessment from './Assessment/saga'
import superUser from './superUser/saga'
import community from './Community/saga'
import staff from './staff/saga'
import chat from './Chat/saga'
import zoomMeeting from './ZoomMeeting/saga'

export default function* rootSaga() {
  yield all([
    user(),
    tutorial(),
    clinic(),
    setting(),
    learner(),
    video(),
    assessment(),
    superUser(),
    community(),
    staff(),
    chat(),
    zoomMeeting(),
  ])
}
