import React from 'react'
import { createHashRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import IndexLayout from 'layouts'
import LoadingComponent from './components/LoadingComponent'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <LoadingComponent />,
  })

const withIndexLayout = component => <IndexLayout>{component}</IndexLayout>

const routes = createHashRouter([
  // Annotation test routes
  {
    path: '/',
    Component: loadable(() => import('Pages/user')),
    caseSensitive: true,
    end: true,
  },
  {
    path: '/user/signUp',
    Component: loadable(() => import('Pages/user/signUp')),
  },
  {
    path: '/user/forgotPassword',
    Component: loadable(() => import('Pages/user/forgotPassword')),
  },
  {
    path: '/user/otp',
    Component: loadable(() => import('Pages/user/forgotPassword/OtpVerification')),
  },
  {
    path: '/user/changePassword',
    Component: loadable(() => import('Pages/user/forgotPassword/ChangePassword')),
  },
  {
    path: 'deactivate-account/:deleteId',
    Component: loadable(() => import('Pages/user/DeactivateAccount')),
  },
  {
    path: '/dashboard/parent',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/DashBoard/parentDashboard')),
  },
  {
    path: '/dashboard/clinic',
    Component: loadable(() => import('Pages/DashBoard/clinicDashboard')),
  },
  {
    path: '/clinic/assign',
    Component: loadable(() => import('Pages/DashBoard/clinicDashboard/AssignVideosToParent')),
  },
  {
    path: '/dashboard/superUser',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/DashBoard/superUser')),
  },
  {
    path: '/superuser/tutorial',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/DashBoard/superUser/Tutorials')),
  },
  {
    path: '/superuser/videosCurriculum',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/DashBoard/superUser/VideosCurriculum')),
  },
  {
    path: '/superuser/clinics',
    caseSensitive: true,
    end: true,
    Component: loadable(() => import('Pages/DashBoard/superUser/Clinics')),
  },
  {
    path: '/superuser/assign',
    Component: loadable(() => import('Pages/DashBoard/superUser/AssignVideos')),
  },
  {
    path: '/video',
    Component: loadable(() => import('Pages/VideoPlayer')),
  },
  {
    path: '/video/:videoId',
    Component: loadable(() => import('Pages/VideoPlayer')),
  },
  {
    path: '/parent/assessment',
    Component: loadable(() => import('Pages/Assessment')),
  },
  {
    path: '/parent/newassessment',
    Component: loadable(() => import('Pages/NewAssessment')),
  },
  {
    path: '/parent/assessment/:assessmentId',
    Component: loadable(() => import('Pages/Assessment/RecordAssessment')),
  },
  {
    path: '/clinic/learnerAssessment',
    Component: loadable(() => import('Pages/LearnerAssessment')),
  },
  {
    path: '/userProfile',
    Component: loadable(() => import('Pages/user/UserProfile')),
  },
  {
    path: '/clinic/learners',
    Component: loadable(() => import('Pages/Learners')),
  },
  {
    path: '/history',
    Component: loadable(() => import('Pages/History')),
  },
  {
    path: '/reports',
    Component: loadable(() => import('Pages/Reports')),
  },
  {
    path: '/meetingReports',
    Component: loadable(() => import('Pages/Reports/InstantMeetReport')),
  },
  {
    path: '/community',
    Component: loadable(() => import('Pages/Community')),
  },
  {
    path: '/connectBCBA',
    Component: loadable(() => import('Pages/Community/ConnectBCBA')),
  },
  {
    path: '/webinar',
    Component: loadable(() => import('Pages/CommunitySuperuser/Webinar')),
  },
  {
    path: '/webinar/recording/:webinarId',
    Component: loadable(() => import('Pages/CommunitySuperuser/Webinar/WebinarRecording')),
  },
  {
    path: '/community/webinar',
    Component: loadable(() => import('Pages/CommunitySuperuser/Webinar')),
  },
  {
    path: '/community/bcba',
    Component: loadable(() => import('Pages/CommunitySuperuser/BCBA')),
  },
  {
    path: '/community/clinic/bcba',
    Component: loadable(() => import('Pages/Community/BCBA')),
  },
  {
    path: '/bcba/chat',
    Component: loadable(() => import('Pages/Therapist/ChatWithParent')),
  },
  {
    path: '/bcba/calendar',
    Component: loadable(() => import('Pages/Therapist/Calender')),
  },
  {
    path: '/bcba/videoHistory',
    Component: loadable(() => import('Pages/Therapist/videoHistory')),
  },
  {
    path: '/reports/watchTimeReport',
    Component: loadable(() => import('Pages/Reports/WatchTimeReport')),
  },
  {
    path: '/reports/BCBAAvailabilityReport',
    Component: loadable(() => import('Pages/Reports/BCBAAvailabilityReport')),
  },
  {
    path: '/reports/bcbaMeetingUnits',
    Component: loadable(() => import('./Pages/Reports/BcbaMeetingUnit')),
  },
])

export default routes
