import Learner from './action'

const initialState = {
  learnerVideos: [],
  isLearnerVideosLoading: false,
  selectedVideo: null,
  selectedLearner: null,
  isLearnerDetailsLoading: false,
  learnerVideosHierarchy: [],
  isDashboardLoading: false,
  dashboard: null,
  graphData: [],
  isGraphLoading: false,
  learners: [],
  isLearnerLoading: false,
  isLearnerSaving: false,
  learnerVideosHistory: [],
  isHistoryLoading: false,
  //learner pagination
  learnerCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
  isLearnerCreateDrawer: false,
  learnerAllBCBA: [], //define because used for chat,
  superAdminLearnerCount: 0,
  learnerHistoryCount: 0,
}

export default function learnerReducer(state = initialState, action) {
  switch (action.type) {
    case Learner.SET_STATE:
      return { ...state, ...action.payload }
    case Learner.SET_LIKES: {
      let videos = state.learnerVideos
      let idx = videos.findIndex(({ node }) => node.tutorialVideos.id === action.payload.videoId)
      if (idx !== -1) {
        const oldData = videos[idx].node.tutorialVideos
        const newData = {
          ...oldData,
          totalLikes: action.payload.data.like ? oldData.totalLikes + 1 : oldData.totalLikes,
          totalDislikes: action.payload.data.dislike
            ? oldData.totalDislikes + 1
            : oldData.totalDislikes,
          videoslikedislikeSet: {
            edges: {
              node: {
                ...action.payload.data,
              },
            },
          },
        }
        videos[idx].node.tutorialVideos = newData
      }
      return { ...state, learnerVideos: videos }
    }
    case Learner.APPEND_LEARNER: {
      return {
        ...state,
        isLearnerCreateDrawer: false,
        learners: [
          {
            node: action.payload.data,
          },
          ...state.learners,
        ],
      }
    }
    case Learner.REPLACE_LEARNER: {
      return {
        ...state,
        selectedLearner: null,
        isLearnerCreateDrawer: false,
        learners: [
          ...state.learners.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    default:
      return state
  }
}
