import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import Learner from '../Learner/action'
import SuperUser from './action'
import {
  getWatchTimeGraphData,
  getDashboard,
  getVideosInHierarchy,
  getVideos,
  getClinics,
  activeInActiveClinic,
  createClinic,
  updateClinic,
  getCountry,
  getVideosList,
  getLearnetWatchData,
  getLanguage,
  getClinicVideosByAdmin,
} from './services'

export function* GET_WATCH_TIME_GRAPH_DATA({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isGraphLoading: true,
    },
  })
  const response = yield call(getWatchTimeGraphData, payload)
  if (response && response.getVideoWatchTimeFetchBySuperadmin) {
    const data = response.getVideoWatchTimeFetchBySuperadmin
    const temp = JSON.parse(data)
    const gData = Object.keys(temp).map(item => ({ x: item, y: temp[item] }))
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        graphData: [
          {
            id: 'Watch Time',
            color: 'hsl(286, 70%, 50%)',
            data: gData,
          },
        ],
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isGraphLoading: false,
    },
  })
}

export function* GET_DASHBOARD() {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isDashboardLoading: true,
    },
  })
  const response = yield call(getDashboard)
  if (response && response.getSuperadminDashboardData) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        dashboard: response.getSuperadminDashboardData,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isDashboardLoading: false,
    },
  })
}
export function* GET_VIDEOS_HIERARCHY() {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isVideoLoading: true,
    },
  })
  const response = yield call(getVideosInHierarchy)
  if (response) {
    const temp = response.getVideosModules.edges
    if (temp.length && temp[0].node.tutorialvideossubmoduleSet.edges.length) {
      console.log('Tutorial==>', temp[0].node.tutorialvideossubmoduleSet.edges)
      const selectedVideo = temp[0].node.tutorialvideossubmoduleSet.edges[0].node.tutorialvideosSet
        .edges.length
        ? temp[0].node.tutorialvideossubmoduleSet.edges[0].node.tutorialvideosSet.edges[0].node
        : null
      yield put({
        type: SuperUser.SET_STATE,
        payload: {
          selectedVideo,
        },
      })
    }
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        videosHierarchy: temp,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isVideoLoading: false,
    },
  })
}
export function* GET_VIDEOS() {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isVideoLoading: true,
    },
  })
  const response = yield call(getVideos)
  if (response) {
    const temp = response.getTutorialVideos.edges
    if (temp.length) {
      yield put({
        type: SuperUser.SET_STATE,
        payload: {
          videos: temp,
        },
      })
    }
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        videosHierarchy: response.getClinicVideosInHierarchy,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isVideoLoading: false,
    },
  })
}
export function* GET_CLINICS({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicsLoading: true,
    },
  })
  const response = yield call(getClinics, payload)
  if (response) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        clinics: response.schools.edges,
        pageInfo: response.schools.pageInfo,
        clinicCount: response.schools.totalCount,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicsLoading: false,
    },
  })
}
export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicsLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { pageInfo, type } = yield select(state => state.superuser)

  let after = null
  let before = null
  let last = null

  if (pageNo === 1) {
    after = null
    before = null
  }
  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }

  const response = yield call(getClinics, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        clinics: response.schools.edges,
        pageInfo: response.schools.pageInfo,
        clinicCount: response.schools.totalCount,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicsLoading: false,
    },
  })
}
export function* GET_COUNTRY({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isCountryLoading: true,
    },
  })
  const response = yield call(getCountry, payload)
  if (response) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        country: response.country.edges.map(({ node }) => ({ id: node.id, label: node.name })),
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isCountryLoading: false,
    },
  })
}
export function* GET_LANGUAGE() {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isLanguageLoading: true,
    },
  })
  const response = yield call(getLanguage)
  if (response) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        language: response.getLanguages.edges.map(({ node }) => ({
          id: node.id,
          label: node.name,
        })),
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isLanguageLoading: false,
    },
  })
}
export function* ACTIVE_INACTIVE_CLINIC({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicsLoading: true,
    },
  })
  const response = yield call(activeInActiveClinic, payload)
  const { clinics } = yield select(state => state.superuser)
  if (response && response.activeInactiveClinic.status) {
    notification.success({
      message: response.activeInactiveClinic.message,
    })
    const { id, isActive } = response.activeInactiveClinic.details
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        clinics: [
          ...clinics.map(({ node: item }) => {
            if (item.id === id) {
              return { node: { ...item, isActive } }
            }
            return { node: item }
          }),
        ],
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicsLoading: false,
    },
  })
}

export function* CREATE_CLINIC({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicSaving: true,
    },
  })
  const response = yield call(createClinic, payload)
  if (response && response.clinicSignup.status) {
    notification.success({
      message: 'Clinic Created successfully!',
    })
    yield put({
      type: SuperUser.APPEND_CLINIC,
      payload: {
        data: response.clinicSignup.school,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicSaving: false,
    },
  })
}

export function* UPDATE_CLINIC({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicSaving: true,
    },
  })
  const response = yield call(updateClinic, payload)
  if (response && response.updateClinic.status) {
    notification.success({
      message: response.updateClinic.message,
    })
    yield put({
      type: SuperUser.REPLACE_CLINIC,
      payload: {
        data: response.updateClinic.school,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicSaving: false,
    },
  })
}

export function* GET_VIDEOS_HISTORY({ payload }) {
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isHistoryLoading: true,
    },
  })
  const response = yield call(getLearnerVideosHistory, payload)
  if (response) {
    yield put({
      type: Learner.SET_STATE,
      payload: {
        videosHistory: response.getLearnerVideosHistory.edges,
      },
    })
  }
  yield put({
    type: Learner.SET_STATE,
    payload: {
      isHistoryLoading: false,
    },
  })
}

export function* GET_LEARNER_VIDEOS_LIST({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isVideoListLoading: true,
    },
  })
  const response = yield call(getVideosList, payload)
  if (response) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        selectedUser: response.student.parent.id,
        videoList: response.student.studentstutorialvideosSet.edges,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isVideoListLoading: false,
    },
  })
}

export function* GET_LEARNER_WATCH_DATA({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      watchDataLoading: true,
    },
  })
  const response = yield call(getLearnetWatchData, payload)
  if (response) {
    const { watchDataByUser } = yield select(state => state.superuser)
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        watchDataByUser: {
          ...watchDataByUser,
          [payload.videoId]: response.getLearnerWatchTime.edges,
        },
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      watchDataLoading: false,
    },
  })
}

export function* GET_CLINIC_VIDEOS_BY_ADMIN({ payload }) {
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicTutorialVideosLoading: true,
    },
  })
  const selectedClinic = yield select(state => state.clinic.selectedClinic)

  const response = yield call(getClinicVideosByAdmin, {
    clinicId: selectedClinic.id,
  })
  if (response) {
    yield put({
      type: SuperUser.SET_STATE,
      payload: {
        clinicTutorialVideos: response.getAllClinicsVideosByAdmin.edges,
      },
    })
  }
  yield put({
    type: SuperUser.SET_STATE,
    payload: {
      isClinicTutorialVideosLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(SuperUser.GET_WATCH_TIME_GRAPH_DATA, GET_WATCH_TIME_GRAPH_DATA),
    takeLatest(SuperUser.GET_DASHBOARD, GET_DASHBOARD),
    takeLatest(SuperUser.GET_VIDEOS, GET_VIDEOS),
    takeLatest(SuperUser.GET_CLINICS, GET_CLINICS),
    takeLatest(SuperUser.ACTIVE_INACTIVE_CLINIC, ACTIVE_INACTIVE_CLINIC),
    takeLatest(SuperUser.CREATE_CLINIC, CREATE_CLINIC),
    takeLatest(SuperUser.UPDATE_CLINIC, UPDATE_CLINIC),
    takeLatest(SuperUser.GET_COUNTRY, GET_COUNTRY),
    takeLatest(SuperUser.GET_LANGUAGE, GET_LANGUAGE),

    takeLatest(SuperUser.GET_VIDEOS_HISTORY, GET_VIDEOS_HISTORY),
    takeLatest(SuperUser.GET_VIDEOS_HIERARCHY, GET_VIDEOS_HIERARCHY),
    takeLatest(SuperUser.PAGE_CHANGED, PAGE_CHANGED),
    takeLatest(SuperUser.GET_LEARNER_VIDEOS_LIST, GET_LEARNER_VIDEOS_LIST),
    takeLatest(SuperUser.GET_LEARNER_WATCH_DATA, GET_LEARNER_WATCH_DATA),
    takeLatest(SuperUser.GET_CLINIC_VIDEOS_BY_ADMIN, GET_CLINIC_VIDEOS_BY_ADMIN),
  ])
}
