const SuperUser = {
  SET_STATE: 'superUser/SET_STATE',
  GET_VIDEOS_HIERARCHY: 'superUser/GET_VIDEOS_HIERARCHY',
  GET_WATCH_TIME_GRAPH_DATA: 'superUser/GET_WATCH_TIME_GRAPH_DATA',
  GET_DASHBOARD: 'superUser/GET_DASHBOARD',
  GET_VIDEOS: 'superUser/GET_VIDEOS',
  GET_CLINICS: 'superUser/GET_CLINICS',
  ACTIVE_INACTIVE_CLINIC: 'superUser/ACTIVE_INACTIVE_CLINIC',
  CREATE_CLINIC: 'superUser/CREATE_CLINIC',
  UPDATE_CLINIC: 'superUser/UPDATE_CLINIC',
  APPEND_CLINIC: 'superUser/APPEND_CLINIC',
  REPLACE_CLINIC: 'superUser/REPLACE_CLINIC',
  GET_COUNTRY: 'superUser/GET_COUNTRY',
  GET_LANGUAGE: 'superUser/GET_LANGUAGE',
  GET_VIDEOS_HISTORY: 'superUser/GET_VIDEOS_HISTORY',
  PAGE_CHANGED: 'superUser/PAGE_CHANGED',
  GET_LEARNER_VIDEOS_LIST: 'superUser/GET_LEARNER_VIDEOS_LIST',
  GET_LEARNER_WATCH_DATA: 'superUser/GET_LEARNER_WATCH_DATA',
  GET_CLINIC_VIDEOS_BY_ADMIN: 'superUser/GET_CLINIC_VIDEOS_BY_ADMIN',
}

export default SuperUser
