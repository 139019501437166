/* eslint-disable no-nested-ternary */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Avatar, Button, Dropdown, Typography } from 'antd'
import styles from './style.module.scss'
import { EditOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../../../assests/globalStyle'
import { FiLogOut } from 'react-icons/fi'
import { FaUserEdit } from 'react-icons/fa'

const { Title, Text } = Typography

const ProfileMenu = props => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
      navigate,
    })
  }

  const items = [
    {
      key: 'info',
      label: (
        <>
          <div style={{ display: 'flex', alignItems: 'center', borderBottom: 1 }}>
            <Avatar
              src={user.role === 'school_admin' ? user.school.logo : ''}
              icon={<UserOutlined />}
              style={{ marginRight: 5 }}
            />
            <div>
              <Title level={5} style={{ color: COLORS.blackTitle, marginTop: 0 }}>
                Hello,
                {user.role === 'parents' || user.role === 'therapist'
                  ? user.firstName
                  : user.role === 'school_admin'
                  ? user.firstName
                  : 'Anonymous'}
              </Title>
              <div
                style={{
                  color: COLORS.blackTitle,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 0,
                }}
              >
                <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                  Role:{' '}
                </Title>
                <Text style={{ marginLeft: 5, marginTop: 0, marginBottom: 0 }}>
                  {user.role === 'therapist' ? 'BCBA' : ''}
                  {user.role === 'parents' ? 'Parent' : ''}
                  {user.role.toLowerCase() === 'superuser' ? 'Admin' : ''}
                  {user.role === 'school_admin' ? 'Clinic' : ''}
                </Text>
              </div>
            </div>
          </div>
        </>
      ),
    },
    { type: 'divider' },
    {
      key: 'profile',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaUserEdit style={{ color: COLORS.primary }} size={20} />
          <Button type="link" style={{ color: COLORS.blackTitle }}>
            <Title level={5} style={{ marginTop: 0 }}>
              Profile{' '}
            </Title>
          </Button>
        </div>
      ),
    },
    {
      key: 'logout',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FiLogOut style={{ color: COLORS.primary }} size={20} />
          <Button type="link" style={{ color: COLORS.blackTitle }}>
            <Title level={5} style={{ marginTop: 0 }}>
              Logout
            </Title>
          </Button>
        </div>
      ),
    },
  ]
  const handleMenuClick = e => {
    if (e.key === 'logout') {
      logout()
    }
    if (e.key === 'profile') {
      navigate('/userProfile')
    }
  }
  const menuProps = {
    items:
      user.role === 'superuser'
        ? items.filter(i => i.key !== 'profile')
        : user.role === 'parents'
        ? items.filter(item => item.key !== 'logout')
        : items,
    selectable: false,
    onClick: handleMenuClick,
  }

  return (
    <Dropdown menu={menuProps} trigger="click">
      <div className={styles.dropdown}>
        {user.role !== 'parents' ? (
          <SettingOutlined style={{ fontSize: 20 }} />
        ) : (
          <UserOutlined style={{ fontSize: 20 }} />
        )}
        {user.role !== 'parents' ? (
          <div style={{ marginInlineStart: 10 }}> Settings</div>
        ) : (
          <div style={{ marginInlineStart: 10 }}>Profile</div>
        )}
        {/* <p style={{ fontSize: 10, lineHeight: '13px', marginTop: -14, marginBottom: 3 }}>Logout</p> */}
      </div>
    </Dropdown>
  )
}

export default ProfileMenu
