import React from 'react'
import { Menu, Row, Col, Button, Avatar, Typography, List, Popover, Badge } from 'antd'
import ProfileMenu from './ProfileMenu'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import '../style.module.scss'
import { TiArrowBack } from 'react-icons/ti'
import { COLORS } from '../../../assests/globalStyle'
import _ from 'lodash'
import NotificationList from '../../Notification/NotificationList'
import { FiLogOut } from 'react-icons/fi'

const { Title, Text } = Typography

const MenuTop = ({ menuItems, onClick, current }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(state => state.user)

  const { role } = user
  const { pathname } = useLocation()

  const logout = () => {
    dispatch({
      type: 'user/LOGOUT',
      navigate,
    })
  }

  return (
    <Row
      style={{
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderBottom: role === 'parents' ? '1px solid #dee2e6' : 'none',
      }}
    >
      <Col md={16} xl={role === 'parents' ? 15 : 19} xs={20} className="menu">
        <Menu
          style={{
            flex: 'auto',
            minWidth: 0,
            borderBottom: role !== 'parents' ? '1px solid #dee2e6' : 'none',
          }}
          // theme="dark"
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={menuItems.filter(item => item.key !== 'logout-profile')}
        />
      </Col>
      {role !== 'parents' && (
        <>
          <Col
            md={1}
            xl={2}
            xs={2}
            className="ant-menu-overflow-item ant-menu-item-only-child"
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderBottom: '1px solid #dee2e6',
            }}
          >
            <Button
              type="link"
              onClick={() => {
                if (role === 'parents' && pathname && pathname.includes('assessment')) {
                  navigate('/dashboard/parent')
                } else {
                  navigate(-1)
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: COLORS.blackTitle,
                paddingInlineEnd: 0,
              }}
              className="ant-menu-title-content"
            >
              <TiArrowBack size={20} style={{ marginRight: 5 }} /> Back
            </Button>
          </Col>
          {role !== 'superuser' && (
            <Col
              md={2}
              xl={1}
              xs={2}
              className="ant-menu-overflow-item ant-menu-item-only-child"
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                borderBottom: '1px solid #dee2e6',
              }}
            >
              <NotificationList />
            </Col>
          )}
          <Col
            md={4}
            xl={2}
            xs={2}
            className="ant-menu-overflow-item ant-menu-item-only-child"
            style={{
              justifyContent: 'flex-start',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderBottom: '1px solid #dee2e6',
            }}
          >
            <div>
              <div className="ant-menu-title-content">
                <ProfileMenu />
              </div>
            </div>
          </Col>
        </>
      )}
      {role === 'parents' && (
        <>
          {/* <Col
            md={2}
            xl={1}
            xs={2}
            className="ant-menu-overflow-item ant-menu-item-only-child"
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderBottom: '1px solid #dee2e6',
            }}
          >
            <NotificationList />
          </Col> */}
          <Col
            md={4}
            xl={6}
            xs={4}
            // className="ant-menu-overflow-item ant-menu-item-only-child"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: 'white',
              alignItems: 'center',
              // borderBottom: '1px solid #dee2e6',
              padding: '8px 14px',
              paddingInlineEnd: 0,
              // textAlign: 'center',
              // height: '58px',
            }}
          >
            <NotificationList />
            <Avatar
              src={`https://ui-avatars.com/api/name=${
                user && user.students && user.students.parentFirstname
                  ? user.students.parentFirstname[0]
                  : user.firstName[0]
              }${
                user && user.students && user.students.parentLastname
                  ? user.students.parentLastname[0]
                  : ''
              }&background=random&bold=true`}
              style={{ marginRight: 5 }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Title level={5} style={{ color: COLORS.blackTitle, margin: 0 }}>
                {_.capitalize(
                  user &&
                    user.students &&
                    `${user.firstName} ${user.lastName ? user.lastName : ''}`,
                )}
              </Title>
            </div>
          </Col>
          <Col
            md={3}
            xl={2}
            xs={2}
            className="ant-menu-overflow-item ant-menu-item-only-child"
            style={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              borderBottom: '1px solid #dee2e6',
            }}
          >
            <Button
              type="link"
              onClick={() => {
                logout()
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                color: COLORS.blackTitle,
                paddingInlineEnd: 0,
                paddingInlineStart: 0,
              }}
              className="ant-menu-title-content"
            >
              <FiLogOut size={20} style={{ marginRight: 5 }} /> Logout
            </Button>
          </Col>
        </>
      )}
    </Row>
  )
}
export default MenuTop
