import Tutorial from './action'

const initialState = {
  clinics: [],
  isClinicLoading: false,
  modules: [],
  isModulesLoading: false,
  isModuleSaving: false,
  selectedModule: null,
  selectedSubModule: null,
  selectedVideo: null,
  subModules: [],
  isSubModuleLoading: false,
  isSubModuleSaving: false,
  videos: [],
  isVideoLoading: false,
  isVideoSaving: false,
  isAssigning: false,
  isAllVideoLoading: false,
  allVideos: [],
  isCreateTutorialVideoDrawer: false,
  isCurriculumVideosLoading: false,
  curriculumVideos: [],
  isVideoCreatingModal: false,
  selectedCurriculumVideo: null,
  isCurriculumVideoSaving: false,
}

export default function tutorialReducer(state = initialState, action) {
  switch (action.type) {
    case Tutorial.SET_STATE:
      return { ...state, ...action.payload }
    case Tutorial.APPEND_MODULE: {
      return {
        ...state,
        modules: [...state.modules, { node: action.payload }],
        selectedModule: action.payload,
      }
    }
    case Tutorial.REPLACE_MODULE: {
      const tempIndex = state.modules.findIndex(({ node }) => node.id === action.payload.moduleID)
      let newData = state.modules[tempIndex].node

      const newModule = { ...newData, ...action.payload.data }

      state.modules[tempIndex].node = newModule
      return {
        ...state,
      }
    }
    case Tutorial.APPEND_SUB_MODULE: {
      return {
        ...state,
        subModules: [...state.subModules, { node: action.payload }],
        selectedSubModule: action.payload,
      }
    }
    case Tutorial.REPLACE_SUB_MODULE: {
      const tempIndex = state.subModules.findIndex(
        ({ node }) => node.id === action.payload.subModuleID,
      )
      let newData = state.subModules[tempIndex].node

      const newModule = { ...newData, ...action.payload.data }

      state.subModules[tempIndex].node = newModule
      return {
        ...state,
      }
    }
    case Tutorial.APPEND_VIDEO: {
      return {
        ...state,
        isCreateTutorialVideoDrawer: false,
        videos: [...state.videos, { node: action.payload }],
      }
    }
    case Tutorial.REPLACE_VIDEO: {
      const tempIndex = state.videos.findIndex(({ node }) => node.id === action.payload.videoId)
      let newData = state.videos[tempIndex].node

      const newModule = { ...newData, ...action.payload.data }

      state.videos[tempIndex].node = newModule
      return {
        ...state,
        isCreateTutorialVideoDrawer: false,
        selectedVideo: null,
      }
    }
    case Tutorial.SET_ASSIGN_VIDEO: {
      const selectedClinic = action.payload.selectedClinic
      const clinics = state.clinics
      const idx = clinics.findIndex(item => item.id === selectedClinic.id)
      if (idx !== -1) {
        let newData = clinics[idx]
        const newAddedData = {
          ...newData,
          clinictutorialvideosSet: {
            edges: action.payload.data,
          },
        }
        clinics[idx] = newAddedData
      }

      return {
        ...state,
        selectedClinic: {
          ...selectedClinic,
          clinictutorialvideosSet: {
            edges: action.payload.data,
          },
        },
      }
    }
    case Tutorial.APPEND_CURRICULUM_VIDEO:
      return {
        ...state,
        isVideoCreatingModal: false,
        curriculumVideos: [
          ...state.curriculumVideos,
          {
            node: action.payload.data,
          },
        ],
      }
    case Tutorial.REPLACE_CURRICULUM_VIDEO: {
      return {
        ...state,
        selectedCurriculumVideo: null,
        isVideoCreatingModal: false,
        curriculumVideos: [
          ...state.curriculumVideos.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    default:
      return state
  }
}
