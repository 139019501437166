import dayjs from 'dayjs'
import Clinic from './action'

const initialState = {
  selectedClinic: null,
  clinicVideos: [],
  isVideoLoading: false,
  learners: [],
  isLearnerLoading: false,
  learnersFetched: false,
  learnerPageInfo: null,
  selectedLearner: null,
  learnerVideos: [],
  learnerVideosLoading: false,
  isAssigning: false,
  selectedVideo: null,
  learnerAssessments: [],
  isLearnerAssessmentLoading: false,
  isClinicSaving: false,
  graphData: [],
  isGraphLoading: false,
  clinicVideosHierarchy: [],
  isDashboardLoading: false,
  dashboard: null,
  last5Assessment: [],
  clinicVideosHistory: [],
  isHistoryLoading: false,
  isReportLoading: false,
  reportData: [],
  assessmentCount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',
  geoGraphData: null,
  isGeoGraphLoading: false,
  videoById: null,
  isVideoByIdLoading: false,
  allMeetRequest: [],
  isAllRequestLoading: false,
  allInstantMeetings: [],
  allManualInstantMeetings: [],
  instantMeetingCount: 0,
  requestMeetingCount: 0,
  learnerReportCount: 0,
  isAllInstantMeetingLoading: false,
  isWatchTimeGraphLoading: false,
  learnerGraphWatchData: [],
  isBCBAAvailabilityLoading: false,
  BCBAAvailability: [],
  renderedStartDate: dayjs(),
  renderedEndDate: dayjs(),
  studentTutorialVideos: [],
  isStudentTutorialVideosLoading: false,
  isSyncing: false,

  bcbaReportData: [],
}

export default function tutorialReducer(state = initialState, action) {
  switch (action.type) {
    case Clinic.SET_STATE:
      return { ...state, ...action.payload }
    case Clinic.RESET:
      return { ...initialState }
    case Clinic.SET_ASSIGN_VIDEO: {
      const selectedLearner = state.selectedLearner
      const learners = state.learners
      const idx = learners.findIndex(({ node }) => node.id === selectedLearner.id)
      if (idx !== -1) {
        let newData = learners[idx].node
        const newAddedData = {
          ...newData,
          studentstutorialvideosSet: {
            edges: action.payload.data,
          },
        }
        learners[idx].node = newAddedData
      }

      return {
        ...state,
        learners,
        studentTutorialVideos: action.payload.data,
        selectedLearner: {
          ...selectedLearner,
          studentstutorialvideosSet: {
            edges: action.payload.data,
          },
        },
      }
    }

    default:
      return state
  }
}
