import User from './action'

const initialState = {
  id: '',
  isVerified: false,
  authorized: false,
  loading: false,
  role: '',
  token: null,
  userSaving: false,
  isOtpSending: false,
  isOtpSent: false,
  isOtpVerifying: false,
  isForgetPasswordRequestSent: false,
  isChangePasswordVerified: false,
  students: null,
  school: null,
  staff: null,
  current: 'dashboard',
  accountDeleteRequest: false,

  //notification
  isNotificationLoading: false,
  notifications: [],
  isRegistration: false,
  isRemoving: false,
  notificationPopoverVisible: false,
  clickedNotificationId: null,

  //deActivateaccount
  isDeleteOtpSending: false,
  isDeleteOtpVerifying: false,

  //fcmtokem
  fcmToken: null,
  deviceId: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case User.SET_STATE:
      return { ...state, ...action.payload }
    case User.RESET:
      return { ...initialState }
    case User.REPLACE_NOTIFICATION_READ: {
      return {
        ...state,
        notificationPopoverVisible: false,
        notifications: [
          ...state.notifications.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    default:
      return state
  }
}
