/* eslint-disable no-unused-vars */

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function likeDislikeVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation likeDislikeVideo($videoId: ID!, $like: Boolean!, $disLike: Boolean!) {
          likeDislikeVideo(input: { tutorialVideo: $videoId, like: $like, dislike: $disLike }) {
            message
            status
            totalLikesInClinic
            totalDislikesInClinic
            videoLikeDislike {
              id
              like
              dislike
              reactBy {
                id
                email
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function addVideoRating(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation addUpdateVideoRating($videoId: ID!, $rating: Int!) {
          addUpdateVideoRating(input: { tutorialVideo: $videoId, rating: $rating }) {
            message
            status
            averageRatingInClinic
            videoRating {
              id
              rating
              reactBy {
                id
                username
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function addCommentOnVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation addCommentOnVideo($videoId: ID!, $comment: String!) {
          addCommentOnVideo(input: { tutorialVideo: $videoId, comment: $comment }) {
            message
            status
            videoComment {
              id
              video {
                id
                tutorialvideosSet {
                  edges {
                    node {
                      id
                      video {
                        id
                        videoName
                      }
                    }
                  }
                }
              }
              comment
              reactBy {
                id
                username
                firstName
                lastName
                email
                students {
                  id
                  firstname
                  lastname
                  parentNickname
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function updateCommentOnVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateCommentOnVideo($commentId: ID!, $comment: String!) {
          updateCommentOnVideo(input: { id: $commentId, comment: $comment }) {
            message
            status
            videoComment {
              id
              video {
                id
                tutorialvideosSet {
                  edges {
                    node {
                      id
                      video {
                        id
                        videoName
                      }
                    }
                  }
                }
              }
              comment
              reactBy {
                id
                username
                firstName
                lastName
                email
                students {
                  id
                  firstname
                  lastname
                  parentNickname
                }
              }
              reply {
                edges {
                  node {
                    id
                    comment
                    parentCommentReply {
                      id
                      reactBy {
                        id
                        firstName
                        students {
                          id
                          firstname
                          lastname
                          parentNickname
                        }
                      }
                    }
                    reactBy {
                      id
                      username
                      firstName
                      lastName
                      email
                      students {
                        id
                        firstname
                        lastname
                        parentNickname
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function addCommentReply(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation addReplyOnComment($commentId: ID!, $reply: String!, $parentReplyId: ID) {
          addReplyOnComment(
            input: { commentId: $commentId, reply: $reply, parentReplyId: $parentReplyId }
          ) {
            message
            status
            commentReply {
              id
              comment
              parentCommentReply {
                id
                reactBy {
                  id
                  firstName
                  students {
                    id
                    firstname
                    lastname
                    parentNickname
                  }
                }
              }
              reactBy {
                id
                username
                firstName
                lastName
                email
                students {
                  id
                  firstname
                  lastname
                  parentNickname
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
export async function updateCommentReply(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateReplyOnComment($replyCommentId: ID!, $replyComment: String!) {
          updateReplyOnComment(
            input: { replyCommentId: $replyCommentId, replyComment: $replyComment }
          ) {
            message
            status
            commentReply {
              id
              comment
              parentCommentReply {
                id
                reactBy {
                  id
                  firstName
                  students {
                    id
                    firstname
                    lastname
                    parentNickname
                  }
                }
              }
              reactBy {
                id
                username
                firstName
                lastName
                email
                students {
                  id
                  firstname
                  lastname
                  parentNickname
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
export async function removeComment(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation removeCommentOrReply($commentId: ID, $replyCommentId: ID) {
          removeCommentOrReply(input: { commentId: $commentId, replyCommentId: $replyCommentId }) {
            message
            status
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function fetchVideoComments(payload) {
  return client
    .query({
      query: gql`
        query getVideoComments($videoId: ID!) {
          getVideoComments(videoId: $videoId) {
            edges {
              node {
                id
                video {
                  id
                  videoName
                }
                comment
                reactBy {
                  id
                  username
                  firstName
                  lastName
                  email
                  students {
                    id
                    firstname
                    lastname
                    parentNickname
                  }
                }
                reply {
                  edges {
                    node {
                      id
                      comment
                      parentCommentReply {
                        id
                        reactBy {
                          id
                          firstName
                          students {
                            id
                            firstname
                            lastname
                            parentNickname
                          }
                        }
                      }
                      reactBy {
                        id
                        username
                        firstName
                        lastName
                        email
                        students {
                          id
                          firstname
                          lastname
                          parentNickname
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function getLikeDislikeOnVideo(payload) {
  return client
    .query({
      query: gql`
        query getVideoLikeDislike($videoId: ID!) {
          getVideoLikeDislike(videoId: $videoId) {
            edges {
              node {
                id
                tutorialVideos {
                  totalLikes
                  totalDislikes
                }
                like
                dislike
                reactBy {
                  id
                  username
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function onPauseVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation trackLearnerVideo(
          $videoId: ID!
          $status: String
          $videoTrack: JSONString
          $videoWatchedTime: BigInt
          $userWatchTime: BigInt
          $userStartWatching: DateTime
          $userEndWatching: DateTime
          $videoPlayCount: Int
          $videoStartPoint: JSONString
          $videoEndPoint: JSONString
        ) {
          trackLearnerVideo(
            input: {
              learnerVideo: $videoId
              status: $status
              videoTrack: $videoTrack
              videoWatchedTime: $videoWatchedTime
              userWatchTime: $userWatchTime
              userStartWatching: $userStartWatching
              userEndWatching: $userEndWatching
              videoPlayCount: $videoPlayCount
              videoStartPoint: $videoStartPoint
              videoEndPoint: $videoEndPoint
            }
          ) {
            status
            message
            learnerVideo {
              id
              status
              videoPlayedAt
              videoCompletedAt
              videoPlayCount
              userWatchTime
              videoWatchedTime
              learner {
                id
                email
              }
              tutorialVideos {
                id
                video {
                  id
                  videoName
                  videoUrl
                }

                module {
                  id
                }
                subModule {
                  id
                }
              }
              studentvideotracking {
                id
                videoTracking
                learnerVideo {
                  id
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
        videoTrack: payload.videoTrack ? JSON.stringify(payload.videoTrack) : null,
        videoStartPoint: payload.videoStartPoint ? JSON.stringify(payload.videoStartPoint) : null,
        videoEndPoint: payload.videoEndPoint ? JSON.stringify(payload.videoEndPoint) : null,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
export async function addVideoView(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation addVideoView($videoId: ID!) {
          addVideoView(input: { tutorialVideo: $videoId }) {
            status
            message
            videoView {
              id
              video {
                id
                tutorialvideosSet {
                  edges {
                    node {
                      id
                      video {
                        id
                        videoName
                      }
                      totalLikes
                      totalDislikes
                      videoViews
                      module {
                        id
                      }
                      subModule {
                        id
                      }
                      studentstutorialvideosSet(tutorialVideos: $videoId) {
                        edges {
                          node {
                            id
                            videoViewsInClinic
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
// ($startDate: Date, $endDate: Date)
// (startDate: $startDate, endDate: $endDate)
export async function getGeoGraphData(payload) {
  return client
    .query({
      query: gql`
        query getVideoDataForGeoGraph($videoId: ID!) {
          getVideoDataForGeoGraph(tutorialVideo: $videoId) {
            completedData {
              learner_Country_Name
              learner_Country_Iso3Code
              totalCount
            }
            inprogressData {
              learner_Country_Name
              learner_Country_Iso3Code
              totalCount
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
