import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import User from '../user/action'
import Video from '../Video/action'
import Clinic from './action'
import {
  assignVideosToLearner,
  getAllLearnerAssessment,
  getBCBAAvailabilityReport,
  getClinicDashboard,
  getClinicVideobyTutorialVideoId,
  getClinicVideosHistory,
  getClinicVideosInHierarchy,
  getGeoGraphData,
  getInstantMeetingReport,
  getLast5Assessment,
  getLearnersByClinic,
  getLearnersVideosByClinic,
  getLearnerVideosReport,
  getMeetingRequestReport,
  getVideosByClinic,
  getWatchTimeByLearner,
  getWatchTimeGraphData,
  syncVideos,
  updateClinic,
  getUnitReport,
  getManualInstantMeetCalls,
} from './services'

export function* GET_VIDEOS_BY_CLINIC({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isVideoLoading: true,
    },
  })
  const response = yield call(getVideosByClinic, payload)
  if (response) {
    const temp = response.getClinicTutorialVideos.edges.map(({ node: item }) => ({
      node: {
        ...item,
        tutorialVideos: {
          ...item.tutorialVideos,
          totalLikes: item.totalLikes,
          totalDislikes: item.totalDislikes,
          averageRating: item.averageRating,
        },
      },
    }))
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        clinicVideos: temp,
        selectedVideo: payload ? temp[0] : null,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isVideoLoading: false,
    },
  })
}

export function* GET_LEARNERS_BY_CLINIC({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const response = yield call(getLearnersByClinic, payload)
  if (response) {
    const { learners, selectedLearner } = yield select(state => state.clinic)
    const learnersData =
      payload && payload.firstname
        ? response.students.edges
        : [...learners, ...response.students.edges]
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        learnerPageInfo: response.students.pageInfo,
        learners: learnersData,
        selectedLearner:
          !selectedLearner && learnersData.length ? learnersData[0].node : selectedLearner,
        learnersFetched: true,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export function* GET_LEARNERS_VIDEOS_BY_CLINIC({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const selectedLearner = yield select(state => state.clinic.selectedLearner)

  const response = yield call(getLearnersVideosByClinic, {
    learnerId: selectedLearner.id,
  })
  if (response) {
    // yield put({
    //   type: Clinic.SET_STATE,
    //   payload: {
    //     selectedLearner: {
    //       ...selectedLearner,
    //       studentstutorialvideosSet: response.getAllStudentsVideosByClinic,
    //     },
    //   },
    // })
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        studentTutorialVideos: response.getAllStudentsVideosByClinic.edges,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}
export function* ASSIGN_VIDEOS_TO_LEARNER({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAssigning: true,
    },
  })
  const response = yield call(assignVideosToLearner, payload)
  if (response && response.assignVideosToLearner) {
    if (response.assignVideosToLearner.status) {
      notification.success({
        message: response.assignVideosToLearner.message,
      })

      yield put({
        type: Clinic.SET_ASSIGN_VIDEO,
        payload: {
          data: response.assignVideosToLearner.learnerTutorialVideos.map(item => ({
            node: item,
          })),
        },
      })
    }
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAssigning: false,
    },
  })
}
export function* GET_ALL_LEARNER_ASSESSMENT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: true,
    },
  })

  const response = yield call(getAllLearnerAssessment, payload)
  if (response && response.getLearnerAssessmentsByClinic) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        learnerAssessments: response.getLearnerAssessmentsByClinic.edges,
        pageInfo: response.getLearnerAssessmentsByClinic.pageInfo,
        assessmentCount: response.getLearnerAssessmentsByClinic.totalCount,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: false,
    },
  })
}
export function* PAGE_CHANGED({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: true,
    },
  })
  let { first, pageNo } = payload
  const { pageInfo, type } = yield select(state => state.clinic)
  const size = first
  let after = null
  let before = null
  let last = null

  if (type === 'next') {
    after = pageInfo?.endCursor
  }
  if (type === 'prev') {
    before = pageInfo?.startCursor
    last = first
    first = null
  }
  if (pageNo === 1) {
    after = null
    before = null
    last = null
    first = size
  }

  const response = yield call(getAllLearnerAssessment, { ...payload, after, before, first, last })
  if (response) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        learnerAssessments: response.getLearnerAssessmentsByClinic.edges,
        pageInfo: response.getLearnerAssessmentsByClinic.pageInfo,
        assessmentCount: response.getLearnerAssessmentsByClinic.totalCount,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: false,
    },
  })
}

export function* GET_LAST5_ASSESSMENT() {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: true,
    },
  })

  const response = yield call(getLast5Assessment)
  if (response && response.getLearnerAssessmentsByClinic) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        last5Assessment: response.getLearnerAssessmentsByClinic.edges,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isLearnerAssessmentLoading: false,
    },
  })
}

export function* UPDATE_CLINIC({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isClinicSaving: true,
    },
  })
  const { school } = yield select(state => state.user)
  const response = yield call(updateClinic, payload)
  if (response && response.updateClinic) {
    if (response.updateClinic.status) {
      notification.success({
        message: response.updateClinic.message,
      })

      yield put({
        type: User.SET_STATE,
        payload: {
          school: { ...school, ...response.updateClinic.school },
        },
      })
    }
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isClinicSaving: false,
    },
  })
}
export function* GET_WATCH_TIME_GRAPH_DATA({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isGraphLoading: true,
    },
  })
  const response = yield call(getWatchTimeGraphData, payload)
  if (response && response.getVideoWatchTimeInClinic) {
    const data = response.getVideoWatchTimeInClinic
    const temp = JSON.parse(data)
    const gData = Object.keys(temp).map(item => ({ x: item, y: temp[item] }))
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        graphData: [
          {
            id: 'Watch Time',
            color: 'hsl(286, 70%, 50%)',
            data: gData,
          },
        ],
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isGraphLoading: false,
    },
  })
}
export function* GET_CLINIC_VIDEOS_HIERARCHY() {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isVideoLoading: true,
    },
  })
  const { id } = yield select(state => state.user)
  const response = yield call(getClinicVideosInHierarchy, { userId: id })
  if (response) {
    const temp = response.getClinicVideosInHierarchy

    yield put({
      type: Clinic.SET_STATE,
      payload: {
        clinicVideosHierarchy: temp,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isVideoLoading: false,
    },
  })
}

export function* GET_CLINIC_DASHBOARD() {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isDashboardLoading: true,
    },
  })
  const response = yield call(getClinicDashboard)
  if (response && response.getClinicDashboardData) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        dashboard: response.getClinicDashboardData,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isDashboardLoading: false,
    },
  })
}

export function* GET_CLINIC_VIDEOS_HISTORY({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isHistoryLoading: true,
    },
  })
  const response = yield call(getClinicVideosHistory, payload)
  if (response) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        clinicVideosHistory: response.getClinicVideosHistory.edges,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isHistoryLoading: false,
    },
  })
}

export function* GET_LEARNER_VIDEOS_REPORT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isReportLoading: true,
    },
  })
  const response = yield call(getLearnerVideosReport, payload)
  if (response) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        reportData: response.getLearnersVideosReport.reportData,
        learnerReportCount: response.getLearnersVideosReport.totalCount,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isReportLoading: false,
    },
  })
}

export function* GET_GEO_GRAPH_DATA({ payload }) {
  yield put({
    type: Video.SET_STATE,
    payload: {
      isGeoGraphLoading: true,
    },
  })
  const response = yield call(getGeoGraphData, payload)
  if (response) {
    yield put({
      type: Video.SET_STATE,
      payload: {
        geoGraphData: response.getGeoGraphDataByClinic,
      },
    })
  }
  yield put({
    type: Video.SET_STATE,
    payload: {
      isGeoGraphLoading: false,
    },
  })
}

export function* GET_CLINIC_VIDEO_BY_TUTORIAL_VIDEO_ID({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isVideoByIdLoading: true,
    },
  })
  const { id } = yield select(state => state.user)
  const { clinicVideosHierarchy } = yield select(state => state.clinic)

  const response = yield call(getClinicVideobyTutorialVideoId, { userId: id, ...payload })

  if (response?.getClinicVideoByTutorialId) {
    const temp = response.getClinicVideoByTutorialId

    const { module, subModule } = temp.tutorialVideos

    const modIdx = clinicVideosHierarchy.findIndex(item => item.moduleId === module.id)
    if (modIdx !== -1) {
      const mod = clinicVideosHierarchy[modIdx]
      const subIdx = mod.subModules.findIndex(item => item.subModuleId === subModule.id)
      if (subIdx !== -1) {
        const sub = mod.subModules[subIdx]
        const subData = sub.clinicTutorialVideos
        const vidIdx = subData.findIndex(item => item.tutorialVideos.id === temp.tutorialVideos.id)
        if (vidIdx !== -1) {
          yield put({
            type: Video.SET_STATE,
            payload: {
              selectedVideo: temp,
              selectedModuleIndex: modIdx,
              selectedSubModuleIndex: subIdx,
              selectedVideoIndex: vidIdx,
            },
          })
        }
      }
    }

    yield put({ type: Clinic.SET_STATE, payload: { videoById: temp } })
  }

  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isVideoByIdLoading: false,
    },
  })
}

export function* GET_MEETING_REQUEST_REPORT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAllRequestLoading: true,
    },
  })
  const selectedLearner = yield select(state => state.clinic.selectedLearner)

  const response = yield call(getMeetingRequestReport, payload)
  if (response) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        allMeetRequest: response.getRequestMeetsByClinic.edges,
        requestMeetingCount: response.getRequestMeetsByClinic.totalCount,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAllRequestLoading: false,
    },
  })
}

export function* GET_INSTANT_MEETING_REPORT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAllInstantMeetingLoading: true,
    },
  })

  const response = yield call(getInstantMeetingReport, payload)
  if (response) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        allInstantMeetings: response.getInstantMeetByClinic.edges,
        instantMeetingCount: response.getInstantMeetByClinic.totalCount,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAllInstantMeetingLoading: false,
    },
  })
}

export function* GET_MANUAL_NSTANT_MEETING_REPORT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAllInstantMeetingLoading: true,
    },
  })
  const response = yield call(getManualInstantMeetCalls, payload)
  if (response && response.getManualInstantMeetByClinic) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        allManualInstantMeetings: response.getManualInstantMeetByClinic.edges,
        instantMeetingCount: response.getManualInstantMeetByClinic.totalCount,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isAllInstantMeetingLoading: false,
    },
  })
}

export function* GET_WATCH_TIME_BY_LEARNER({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isWatchTimeGraphLoading: true,
    },
  })
  const response = yield call(getWatchTimeByLearner, payload)
  if (response && response.getVideoWatchTimeOfLearner) {
    const data = response.getVideoWatchTimeOfLearner
    const temp = JSON.parse(data)
    const gData = Object.keys(temp).map(item => ({
      x: item,
      y: temp[item].total_seconds,
      videoData: temp[item],
    }))
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        learnerGraphWatchData: [
          {
            id: 'Watch Time',
            color: 'hsl(286, 70%, 50%)',
            data: gData,
          },
        ],
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isWatchTimeGraphLoading: false,
    },
  })
}

export function* GET_BCBA_AVAILABILITY_REPORT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isBCBAAvailabilityLoading: true,
    },
  })
  const response = yield call(getBCBAAvailabilityReport, payload)
  if (response && response.getAllStaffAvailabilityByDate) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        BCBAAvailability: response.getAllStaffAvailabilityByDate,
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isBCBAAvailabilityLoading: false,
    },
  })
}

export function* SYNC_VIDEOS() {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isSyncing: true,
    },
  })
  const response = yield call(syncVideos)
  if (response && response.syncVideoCurriculumByClinic.status) {
    notification.success({
      message: response.syncVideoCurriculumByClinic.message,
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isSyncing: false,
    },
  })
}

export function* GET_UNIT_REPORT({ payload }) {
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isReportLoading: true,
    },
  })
  const response = yield call(getUnitReport, payload)
  if (response) {
    yield put({
      type: Clinic.SET_STATE,
      payload: {
        bcbaReportData: JSON.parse(response.therapistGraphOfUnits),
      },
    })
  }
  yield put({
    type: Clinic.SET_STATE,
    payload: {
      isReportLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(Clinic.GET_VIDEOS_BY_CLINIC, GET_VIDEOS_BY_CLINIC),
    takeLatest(Clinic.GET_LEARNERS_BY_CLINIC, GET_LEARNERS_BY_CLINIC),
    takeLatest(Clinic.GET_LEARNERS_VIDEOS_BY_CLINIC, GET_LEARNERS_VIDEOS_BY_CLINIC),

    takeLatest(Clinic.ASSIGN_VIDEOS_TO_LEARNER, ASSIGN_VIDEOS_TO_LEARNER),
    takeLatest(Clinic.GET_ALL_LEARNER_ASSESSMENT, GET_ALL_LEARNER_ASSESSMENT),
    takeLatest(Clinic.GET_LAST5_ASSESSMENT, GET_LAST5_ASSESSMENT),

    takeLatest(Clinic.UPDATE_CLINIC, UPDATE_CLINIC),
    takeLatest(Clinic.GET_WATCH_TIME_GRAPH_DATA, GET_WATCH_TIME_GRAPH_DATA),
    takeLatest(Clinic.GET_CLINIC_VIDEOS_HIERARCHY, GET_CLINIC_VIDEOS_HIERARCHY),
    takeLatest(Clinic.GET_CLINIC_DASHBOARD, GET_CLINIC_DASHBOARD),

    takeLatest(Clinic.GET_CLINIC_VIDEOS_HISTORY, GET_CLINIC_VIDEOS_HISTORY),
    takeLatest(Clinic.GET_LEARNER_VIDEOS_REPORT, GET_LEARNER_VIDEOS_REPORT),
    takeLatest(Clinic.PAGE_CHANGED, PAGE_CHANGED),
    takeLatest(Clinic.GET_GEO_GRAPH_DATA, GET_GEO_GRAPH_DATA),
    takeLatest(Clinic.GET_CLINIC_VIDEO_BY_TUTORIAL_VIDEO_ID, GET_CLINIC_VIDEO_BY_TUTORIAL_VIDEO_ID),

    takeLatest(Clinic.GET_MEETING_REQUEST_REPORT, GET_MEETING_REQUEST_REPORT),
    takeLatest(Clinic.GET_INSTANT_MEETING_REPORT, GET_INSTANT_MEETING_REPORT),
    takeLatest(Clinic.GET_MANUAL_NSTANT_MEETING_REPORT, GET_MANUAL_NSTANT_MEETING_REPORT),

    takeLatest(Clinic.GET_WATCH_TIME_BY_LEARNER, GET_WATCH_TIME_BY_LEARNER),
    takeLatest(Clinic.GET_BCBA_AVAILABILITY_REPORT, GET_BCBA_AVAILABILITY_REPORT),
    takeLatest(Clinic.SYNC_VIDEOS, SYNC_VIDEOS),
    takeLatest(Clinic.GET_UNIT_REPORT, GET_UNIT_REPORT),
  ])
}
