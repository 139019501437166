import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import User from '../user/action'
import Staff from './action'
import {
  createUpdateSlot,
  getAllBCBAforWebinar,
  getALlBCBAInSuperAdmin,
  getAllStaffByClincId,
  getRequestMeets,
  getSlots,
  getSlotsByDate,
  getStaffLearners,
  updateMeetRequestByBcba,
  updateStaff,
} from './services'

export function* UPDATE_STAFF({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isStaffSaving: true,
    },
  })
  const response = yield call(updateStaff, payload)
  if (response && response.updateStaff.status) {
    notification.success({
      message: response.updateStaff.message,
    })
    const { staff } = yield select(state => state.user)
    yield put({
      type: User.SET_STATE,
      payload: {
        staff: {
          ...staff,
          ...response.updateStaff.staff,
        },
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isStaffSaving: false,
    },
  })
}

export function* GET_REQUEST_MEETS() {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isRequestMeetsLoading: true,
    },
  })
  const response = yield call(getRequestMeets)
  if (response && response.getRequestMeets) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        requestMeets: response.getRequestMeets.edges,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isRequestMeetsLoading: false,
    },
  })
}

export function* UPDATE_MEET_REQUEST_BY_BCBA({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isMeetSaving: true,
    },
  })
  const response = yield call(updateMeetRequestByBcba, payload)
  if (response && response.updateMeetRequestByBcba && response.updateMeetRequestByBcba.status) {
    notification.success({
      message: response.updateMeetRequestByBcba.message,
    })

    yield put({
      type: Staff.REPLACE_MEET_REQUEST_BY_BCBA,
      payload: {
        data: response.updateMeetRequestByBcba.data,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isMeetSaving: false,
      isMeetEditDrawer: false,
    },
  })
}

export function* GET_STAFF_LEARNERS({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isLearnerLoading: true,
    },
  })
  const response = yield call(getStaffLearners, payload)
  if (response && response.getStaffLearners) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        staffLearners: response.getStaffLearners,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isLearnerLoading: false,
    },
  })
}

export function* GET_SLOTS({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isSlotLoading: true,
    },
  })
  const response = yield call(getSlots, payload)
  if (response && response.getStaffById) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        staffSlots: response.getStaffById.staffweekdaysSet.edges,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isSlotLoading: false,
    },
  })
}
export function* CREATE_UPDATE_SLOT({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isSlotSaving: true,
    },
  })
  const response = yield call(createUpdateSlot, payload)
  if (response && response.createUpdateStaffSlots.status) {
    notification.success({
      message: response.createUpdateStaffSlots.message,
    })
    yield put({
      type: Staff.REPLACE_SLOT,
      payload: {
        data: response.createUpdateStaffSlots.staff.staffweekdaysSet.edges,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isSlotSaving: false,
    },
  })
}

export function* GET_SLOTS_BY_DATE({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isDateSlotsLoading: true,
    },
  })
  const response = yield call(getSlotsByDate, payload)
  if (response && response.getAvailableStaffTimeSlots) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        dateSlots: response.getAvailableStaffTimeSlots,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isDateSlotsLoading: false,
    },
  })
}

export function* GET_ALL_BCBA_IN_SUPERADMIN({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isAllBCBALoading: true,
    },
  })
  const response = yield call(getALlBCBAInSuperAdmin, payload)
  if (response && response.getAllStaffInSuperadmin) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        allBCBAInSuperAdmin: response.getAllStaffInSuperadmin.edges,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isAllBCBALoading: false,
    },
  })
}

export function* GET_ALL_BCBA_FOR_WEBINAR({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isAllBCBALoading: true,
    },
  })
  const response = yield call(getAllBCBAforWebinar)
  if (response && response.getAllStaff) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        allBCBAForWebinar: response.getAllStaff.edges,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isAllBCBALoading: false,
    },
  })
}

export function* GET_BCBA_BY_CLINIC_ID({ payload }) {
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isStaffLoading: true,
    },
  })
  const response = yield call(getAllStaffByClincId, payload)
  if (response && response.getAllStaffInSuperadmin) {
    yield put({
      type: Staff.SET_STATE,
      payload: {
        staffs: response.getAllStaffInSuperadmin.edges,
      },
    })
  }
  yield put({
    type: Staff.SET_STATE,
    payload: {
      isStaffLoading: false,
    },
  })
}
export default function* rootSaga() {
  yield all([
    takeLatest(Staff.UPDATE_STAFF, UPDATE_STAFF),
    takeLatest(Staff.GET_REQUEST_MEETS, GET_REQUEST_MEETS),
    takeLatest(Staff.UPDATE_MEET_REQUEST_BY_BCBA, UPDATE_MEET_REQUEST_BY_BCBA),
    takeLatest(Staff.GET_STAFF_LEARNERS, GET_STAFF_LEARNERS),
    takeLatest(Staff.GET_SLOTS, GET_SLOTS),
    takeLatest(Staff.CREATE_UPDATE_SLOT, CREATE_UPDATE_SLOT),
    takeLatest(Staff.GET_SLOTS_BY_DATE, GET_SLOTS_BY_DATE),
    takeLatest(Staff.GET_ALL_BCBA_IN_SUPERADMIN, GET_ALL_BCBA_IN_SUPERADMIN),
    takeLatest(Staff.GET_BCBA_BY_CLINIC_ID, GET_BCBA_BY_CLINIC_ID),
    takeLatest(Staff.GET_ALL_BCBA_FOR_WEBINAR, GET_ALL_BCBA_FOR_WEBINAR),

  ])
}
