const Video = {
  SET_STATE: 'video/SET_STATE',

  GET_LIKE_DISLIKE_ON_VIDEO: 'video/GET_LIKE_DISLIKE_ON_VIDEO',
  LIKE_DISLIKE_VIDEO: 'video/LIKE_DISLIKE_VIDEO',
  SET_LIKES: 'video/SET_LIKES',
  ADD_VIDEO_RATING: 'video/ADD_VIDEO_RATING',
  ADD_COMMENT_ON_VIDEO: 'video/ADD_COMMENT_ON_VIDEO',
  UPDATE_COMMENT_ON_VIDEO: 'video/UPDATE_COMMENT_ON_VIDEO',
  ADD_COMMENT_REPLY: 'video/ADD_COMMENT_REPLY',
  UPDATE_COMMENT_REPLY: 'video/UPDATE_COMMENT_REPLY',
  REMOVE_COMMENT: 'video/REMOVE_COMMENT',
  FETCH_VIDEO_COMMENTS: 'video/FETCH_VIDEO_COMMENTS',
  ON_PAUSE_VIDEO: 'video/ON_PAUSE_VIDEO',
  ADD_VIDEO_VIEW: 'video/ADD_VIDEO_VIEW',
  GET_GEO_GRAPH_DATA: 'video/GET_GEO_GRAPH_DATA',
  APPEND_VIDEO_COMMENT: 'video/APPEND_VIDEO_COMMENT',
  APPEND_VIDEO_COMMENT_REPLY: 'video/APPEND_VIDEO_COMMENT_REPLY',
  REPLACE_VIDEO_COMMENT: 'video/REPLACE_VIDEO_COMMENT',
  REPLACE_VIDEO_COMMENT_REPLY: 'video/REPLACE_VIDEO_COMMENT_REPLY',
  POP_COMMENT: 'video/POP_COMMENT',
  POP_REPLY_COMMENT: 'video/POP_REPLY_COMMENT',
}

export default Video
