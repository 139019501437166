const Community = {
  SET_STATE: 'community/SET_STATE',
  GET_ALL_ZOOM_MEETINGS: 'community/GET_ZOOM_MEETINGS',
  CREATE_ZOOM_MEETING: 'community/CREATE_ZOOM_MEETING',
  UPDATE_ZOOM_MEETING: 'community/UPDATE_ZOOM_MEETING',
  ACTIVE_INACTIVE_ZOOM_MEETING: 'community/DELETE_ZOOM_MEETING',
  APPEND_ZOOM_MEETING: 'community/APPEND_ZOOM_MEETING',
  REPLACE_ZOOM_MEETING: 'community/REPLACE_ZOOM_MEETING',
  POP_ZOOM_MEETING: 'community/POP_ZOOM_MEETING',
  CREATE_HOST: 'community/CREATE_HOST',
  GET_ALL_HOSTS: 'community/GET_ALL_HOSTS',
  APPEND_HOST: 'community/APPEND_HOST',
  GET_WEBINAR_BY_ID: 'community/GET_WEBINAR_BY_ID',
  GET_ALL_BCBA: 'community/GET_ALL_BCBA',
  CREATE_BCBA: 'community/CREATE_BCBA',
  UPDATE_BCBA: 'community/UPDATE_BCBA',
  APPEND_BCBA: 'community/APPEND_BCBA',
  REPLACE_BCBA: 'community/REPLACE_BCBA',
  PAGE_CHANGED: 'community/PAGE_CHANGED',
  ASSIGN_PARENTS_TO_BCBA: 'community/ASSIGN_PARENTS_TO_BCBA',
  GET_PARENTS_BY_BCBA: 'community/GET_PARENTS_BY_BCBA',
  APPEND_PARENTS_TO_BCBA: 'community/APPEND_PARENTS_TO_BCBA',
  GET_LEARNERS: 'community/GET_LEARNERS',
  SEND_EMAIL_TO_BCBA: 'community/SEND_EMAIL_TO_BCBA',
  SEND_MEETING_REQUEST_TO_BCBA: 'community/SEND_MEETING_REQUEST_TO_BCBA',
  UPDATE_MEETING_REQUEST_BY_PARENT: 'community/UPDATE_MEETING_REQUEST_BY_PARENT',
  DELETE_MEETING_REQUEST_BY_PARENT: 'community/DELETE_MEETING_REQUEST_BY_PARENT',
  APPEND_MEETING_REQUEST: 'community/APPEND_MEETING_REQUEST',
  REPLACE_MEETING_REQUEST: 'community/REPLACE_MEETING_REQUEST',
  POP_MEETING_REQUEST: 'community/POP_MEETING_REQUEST',

  GET_REQUEST_MEETS: 'community/GET_REQUEST_MEETS',
  GET_REQUEST_MEETS_COMMENTS: 'community/GET_REQUEST_MEETS_COMMENTS',
  CREATE_MEET_REQUEST_COMMENT: 'community/CREATE_MEET_REQUEST_COMMENT',
  UPDATE_MEET_REQUEST_COMMENT: 'community/UPDATE_MEET_REQUEST_COMMENT',
  DELETE_MEET_REQUEST_COMMENT: 'community/DELETE_MEET_REQUEST',
  APPEND_MEET_REQUEST_COMMENT: 'community/APPEND_MEET_REQUEST_COMMENT',
  REPLACE_MEET_REQUEST_COMMENT: 'community/REPLACE_MEET_REQUEST_COMMENT',
  POP_MEET_REQUEST_COMMENT: 'community/POP_MEET_REQUEST_COMMENT',

  //chat
  SEND_MESSAGE_TO_BCBA: 'community/SEND_MESSAGE_TO_BCBA',
  GET_ALL_MESSAGES: 'community/GET_ALL_MESSAGES',
}

export default Community
