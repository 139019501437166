/* eslint-disable no-unused-vars */

import { gql } from 'apollo-boost'
import { client } from '../../apollo/config'

export async function getClinics() {
  return client
    .query({
      query: gql`
        query {
          schools {
            edges {
              node {
                id
                schoolName
                email
                isDefault
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function getModules() {
  return client
    .query({
      query: gql`
        query {
          getVideosModules {
            edges {
              node {
                id
                name
                isActive
                moduleOrder
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function createModule(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createVideoModule($name: String!,moduleOrder: Int) {
          createVideoModule(input: { name: $name,moduleOrder: $moduleOrder}) {
            message
            status
            videoModule {
              id
              name
              isActive
              moduleOrder 
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function editModule(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateVideoModule($id: ID!, $name: String, $isActive: Boolean, $moduleOrder: Int) {
          updateVideoModule(
            input: { id: $id, name: $name, isActive: $isActive, moduleOrder: $moduleOrder }
          ) {
            message
            status
            videoModule {
              id
              name
              isActive
              moduleOrder
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getSubModules(payload) {
  return client
    .query({
      query: gql`
        query getVideosSubModules($moduleId: ID) {
          getVideosSubModules(module: $moduleId) {
            edges {
              node {
                id
                name
                isActive
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createSubModule(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createVideoSubModule($moduleId: ID!, $name: String!) {
          createVideoSubModule(input: { name: $name, moduleId: $moduleId }) {
            message
            status
            videoSubModule {
              id
              name
              isActive
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function editSubModule(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateVideoSubModule($submoduleId: ID!, $name: String, $isActive: Boolean) {
          updateVideoSubModule(input: { id: $submoduleId, name: $name, isActive: $isActive }) {
            message
            status
            videoSubModule {
              id
              name
              isActive
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getVideos(payload) {
  return client
    .query({
      query: gql`
        query getTutorialVideos($moduleId: ID, $submoduleId: ID) {
          getTutorialVideos(module: $moduleId, subModule: $submoduleId) {
            edges {
              node {
                id
                video {
                  id
                  videoName
                  videoUrl
                  videoDocFile
                }
                isActive
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createTutorialVideo(
          $submoduleId: ID!
          $moduleId: ID!
          $video: ID!
          $videoDocFile: String
        ) {
          createTutorialVideo(
            input: {
              submoduleId: $submoduleId
              moduleId: $moduleId
              videoId: $video
              videoDocFile: $videoDocFile
            }
          ) {
            message
            status
            tutorialVideo {
              id
              video {
                id
                videoName
                videoUrl
                videoDocFile
              }
              isActive
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function editVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateTutorialVideo(
          $id: ID!
          $video: ID!
          $isActive: Boolean
          $videoDocFile: String
        ) {
          updateTutorialVideo(
            input: { id: $id, videoId: $video, isActive: $isActive, videoDocFile: $videoDocFile }
          ) {
            message
            status
            tutorialVideo {
              id
              video {
                id
                videoName
                videoUrl
                videoDocFile
              }
              isActive
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
export async function getAllVideos() {
  return client
    .query({
      query: gql`
        query getTutorialVideos {
          getTutorialVideos(isActive: true) {
            edges {
              node {
                id
                isActive
                video {
                  id
                  videoName
                  videoUrl
                  videoDocFile
                }
                module {
                  id
                  name
                  isActive
                }
                subModule {
                  id
                  name
                  isActive
                }
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}
export async function assignVideosToClinic(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation assignVideosToClinic($clinic: ID!, $videos: [ID]!) {
          assignVideosToClinic(input: { clinic: $clinic, tutorialVideos: $videos }) {
            message
            status
            clinicTutorialVideos {
              id
              clinic {
                id
                schoolName
              }
              tutorialVideos {
                id
                video {
                  id
                  videoName
                  videoUrl
                }
                isActive
                module {
                  id
                  name
                  isActive
                }
                subModule {
                  id
                  name
                  isActive
                }
              }
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function getCurriculumVideos() {
  return client
    .query({
      query: gql`
        query {
          getVideos {
            edges {
              node {
                id
                videoName
                videoUrl
                videoDocFile
              }
            }
          }
        }
      `,

      fetchPolicy: 'network-only',
    })
    .then(data => {
      return data?.data
    })
    .catch(err => console.error(err, 'error'))
}

export async function createCurriculumVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation createVideo($videoName: String!, $videoUrl: String!, $videoDocFile: String) {
          createVideo(
            input: { videoName: $videoName, videoUrl: $videoUrl, videoDocFile: $videoDocFile }
          ) {
            message
            status
            video {
              id
              videoName
              videoUrl
              videoDocFile
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}

export async function updateCurriculumVideo(payload) {
  return client
    .mutate({
      mutation: gql`
        mutation updateVideo(
          $videoId: ID!
          $videoName: String!
          $videoUrl: String!
          $videoDocFile: String
        ) {
          updateVideo(
            input: {
              pk: $videoId
              videoName: $videoName
              videoUrl: $videoUrl
              videoDocFile: $videoDocFile
            }
          ) {
            message
            status
            video {
              id
              videoName
              videoUrl
              videoDocFile
            }
          }
        }
      `,
      variables: {
        ...payload,
      },
    })
    .then(data => {
      return data?.data
    })
    .catch(err => {
      return err
    })
}
