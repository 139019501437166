import Community from './action'

const initialState = {
  isWebinarsLoading: false,
  webinars: [],
  selectedWebinar: null,
  isWebinarCreateDrawer: false,
  isWebinarSaving: false,
  isCreateHost: false,
  hostSaving: false,
  isHostLoading: false,
  hosts: [],
  isWebinarByIdLoading: false,
  webinarById: null,

  //learners
  learners: [],
  learnersFetched: false,
  isLearnerLoading: false,

  //BCBA
  isallBCBALoading: false,
  allBCBA: [],
  selectedBCBA: null,
  isBCBASaving: false,
  isBCBACreateDrawer: false,
  parentsByBCBALoading: false,
  parentsByBCBA: {},
  isParentAssigning: false,
  isAssignModal: false,

  BCBACount: 0,
  pageInfo: null,
  prevPageNo: 1,
  type: 'next',

  //Connect BCBA
  isSendEmailDrawer: false,
  isEmailSending: false,
  isRequestMeetDrawer: false,
  isEditRequestDrawer: false,
  selectedMeeting: null,
  isRequestSending: false,
  requestMeets: [],
  isRequestMeetsLoading: false,

  //comment
  isCommentsLoading: false,
  comments: [],
  isCommentDrawer: false,
  isCommentCreateModal: false,
  isCommentSaving: false,
  selectedComment: null,

  //chat
  isChatOpen: false,
  isChatLoading: false,
  isMessageSending: false,
  isStaffAvailable: false,
}

export default function community(state = initialState, action) {
  switch (action.type) {
    case Community.SET_STATE:
      return { ...state, ...action.payload }
    case Community.APPEND_ZOOM_MEETING:
      return {
        ...state,
        isWebinarCreateDrawer: false,
        webinars: [
          ...state.webinars,
          {
            node: action.payload.data,
          },
        ],
      }
    case Community.REPLACE_ZOOM_MEETING: {
      return {
        ...state,
        selectedWebinar: null,
        isWebinarCreateDrawer: false,
        webinars: [
          ...state.webinars.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    case Community.APPEND_HOST:
      return {
        ...state,
        isCreateHost: false,
        hosts: [...state.hosts, { node: action.payload.data }],
      }
    case Community.APPEND_BCBA:
      return {
        ...state,
        isBCBACreateDrawer: false,
        allBCBA: [
          ...state.allBCBA.map(({ node: item }) => {
            return {
              node: {
                ...item,
                isDefaultBcba:
                  action.payload.data.isDefaultBcba && item.isDefaultBcba
                    ? false
                    : item.isDefaultBcba,
              },
            }
          }),
          {
            node: action.payload.data,
          },
        ],
      }
    case Community.REPLACE_BCBA: {
      return {
        ...state,
        selectedBCBA: null,
        isBCBACreateDrawer: false,
        allBCBA: [
          ...state.allBCBA.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return {
              node: {
                ...item,
                isDefaultBcba:
                  action.payload.data.isDefaultBcba && item.isDefaultBcba
                    ? false
                    : item.isDefaultBcba,
              },
            }
          }),
        ],
      }
    }
    case Community.APPEND_PARENTS_TO_BCBA: {
      const { staffId, data } = action.payload

      const updatedParentsByBCBA = { ...state.parentsByBCBA, [staffId]: [...data] }

      return {
        ...state,
        parentsByBCBA: updatedParentsByBCBA,
      }
    }
    case Community.APPEND_MEETING_REQUEST: {
      const { data } = action.payload

      return {
        ...state,
        isRequestMeetDrawer: false,
        requestMeets: [
          ...state.requestMeets,
          {
            node: data,
          },
        ],
      }
    }
    case Community.REPLACE_MEETING_REQUEST: {
      const { data } = action.payload

      return {
        ...state,
        isEditRequestDrawer: false,
        selectedMeeting: null,
        requestMeets: [
          ...state.requestMeets.map(({ node: item }) => {
            if (item.id === data.id) {
              return { node: { ...item, ...data } }
            }
            return { node: item }
          }),
        ],
      }
    }
    case Community.POP_MEETING_REQUEST: {
      const { meetingId } = action.payload

      return {
        ...state,

        requestMeets: [...state.requestMeets.filter(({ node: item }) => item.id !== meetingId)],
      }
    }
    case Community.APPEND_MEET_REQUEST_COMMENT: {
      const { data } = action.payload

      return {
        ...state,
        comments: [
          ...state.comments,
          {
            node: data,
          },
        ],
      }
    }
    case Community.REPLACE_MEET_REQUEST_COMMENT: {
      return {
        ...state,
        selectedComment: null,
        comments: [
          ...state.comments.map(({ node: item }) => {
            if (item.id === action.payload.data.id) {
              return { node: { ...item, ...action.payload.data } }
            }
            return { node: item }
          }),
        ],
      }
    }

    case Community.POP_MEET_REQUEST_COMMENT: {
      return {
        ...state,
        selectedComment: null,
        comments: state.comments.filter(({ node: item }) => item.id !== action.payload.commentId),
      }
    }
    default:
      return state
  }
}
